function setLayout(layouts) {
  isApplied = false;

  if (layouts) {
    layouts = layouts.split("~");
    let uniqueFields = [];
    layouts.forEach(function (obj) {
      if (!uniqueFields.includes(obj)) uniqueFields.push(obj);
    });

    for (const obj of uniqueFields) {
      if (typeof _SUPPORTED_LAYOUTS[obj] !== undefined) {
        let sheet = document.createElement("style");
        sheet.innerHTML =
          "#buttons-bar,#button_ranges, #ranges_table, #toggle_button, #button_table, \
                    #button_15min, #button_hour, #button_day, #button_week, #button_month, .headbtndiv,\
                    .head2btndiv,.head4btndiv, .head3btndiv {display:none;}";
        document.body.appendChild(sheet);
        isApplied = true;
        break;
      }
    }

    if (
      (uniqueFields.includes("hide_all") &&
        uniqueFields.includes("show_buttons")) ||
      (!uniqueFields.includes("hide_all") &&
        !uniqueFields.includes("show_buttons")) ||
      !uniqueFields.includes("hide_all")
    ) {
      uniqueFields.forEach(function (obj) {
        if (typeof _SUPPORTED_LAYOUTS[obj] !== undefined) {
          if (obj == "show_legend") {
            if (isSummary == 0) {
              let sheet = document.createElement("style");
              sheet.innerHTML = "#ranges_table{display: block;}";
              document.body.appendChild(sheet);
              isApplied = true;
            }
          } else if (obj == "show_buttons") {
            let sheet = document.createElement("style");
            if (isSummary == 0) {
              sheet.innerHTML =
                "#buttons-bar,#button_ranges, #toggle_button, #button_table,.headbtndiv,.head3btndiv,.head4btndiv {display:block;}";
              isApplied = true;
            } else {
              sheet.innerHTML =
                "#buttons-bar,#button_15min, #button_hour, #button_day, #button_week, #button_month, .headbtndiv,.head2btndiv,.head3btndiv {display:block;}";
              isApplied = true;
            }
            document.body.appendChild(sheet);
          } else if (obj == "show_table") {
            let sheet = document.createElement("style");
            if (isSummary == 0) {
              sheet.innerHTML = "#info_table{display:block;}";
              isApplied = true;
            }
            document.body.appendChild(sheet);
          } else if (obj == "show_range") {
            let sheet = document.createElement("style");
            if (isSummary == 0) {
              sheet.innerHTML = "#extremes_div{display:block;}";
              isApplied = true;
            }
            document.body.appendChild(sheet);
          }
        }
      });
    }
  }

  if (!isApplied) {
    let sheet = document.createElement("style");
    if (1 == isSummary) {
      sheet.innerHTML =
        "#button_ranges, #ranges_table, #toggle_button, #button_table, .head4btndiv{display:none;}";
    } else {
      sheet.innerHTML =
        ".head2btndiv{display:none;}\
      #button_15min, #button_hour, #button_day, #button_week, #button_month, .head2btndiv{display:none;}";
    }
    document.body.appendChild(sheet);
  }
  return;
} // End of setLayout

function getCountryByTZ(tz) {
  tz = tz.replace(" ", "+");

  for (var country in _ALL_TIMEZONES) {
    tzValues = _ALL_TIMEZONES[country];
    for (var key in tzValues) {
      if (key == tz.split()) {
        _timezone = key;
        tmpTZ = key.split("~").join("/");
        if (country == "GMT") {
          offset = _GMT_OFFSET[tmpTZ] * -1;
        } else {
          offset = getTimezoneOffset(tmpTZ);
        }

        offset = offset * 1000;
        _offset = offset;
        _country = country;
        return;
      }
    }
  }
  return "";
} //End getCountryByTZ

function getTimezoneOffset(remote_tz) {
  let now = new Date();
  let rDate = new Date(now).toLocaleString("en", {
    month: "short",
    day: "numeric",
    year: "numeric",
    timeZone: remote_tz,
  });

  let rTime = new Date(now).toLocaleTimeString("en", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: remote_tz,
  });
  let remoteTime = new Date(rDate + " " + rTime);

  // UTC Time
  let d1 = new Date(now);
  let utcTime = new Date(
    d1.getUTCFullYear(),
    d1.getUTCMonth(),
    d1.getUTCDate(),
    d1.getUTCHours(),
    d1.getUTCMinutes(),
    d1.getUTCSeconds()
  );

  offset = Math.abs(utcTime.getTime() - remoteTime.getTime()) / 1000;
  if (remoteTime > utcTime) {
    offset = offset * -1;
  }

  /* Old code incompatible with iOS 14.0
    let tz_time = new Date().toLocaleString('en', {
        day: '2-digit',
        timeZoneName: 'longOffset',
        timeZone: remote_tz
    }).slice(4);
    tz_time = tz_time.replace("GMT", "");
    tz_time = tz_time.split(":");
    offset = ((tz_time[0] * 60 * 60) + tz_time[1] * 60) * -1*/
  return offset;
} // end getTimezoneOffset()

function setDefaultReads() {
  if (!_REQUEST.has("reads")) {
    if (_REQUEST.has("scale")) {
      let tmpScale = _REQUEST.get("scale").trim();
      if (_SUPPORTED_SCALES.hasOwnProperty(tmpScale))
        scale = tmpScale;
    }

    switch (scale) {
      case "15":
        reads = 48;
        break;
      case "hr":
        reads = 48;
        break;
      case "dy":
        reads = 7;
        break;
      case "wk":
        reads = 13;
        break;
      case "mo":
        reads = 6;
        break;
      default:
        reads = 100;
    }
    return reads;
  }

  return _REQUEST.get("reads");
} //End setDefaultReads()

function setLoadReadsJSByScaleAndType(type, showLabel = 0) {
  if (isSummary == 1) {
    switch (type) {
      case "LOW":
        if (showLabel) {
          label =
            isSummary == 1
              ? scale == "15"
                ? "12 Hour"
                : scale == "hr"
                  ? "2 Days"
                  : scale == "dy"
                    ? "1 Week"
                    : scale == "wk"
                      ? "3 Month"
                      : scale == "mo"
                        ? "6 Months"
                        : "Load 25 reads"
              : "Load 25 reads";
        } else {
          reads =
            isSummary == 1
              ? scale == "15"
                ? 48
                : scale == "hr"
                  ? 48
                  : scale == "dy"
                    ? 7
                    : scale == "wk"
                      ? 13
                      : scale == "mo"
                        ? 6
                        : 25
              : 25;
        }
        break;
      case "MEDIUM":
        if (showLabel) {
          label =
            isSummary == 1
              ? scale == "15"
                ? "1 Day"
                : scale == "hr"
                  ? "5 Days"
                  : scale == "dy"
                    ? "2 Weeks"
                    : scale == "wk"
                      ? "6 Month"
                      : scale == "mo"
                        ? "1 Year"
                        : "Load 50 reads"
              : "Load 50 reads";
        } else {
          reads =
            isSummary == 1
              ? scale == "15"
                ? 96
                : scale == "hr"
                  ? 120
                  : scale == "dy"
                    ? 14
                    : scale == "wk"
                      ? 26
                      : scale == "mo"
                        ? 12
                        : 50
              : 50;
        }
        break;
      case "HIGH":
        if (showLabel) {
          label =
            isSummary == 1
              ? scale == "15"
                ? "2 Days"
                : scale == "hr"
                  ? "1 Week"
                  : scale == "dy"
                    ? "1 Month"
                    : scale == "wk"
                      ? "1 Year"
                      : scale == "mo"
                        ? "2 Years"
                        : "Load 75 reads"
              : "Load 75 reads";
        } else {
          reads =
            isSummary == 1
              ? scale == "15"
                ? 192
                : scale == "hr"
                  ? 168
                  : scale == "dy"
                    ? 30
                    : scale == "wk"
                      ? 52
                      : scale == "mo"
                        ? 24
                        : 75
              : 75;
        }
        break;
    }
    if (showLabel) {
      return label;
    } else {
      changeURLParamValue("reads", reads);
    }
  } else {
    switch (type) {
      case "LOW":
        if (showLabel) {
          label =
            isSummary == 1
              ? scale == "15"
                ? "12 Hour"
                : scale == "hr"
                  ? "2 Days"
                  : scale == "dy"
                    ? "1 Week"
                    : scale == "wk"
                      ? "3 Month"
                      : scale == "mo"
                        ? "6 Months"
                        : "Load 100 reads"
              : "Load 100 reads";
        } else {
          reads =
            isSummary == 1
              ? scale == "15"
                ? 48
                : scale == "hr"
                  ? 48
                  : scale == "dy"
                    ? 7
                    : scale == "wk"
                      ? 13
                      : scale == "mo"
                        ? 6
                        : 100
              : 100;
        }
        break;
      case "MEDIUM":
        if (showLabel) {
          label =
            isSummary == 1
              ? scale == "15"
                ? "1 Day"
                : scale == "hr"
                  ? "5 Days"
                  : scale == "dy"
                    ? "2 Weeks"
                    : scale == "wk"
                      ? "6 Month"
                      : scale == "mo"
                        ? "1 Year"
                        : "Load 500 reads"
              : "Load 500 reads";
        } else {
          reads =
            isSummary == 1
              ? scale == "15"
                ? 96
                : scale == "hr"
                  ? 120
                  : scale == "dy"
                    ? 14
                    : scale == "wk"
                      ? 26
                      : scale == "mo"
                        ? 12
                        : 500
              : 500;
        }
        break;
      case "HIGH":
        if (showLabel) {
          label =
            isSummary == 1
              ? scale == "15"
                ? "2 Days"
                : scale == "hr"
                  ? "1 Week"
                  : scale == "dy"
                    ? "1 Month"
                    : scale == "wk"
                      ? "1 Year"
                      : scale == "mo"
                        ? "2 Years"
                        : "Load 1000 reads"
              : "Load 1000 reads";
        } else {
          reads =
            isSummary == 1
              ? scale == "15"
                ? 192
                : scale == "hr"
                  ? 168
                  : scale == "dy"
                    ? 30
                    : scale == "wk"
                      ? 52
                      : scale == "mo"
                        ? 24
                        : 1000
              : 1000;
        }
        break;
    }
    if (showLabel) {
      return label;
    } else {
      changeURLParamValue("reads", reads);
    }
  }
} // End setLoadReadsJSByScaleAndType

function setMinMax(index) {
  let graphFieldsForURL = new Array();
  let selectGraphFields =
    deviceType == "meter" ? "line_graph_fields" : "io_line_graph_fields";
  let graphFieldsMinMax = _REQUEST.get(selectGraphFields);

  if (graphFieldsMinMax) {
    graphFieldsMinMax = graphFieldsMinMax.toLowerCase();
    graphFieldsMinMax = graphFieldsMinMax.split("~");
    let uniqueFields = new Array();
    graphFieldsMinMax.forEach((obj) => {
      if (!uniqueFields.hasOwnProperty(obj)) {
        uniqueFields.push(obj);
      }
    });
    if (deviceType == "meter") {
      uniqueFields.forEach((obj) => {
        if (_ALL_FIELDS.hasOwnProperty(obj) && graphFieldsForURL.length < 4) {
          graphFieldsForURL.push(_ALL_SUMMARY_FIELDS[obj]["key"]);
        }
      });
    } else if (deviceType == "iostack") {
      uniqueFields.forEach((obj) => {
        if (
          _ALL_FIELDS_IOSTACK.hasOwnProperty(obj) &&
          graphFieldsForURL.length < 4
        ) {
          graphFieldsForURL.push(_ALL_SUMMARY_FIELDS[obj]["key"]);
        }
      });
    }
  }
  if (graphFieldsForURL.length > 0) {
    defaultFields = graphFieldsForURL;
  }

  let key = defaultFields[index];
  let maxArray = [
    "Power_Factor_Ln_1",
    "Power_Factor_Ln_1",
    "Power_Factor_Ln_1",
  ];
  //let minArray = ['Amps_Ln_1', 'Amps_Ln_2', 'Amps_Ln_3'];

  if (maxArray.indexOf(key) >= 0) {
    return 2;
  }
} // End setMinMax()

function timeoutTrigger() {
  $("body").removeClass("loading");
}

function getHexCode(code) {
  code = code.split(",");
  var htmlCode = "";
  $.each(code, function (i, item) {
    htmlCode += "&#x" + item + ";";
  });
  return htmlCode;
} // End getHexCode()

function getDowloadTime() {
  var date = new Date();
  var dt = date.getUTCDate();
  var year = date.getUTCFullYear();
  var mo = date.getUTCMonth() + 1;
  var hr = date.getUTCHours();
  var min = date.getUTCMinutes();
  var sec = date.getUTCSeconds();

  dt = (dt <= 9 ? "0" : "") + dt;
  hr = (hr <= 9 ? "0" : "") + hr;
  mo = (mo <= 9 ? "0" : "") + mo;
  min = (min <= 9 ? "0" : "") + min;
  sec = (sec <= 9 ? "0" : "") + sec;
  return year + "-" + mo + "-" + dt + "-" + hr + "_" + min + "_" + sec;
} // End getDowloadTime()

function getFormattedDate(millisecond, isHideHour) {
  var date = new Date(millisecond);
  var month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  if (date) {
    var dt = date.getUTCDate();
    var day = weekday[date.getUTCDay()];
    var year = date.getUTCFullYear();
    var mo = month[date.getUTCMonth()];
    if (isHideHour) {
      return day + ", " + mo + " " + dt + ", " + year;
    } else {
      var hr = date.getUTCHours();
      var min = date.getUTCMinutes();
      var sec = date.getUTCSeconds();
      var hours = (hr + 24) % 24;
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;

      dt = (dt <= 9 ? "0" : "") + dt;
      hours = (hours <= 9 ? "0" : "") + hours;
      min = (min <= 9 ? "0" : "") + min;
      sec = (sec <= 9 ? "0" : "") + sec;

      return (
        day +
        ", " +
        mo +
        " " +
        dt +
        ", " +
        year +
        " " +
        hours +
        ":" +
        min +
        ":" +
        sec +
        " " +
        ampm
      );
    }
  }
  return "";
} // End getFormattedDate()

function hasFlash() {
  var hasFlash = false;
  try {
    var fo = new ActiveXObject("ShockwaveFlash.ShockwaveFlash");
    if (fo) hasFlash = true;
  } catch (e) {
    if (navigator.mimeTypes["application/x-shockwave-flash"] != undefined)
      hasFlash = true;
  }
  return hasFlash;
} // End hasFlash()

function timeoutInit() {
  $("body").addClass("loading");
  //commented this line and now calling time out manually.
  //setTimeout('timeoutTrigger()', 10000);
} // End timeoutInit()

async function getURLParams(urlParams) {
  let query = urlParams.toString();

  if (urlParams.has("q")) {
    let qParam = urlParams.get("q");
    query = await decompressData(qParam);
    if (urlParams.size > 1) {
      urlParams.forEach((value, key) => {
        if (key !== "q") {
          query += `&${key}=${value}`;
        }
      });
    }
  }

  return query;
}

async function buildURL() {
  let reloadURL =
    location.protocol + "//" + location.host + location.pathname + "?";
  let updateParams = "";
  var reads = $("#reads_amount").val();
  let urlParams = new URLSearchParams(window.location.search.substring(1));
  let query = await getURLParams(urlParams);
  let vars = query.split("&");

  var isLayoutFound = false;
  var isHideLineFound = false;

  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (i != 0) updateParams += "&";
    if (pair[0] == "reads") {
      updateParams += "reads=" + reads;
    } else if (pair[0] == "hide_lines") {
      isHideLineFound = true;
      updateParams += "hide_lines=" + hide_lines.join("");
    } else if (pair[0] == "layout") {
      updateParams += "layout" + "=" + layout;
      isLayoutFound = true;
    } else {
      updateParams += vars[i];
    }
  }

  if (!isLayoutFound && layout != -1) {
    updateParams += "&layout=" + layout;
  }

  if (!isHideLineFound && hide_lines.length > 0) {
    updateParams += "&hide_lines=" + hide_lines.join("");
  }
  _REQUEST = new URLSearchParams(updateParams);
  if (updateParams.length > 512) {
    updateParams = await compressData(updateParams);
    reloadURL = reloadURL + "q=" + updateParams;
    history.pushState(null, null, reloadURL);
  }

  // Update URL in the iframe
  createurl();
  updateBookmark();
} // End buildURL()

function fillConvertedValuePulseTab(pulseIndex) {
  let pcValueSelect = document.querySelector(
    "#pc_" + pulseIndex + "_value_select"
  ).value;
  let pcConvertedSelect = document.querySelector(
    "#pc_" + pulseIndex + "_converted_select"
  ).value;
  let calculated_value =
    _PC_VALUE_MULTIPLIER[pcValueSelect] * _DEFAULT_PC_VALUE[pcConvertedSelect];
  document.querySelector("#pc_" + pulseIndex + "_converted_value").value =
    Math.round(calculated_value * 1000000) / 1000000;
}

function getConvertedValue(pulseIndex) {
  var pc_ratio = 1;

  if (document.getElementById("pc_" + pulseIndex + "_ratio").value != "") {
    pc_ratio = document.getElementById("pc_" + pulseIndex + "_ratio").value;
  }

  var pc_value_select = document.getElementById(
    "pc_" + pulseIndex + "_value_select"
  ).value;

  if (pc_value_select == "kWh") {
    var pc_converted_select = "kWh";
    var options = '<option value="kWh">kWh</option>';
    $("#pc_" + pulseIndex + "_converted_select").append(options);
    document.getElementById("pc_" + pulseIndex + "_converted_select").value =
      "kWh";
    $("#pc_" + pulseIndex + "_converted_select").prop("disabled", true);
  } else {
    var pc_converted_select = document.getElementById(
      "pc_" + pulseIndex + "_converted_select"
    ).value;
    if (pc_converted_select == "kWh") {
      $("#pc_" + pulseIndex + '_converted_select option[value="kWh"]').remove();
      document.getElementById("pc_" + pulseIndex + "_converted_select").value =
        "gl";
    }
    $("#pc_" + pulseIndex + "_converted_select").prop("disabled", false);
  }

  var calculated_value =
    _PC_VALUE_MULTIPLIER[pc_value_select] *
    _DEFAULT_PC_VALUE[pc_converted_select];

  document.getElementById("pc_" + pulseIndex + "_converted_value").value =
    Math.round(calculated_value * 1000000) / 1000000;

  var pc_value = 1;
  if (document.getElementById("pc_" + pulseIndex + "_value").value != "") {
    pc_value = document.getElementById("pc_" + pulseIndex + "_value").value;
  }

  var calculate_value =
    pc_value *
    document.getElementById("pc_" + pulseIndex + "_converted_value").value;
  var optvalue = $(
    "#pc_" + pulseIndex + "_converted_select option:selected"
  ).text();
  var convertedUnitName = _OPTIONS_PULSE_VALUE[pc_converted_select];

  if (calculate_value > 1) {
    convertedUnitName = _OPTIONS_PULSE_VALUE_PLURAL[pc_converted_select];
  }

  document.getElementById("pulse" + pulseIndex + "calculatevalueis").innerHTML =
    Math.round(calculate_value * 1000000 * pc_ratio) / 1000000 +
    " " +
    convertedUnitName;

  var pulseCost = 1;
  if (document.getElementById("pc" + pulseIndex + "_cost").value != "") {
    pulseCost = document.getElementById("pc" + pulseIndex + "_cost").value;
  }

  var pCurrency = "";
  if (document.getElementById("pc" + pulseIndex + "_currency").value != "") {
    var pCurrency = document.getElementById(
      "pc" + pulseIndex + "_currency"
    ).value;
  }

  var cost = numberWithTwoDecimalPlace(pulseCost * pc_value * pc_ratio);

  if (pCurrency != "") {
    document.getElementById("pulse" + pulseIndex + "Cost").innerHTML =
      getHexCode(_CURRENCY_OPTIONS[pCurrency]["hex-code"]) +
      "" +
      numberWithCommas(cost);

    document.getElementById("pulse" + pulseIndex + "ConvertedCost").innerHTML =
      getHexCode(_CURRENCY_OPTIONS[pCurrency]["hex-code"]) +
      "" +
      numberWithCommas(cost);
  }

  if (document.getElementById("pc_" + pulseIndex + "_ratio").value != "") {
    var pc_value_select = document.getElementById(
      "pc_" + pulseIndex + "_value_select"
    ).value;
    var unitName = $(
      "#pc_" + pulseIndex + "_value_select option:selected"
    ).text();
    var pc_ratio = document.getElementById("pc_" + pulseIndex + "_ratio").value;
    var resultis = pc_ratio * pc_value;

    if (resultis > 1) {
      unitName = _OPTIONS_PULSE_VALUE_PLURAL[pc_value_select];
    }
    document.getElementById("pulse" + pulseIndex + "valueis").innerHTML =
      pc_value +
      "" +
      pc_value_select +
      " X " +
      pc_ratio +
      " = " +
      resultis +
      " " +
      unitName;
  }
  var unitName = $(
    "#pc_" + pulseIndex + "_value_select option:selected"
  ).text();
  document.getElementById("pc_" + pulseIndex + "_convert_label").innerHTML =
    "1 " + unitName + " = ";
  document.getElementById("pc" + pulseIndex + "_cost_label").innerHTML =
    "1 " + unitName + " = ";
} // End getConvertedValue()

function getMatch(arr1, arr2) {
  var matches = [];
  var fieldTot = 0;
  for (var i = 0; i < arr1.length; i++) {
    for (var e = 0; e < arr2.length; e++) {
      if (arr1[i] == arr2[e][0]) {
        matches.push(arr2[e]);
        fieldTot += arr2[e][1];
        break;
      }
    }
  }
  return matches;
} // End getMatch()

function numberWithCommas(x) {
  if (x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  } else {
    return x;
  }
}

function numberWithTwoDecimalPlace(x) {
  if (x) {
    var parts = x.toString().split(".");
    if (parts.length == 2) {
      x = formatNumber(x);
      x = x.toFixed(2);
    }
  }
  return x;
}

function setTotFieldValue(totValueArr) {
  let totField = getQueryVariable(selectTotalField).toLowerCase();
  let totValue = 0;
  let isGreaterThanOne = false;

  if (
    totField != "" &&
    supportedDefaultedTotFields.hasOwnProperty(totField) == false
  ) {
    show_error(
      "Value for Total Field is incorrect. Setting default value.",
      5000
    );
  }
  // Set default values if it does not have a value
  if (
    totField == "" ||
    supportedDefaultedTotFields.hasOwnProperty(totField) == false
  ) {
    if (deviceType == "meter") {
      totField = "kwh_tot";
    } else if (deviceType == "iostack") {
      totField = "pulse_cnt_1";
    }
  }

  if (isSummary == 0) {
    if (totValueArr.length > 0) {
      tmpValue = totValueArr[totValueArr.length - 1][1];
    }
  } else {
    // First create time array for running bar graph
    const timeArr = [];

    for (let i = 0; i < chart.series[0].data.length; i++) {
      timeArr.push(chart.series[0].data[i].x);
    }

    //remove overlapping time slot before doing total.
    timeArr.removeDuplicates();

    //create the latest running totfieldarray by matching the time values.
    const matches = [];

    for (let i = 0; i < timeArr.length; i++) {
      for (let e = 0; e < totFieldValueAry.length; e++) {
        if (timeArr[i] == totFieldValueAry[e][0]) {
          matches.push(totFieldValueAry[e]);
          break;
        }
      }
    }

    // re-assign the matches array to totFieldValueAry so that it will have only running data.
    totFieldValueAry = matches;
  }

  totValue = tmpValue;

  if (totValue > 1) {
    isGreaterThanOne = true;
  }

  //Set the data length to 0 if all values are zero and graph type is Realtime.
  let dataLength = chart.series[0].data.length;
  if (isSummary == 0) {
    const zeroValues = [];
    for (var i = 0; i < chart.series[0].data.length; i++) {
      if (chart.series[0].data[i].y == 0) {
        zeroValues.push(chart.series[0].data[i].y);
      }
    }

    if (zeroValues.length == dataLength) dataLength = 0;
  }

  const meter_span_text = ` Reads: ${dataLength}`;
  $("#meter_span_read").html(meter_span_text);

  let totName = getQueryVariable("kwh_tot_yname");

  if (totName != "") {
    totName = decodeURIComponent(totName);
  }

  let costText = "";

  if (
    (totField.indexOf("pulse_cnt_") == 0 && deviceType == "meter") ||
    totField.indexOf("converted_pulse_cnt") == 0 ||
    totField.indexOf("converted_io_pulse_cnt_") == 0
  ) {
    var pulseIndex = totField.replace(/[^+-\d.]/g, "");
    var pc_value_select = "gl";

    if (
      getQueryVariable(devicePrefix + "pc_" + pulseIndex + "_value_select") !=
      "" &&
      _OPTIONS_PULSE_VALUE[
      getQueryVariable(devicePrefix + "pc_" + pulseIndex + "_value_select")
      ] !== undefined
    )
      pc_value_select = getQueryVariable(
        devicePrefix + "pc_" + pulseIndex + "_value_select"
      );

    var pc_converted_select = "gl";
    if (
      getQueryVariable(
        devicePrefix + "pc_" + pulseIndex + "_converted_select"
      ) != "" &&
      _OPTIONS_PULSE_VALUE[
      getQueryVariable(
        devicePrefix + "pc_" + pulseIndex + "_converted_select"
      )
      ] !== undefined
    )
      pc_converted_select = getQueryVariable(
        devicePrefix + "pc_" + pulseIndex + "_converted_select"
      );

    let pCurrency = "USD";
    if (
      getQueryVariable(devicePrefix + "pc" + pulseIndex + "_currency") != "" &&
      _CURRENCY_OPTIONS[
      getQueryVariable(devicePrefix + "pc" + pulseIndex + "_currency")
      ] !== undefined
    )
      pCurrency = getQueryVariable(
        devicePrefix + "pc" + pulseIndex + "_currency"
      );

    var pCost = 0;

    if (getQueryVariable(devicePrefix + "pc" + pulseIndex + "_cost") != "")
      pCost = getQueryVariable(devicePrefix + "pc" + pulseIndex + "_cost");

    if (
      totField.indexOf("converted_pulse_cnt") == 0 ||
      totField.indexOf("converted_io_pulse_cnt_") == 0
    ) {
      var cost_multiplier =
        _PC_VALUE_MULTIPLIER[pc_value_select] *
        _DEFAULT_PC_VALUE[pc_converted_select];
      pCost = numberWithTwoDecimalPlace((pCost * totValue) / cost_multiplier);
    } else {
      pCost = numberWithTwoDecimalPlace(pCost * totValue);
    }

    if (pCost != 0)
      costText =
        " - " +
        getHexCode(_CURRENCY_OPTIONS[pCurrency]["hex-code"]) +
        numberWithCommas(pCost);
  } else if (
    totField.indexOf("fwd") == 0 ||
    totField.indexOf("rev") == 0 ||
    totField.indexOf("net") == 0 ||
    totField.indexOf("kwh") == 0
  ) {
    var pCost = 0;

    if (getQueryVariable("kwh_tot_cost") != "") {
      pCost = getQueryVariable("kwh_tot_cost");
    }

    var pCurrency = "USD";
    if (
      getQueryVariable("kwh_currency") != "" &&
      _CURRENCY_OPTIONS[getQueryVariable("kwh_currency")] !== undefined
    )
      pCurrency = getQueryVariable("kwh_currency");

    pCost = numberWithTwoDecimalPlace(pCost * totValue);
    if (pCost != 0)
      costText =
        " - " +
        getHexCode(_CURRENCY_OPTIONS[pCurrency]["hex-code"]) +
        numberWithCommas(pCost);

    if (totField.indexOf("net_kwh") == 0) {
      var direction = "Forward";
      if (totValue == 0) {
        direction = "";
      }
      if (totValue < 0) {
        direction = "Reverse";
        totValue *= -1;
      }
    }
  }

  totValue = numberWithCommas(formatNumber(totValue));

  // Text to display in the total bar
  if (totField.indexOf("pulse_cnt_") == 0 && deviceType == "meter") {
    var pc_value_select = "gl";
    if (
      getQueryVariable(devicePrefix + "pc_" + pulseIndex + "_value_select") !=
      "" &&
      _OPTIONS_PULSE_VALUE[
      getQueryVariable(devicePrefix + "pc_" + pulseIndex + "_value_select")
      ] !== undefined
    )
      pc_value_select = getQueryVariable(
        devicePrefix + "pc_" + pulseIndex + "_value_select"
      );

    var unitName = _OPTIONS_PULSE_VALUE[pc_value_select];

    if (isGreaterThanOne) {
      unitName = _OPTIONS_PULSE_VALUE_PLURAL[pc_value_select];
    }

    $("#kwh").html(totValue + " " + unitName + costText);
  } else if (
    totField.indexOf("fwd") == 0 ||
    totField.indexOf("rev") == 0 ||
    totField.indexOf("net") == 0 ||
    totField.indexOf("kwh") == 0
  ) {
    if (totField.indexOf("fwd") == 0) {
      $("#kwh").html(totValue + " Forward kWh" + costText);
    } else if (totField.indexOf("rev") == 0) {
      $("#kwh").html(totValue + " Reverse kWh" + costText);
    } else if (totField.indexOf("net") == 0) {
      $("#kwh").html(totValue + " " + direction + " kWh (Net) " + costText);
    } else {
      $("#kwh").html(totValue + " kWh" + costText);
    }
  } else if (
    totField.indexOf("converted_pulse_cnt") == 0 ||
    totField.indexOf("converted_io_pulse_cnt_") == 0
  ) {
    var pulseIndex = totField.replace(/[^+-\d.]/g, "");
    var pc_converted_select = "gl";

    if (
      getQueryVariable(
        devicePrefix + "pc_" + pulseIndex + "_converted_select"
      ) != "" &&
      _OPTIONS_PULSE_VALUE[
      getQueryVariable(
        devicePrefix + "pc_" + pulseIndex + "_converted_select"
      )
      ] !== undefined
    )
      pc_converted_select = getQueryVariable(
        devicePrefix + "pc_" + pulseIndex + "_converted_select"
      );

    var unitName = _OPTIONS_PULSE_VALUE[pc_converted_select];
    if (isGreaterThanOne)
      unitName = _OPTIONS_PULSE_VALUE_PLURAL[pc_converted_select];

    $("#kwh").html(totValue + " " + unitName + costText);
  } else {
    // allField used to display the name in the total row.
    if (deviceType == "meter") {
      allFields = _ALL_FIELDS;
    } else if (deviceType == "iostack") {
      allFields = _ALL_FIELDS_IOSTACK;
    }

    let showText = "";
    showText = totValue + " " + allFields[totField] + " " + costText;
    $("#kwh").html(showText);
  }
} // End  setTotFieldValue()

function getQueryVariable(variable) {
  if (_REQUEST.has(variable)) {
    return _REQUEST.get(variable);
  } else {
    return "";
  }
} // End getQueryVariable()

async function changeURLParamValue(param, value) {
  var reloadURL =
    location.protocol + "//" + location.host + location.pathname + "?";
  let updateParams = "";

  if (param == "reads") {
    var reads = value;
  }
  var graphtype = _GRAPH_TYPE;
  if (param != "reads") {
    if (isSummary == 1) {
      if (value == "realtime") {
        var reads = 100;
        graphtype = "line";
      } else if (value == "15") {
        var reads = 48;
        graphtype = "bar";
      } else if (value == "hr") {
        var reads = 48;
        graphtype = "bar";
      } else if (value == "dy") {
        var reads = 7;
        graphtype = "bar";
      } else if (value == "wk") {
        var reads = 13;
        graphtype = "bar";
      } else if (value == "mo") {
        var reads = 6;
        graphtype = "bar";
      }
    } else {
      if (value == "realtime") {
        graphtype = "line";
        var reads = 100;
      } else {
        graphtype = "bar";
        var reads = 48;
      }
    }
  }
  let urlParams = new URLSearchParams(window.location.search.substring(1));
  let query = await getURLParams(urlParams);
  var vars = query.split("&");
  var isFound = false;
  var isReadFound = false;
  var isLayoutFound = true;
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (i != 0) updateParams += "&";
    if (param == "reads" && pair[0] == "reads") {
      updateParams += "reads=" + reads;
      isReadFound = true;
      isFound = true;
    } else if (pair[0] == "layout") {
      updateParams += "layout" + "=" + layout;
      isLayoutFound = true;
    } else if (pair[0] == param) {
      updateParams += param + "=" + value;
      isFound = true;
    } else {
      updateParams += vars[i];
    }
  }
  if (!isReadFound && param != "reads") {
    if (getQueryVariable("reads") == "") updateParams += "&reads=" + reads;
    else updateParams = updateParams.replace(/(reads=)[^\&]+/, "$1" + reads);
  }
  if (!isFound) {
    updateParams += "&" + param + "=" + value;
  }

  if (!isLayoutFound && layout != -1) {
    updateParams += "&layout=" + layout;
  }

  //set previous parameters
  var previous_reads = getQueryVariable("reads");

  if (getQueryVariable("reads") == "") {
    previous_reads = "100";
  }
  var previous_scale =
    getQueryVariable("scale") == ""
      ? _DEFAULT_VALUES.scale
      : getQueryVariable("scale");

  if (getQueryVariable("prev_scale") == "") {
    updateParams += "&prev_scale=" + previous_scale;
  } else {
    previous_scale = getQueryVariable("prev_scale");
  }

  if (getQueryVariable("prev_reads") == "") {
    updateParams += "&prev_reads=" + previous_reads;
  } else {
    previous_reads = getQueryVariable("prev_reads");
  }
  var historyScales = ["15", "hr", "dy", "wk", "mo"];

  if (
    param == "scale" &&
    historyScales.indexOf(value) > -1 &&
    (getQueryVariable("scale") == "realtime" ||
      getQueryVariable("scale") == "") &&
    getQueryVariable("prev_scale") != "" &&
    historyScales.indexOf(previous_scale) > -1
  ) {
    updateParams = updateParams.replace(
      /(scale=)[^\&]+/,
      "$1" + previous_scale
    );
    updateParams = updateParams.replace(
      /(reads=)[^\&]+/,
      "$1" + previous_reads
    );
  } else if (
    param == "scale" &&
    value == "realtime" &&
    getQueryVariable("prev_scale") != "" &&
    getQueryVariable("prev_scale") == "realtime"
  ) {
    updateParams = updateParams.replace(
      /(reads=)[^\&]+/,
      "$1" + previous_reads
    );
  }

  if (
    getQueryVariable("prev_scale") != getQueryVariable("scale") &&
    ((getQueryVariable("prev_scale") == "realtime" &&
      param == "scale" &&
      value == "realtime") ||
      (getQueryVariable("prev_scale") == "realtime" &&
        param == "scale" &&
        historyScales.indexOf(value) > -1 &&
        historyScales.indexOf(getQueryVariable("scale")) == -1) ||
      (historyScales.indexOf(getQueryVariable("prev_scale")) > -1 &&
        getQueryVariable("scale") == "realtime" &&
        param == "scale" &&
        historyScales.indexOf(value) > -1))
  ) {
    var previous_scale =
      getQueryVariable("scale") == ""
        ? _DEFAULT_VALUES.scale
        : getQueryVariable("scale");

    var previous_reads = getQueryVariable("reads");
    if (getQueryVariable("reads") == "") {
      previous_reads = "100";
    }
    updateParams = updateParams.replace(
      /(prev_scale=)[^\&]+/,
      "$1" + previous_scale
    );
    updateParams = updateParams.replace(
      /(prev_reads=)[^\&]+/,
      "$1" + previous_reads
    );
  }

  let paramsToUpdate = updateParams;
  if (updateParams.length > 512) {
    updateParams = await compressData(updateParams);
    paramsToUpdate = "q=" + updateParams;
  }

  if (inIframe() && param == "reads") {
    $("#reads_amount").val(reads);
    initialDataFill(reads);
  } else {
    window.location.assign(reloadURL + paramsToUpdate);
  }
  return false;
} // End changeURLParamValue()

async function changeHideParamValue(value) {
  let baseURL = location.protocol + "//" + location.host + location.pathname;
  let reloadURL = "";
  let urlParams = new URLSearchParams(window.location.search.substring(1));
  let query = await getURLParams(urlParams);
  let vars = query.split("&");
  let isFound = false;

  for (let i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == "hide_lines") {
      if (value != "") {
        if (i != 0) reloadURL += "&";
        reloadURL += "hide_lines=" + value;
      }
      isFound = true;
    } else {
      if (i != 0) reloadURL += "&";
      reloadURL += vars[i];
    }
  }

  if (!isFound && value != "") {
    reloadURL += "&hide_lines=" + value;
  }

  let paramToUpdate = reloadURL;
  if (reloadURL.length > 512) {
    reloadURL = await compressData(reloadURL);
    paramToUpdate = `q=${reloadURL}`;
  }

  reloadURL = `${baseURL}?${paramToUpdate}`;
  if (inIframe()) {
    hideLegandLines();
  } else {
    window.location.assign(reloadURL);
  }
  return false;
} // End changeHideParamValue()

function show_error(message, delay_time = 20000) {
  if ($("#error-message").css("display") == "none") {
    $("#error-message p").text(message);
    $("#error-message").show().delay(delay_time).fadeOut(300);
    console.error(message);
  }
} // End show_error()

function shoInfowMessage(message, delay_time = 1500) {
  if ($("#info-message").css("display") == "none") {
    $("#info-message p").text(message);
    $("#info-message").show().delay(delay_time).fadeOut(300);
  }
} // End show_error()

function floatval(mixed_var) {
  return parseFloat(mixed_var) || 0;
} // End floatval()

function formatNumber(num) {
  if (isNaN(num)) return 0;
  var roundNum = Math.round(num * 100) / 100;
  if (roundNum == 0 && num > 0) roundNum = 0.01;
  return roundNum;
} // End formatNumber()

// This function gets the value by field name from array item
// If one field is combination of two fields
// then extra calculation u can put in this method
function getFieldValue(field, item) {
  let relative_fields = "";
  let barGraphFieldsGFV = "";

  if (deviceType == "meter") {
    barGraphFieldsGFV = _BAR_GRAPH_FIELDS;
  } else if (deviceType == "iostack") {
    barGraphFieldsGFV = _BAR_GRAPH_FIELDS_IOSTACK;
  }

  if (isSummary == 1) {
    if (barGraphFieldsGFV.hasOwnProperty(field)) {
      if (
        field.indexOf("converted_analog_in_") == 0 ||
        field.indexOf("converted_ow_") == 0
      ) {
        relative_fields = field.split("~");
      } else {
        relative_fields = barGraphFieldsGFV[field].value.split("~");
      }
    } else {
      relative_fields = _ALL_SUMMARY_FIELDS[field].value.split("~");
    }
  } else {
    if (deviceType == "meter") {
      relative_fields = _ALL_SUMMARY_FIELDS[field].key.split("~");
    } else if (deviceType == "iostack") {
      if (_ALL_SUMMARY_FIELDS_IOSTACK.hasOwnProperty(field))
        relative_fields = _ALL_SUMMARY_FIELDS_IOSTACK[field].key.split("~");
    }

  }

  let value = 0;
  let requestedField = field

  if (field.startsWith('state_inputs_')) {
    field = 'state_inputs'
  } else if (field.startsWith('state_out_')) {
    field = 'state_out'
  }

  if (
    relative_fields.length == 1 &&
    field.indexOf("converted_io_pulse_cnt_") != 0
  ) {

    // multiply by -1 for net_calc_watts fields in compare mode for realtime
    if (deviceType == "meter") {
      if (requestedField.startsWith('state_inputs_') || requestedField.startsWith('state_out_')) {
        let fieldPosition = requestedField.split("_")
        fieldPosition = fieldPosition[fieldPosition.length - 1]
        value = item[relative_fields[0]];
        value = value.split(":")
        value = parseInt(value[fieldPosition - 1])
      } else {
        value =
          field.indexOf("power_factor_ln") === 0 && isSummary != 1
            ? floatval(item[relative_fields[0]] / 100)
            : isEnableAreaGraph == 0 &&
              isSummary != 1 &&
              field.indexOf("net_calc_watts") === 0
              ? floatval(item[relative_fields[0]]) * -1
              : floatval(item[relative_fields[0]]);
      }
    } else if (deviceType == "iostack") {
      if (requestedField.startsWith('state_inputs_') || requestedField.startsWith('state_out_')) {
        let fieldPosition = requestedField.split("_")
        fieldPosition = fieldPosition[fieldPosition.length - 1]
        value = item[relative_fields[0]];
        value = value.split(":")
        value = parseInt(value[fieldPosition - 1])
      } else {
        value = item[relative_fields[0]];
      }
    }
  } else {
    if (
      (field.indexOf("pulse_cnt_") == 0 && deviceType == "meter") ||
      field.indexOf("converted_pulse_cnt_") == 0
    ) {
      value = formatNumber(item[relative_fields[0]] * item[relative_fields[1]]);
    } else if (field.indexOf("fwd") == 0) {
      value = formatNumber(item[relative_fields[0]] - item[relative_fields[1]]);
    } else if (field.indexOf("net") == 0) {
      value = formatNumber(
        item[relative_fields[0]] - 2 * item[relative_fields[1]]
      );
    } else if (field.indexOf("converted_io_pulse_cnt_") == 0) {
      let newRelativeField = relative_fields[0].replace("converted_", "");
      let newPulseIndex = newRelativeField.replace(/[^+-\d.]/g, "");

      if (_REQUEST.has(devicePrefix + "pc_" + newPulseIndex + "_value")) {
        value = formatNumber(
          item[newRelativeField + '_Diff'] *
          _REQUEST.get(devicePrefix + "pc_" + newPulseIndex + "_value")
        );
      } else {
        value = formatNumber(item[newRelativeField]);
      }
    }
  }

  return value;
} // End getFieldValue()

// This function returns the formatted data for building the graph.
function getGraphData(arr, checkFirst = true) {
  let array_initial_data = new Array(null, null, null, null, null);

  if (arr.length > 0) {
    let field1 = [];
    let field2 = [];
    let field3 = [];
    let field4 = [];
    let totArr = [];
    let legend_fields = legendFields.toLowerCase().split("~");
    let totFields = defaultTotalField.split("~");

    $.each(arr, function (i, item) {
      let tValue = 0;
      let timeStamp =
        isSummary == 1
          ? floatval(item["Start_Time_Stamp_UTC_ms"])
          : floatval(item["Time_Stamp_UTC_ms"]);
      if (_timezone != "") {
        timeStamp = timeStamp - _offset;
      }

      field1.push(new Array(timeStamp, getFieldValue(legend_fields[0], item)));

      if (isSummary != 1) {
        field2.push(
          new Array(timeStamp, getFieldValue(legend_fields[1], item))
        );
        field3.push(
          new Array(timeStamp, getFieldValue(legend_fields[2], item))
        );
        field4.push(
          new Array(timeStamp, getFieldValue(legend_fields[3], item))
        );
      }

      if (checkFirst) {
        tmpValue = 0;

        if (i == arr.length - 1) {
          tmpValue = extractValuesforTotalField(arr, totFields);
        }

        if (
          getQueryVariable(devicePrefix + "total_field").indexOf("fwd") == 0
        ) {
          tValue = floatval(
            item[totFields[0]] - formatNumber(item[totFields[1]])
          );
        } else if (
          getQueryVariable(devicePrefix + "total_field").indexOf("net") == 0
        ) {
          tValue = floatval(
            item[totFields[0]] - 2 * formatNumber(item[totFields[1]])
          );
        } else if (
          (getQueryVariable(devicePrefix + "total_field").indexOf(
            "pulse_cnt_"
          ) == 0 &&
            deviceType == "meter") ||
          getQueryVariable(devicePrefix + "total_field").indexOf(
            "converted_pulse_cnt_"
          ) == 0 ||
          getQueryVariable(devicePrefix + "total_field").indexOf(
            "converted_io_pulse_cnt_"
          ) == 0
        ) {
          const field = getQueryVariable(devicePrefix + "total_field");
          const _index = field.replace(/[^+-\d.]/g, "");
          let pc_value_select = "gl";
          let pc_converted_select = "gl";

          if (
            getQueryVariable(devicePrefix + "pc_" + _index + "_value_select") !=
            "" &&
            _OPTIONS_PULSE_VALUE[
            getQueryVariable(devicePrefix + "pc_" + _index + "_value_select")
            ] !== undefined
          )
            pc_value_select = getQueryVariable(
              devicePrefix + "pc_" + _index + "_value_select"
            );

          if (
            getQueryVariable(
              devicePrefix + "pc_" + _index + "_converted_select"
            ) != "" &&
            _OPTIONS_PULSE_VALUE[
            getQueryVariable(
              devicePrefix + "pc_" + _index + "_converted_select"
            )
            ] !== undefined
          )
            pc_converted_select = getQueryVariable(
              devicePrefix + "pc_" + _index + "_converted_select"
            );

          var pcSelectVal = _DEFAULT_PC_VALUE[pc_value_select];
          var calculated_value =
            _PC_VALUE_MULTIPLIER[pc_value_select] *
            _DEFAULT_PC_VALUE[pc_converted_select];
          var pcValue = 1;

          if (getQueryVariable(devicePrefix + "pc_" + _index + "_value") != "")
            pcValue = getQueryVariable(
              devicePrefix + "pc_" + _index + "_value"
            );

          if (field == "converted_pulse_cnt_" + _index) {
            if (i == arr.length - 1)
              tmpValue = tmpValue * calculated_value * pcValue;

            tValue = floatval(
              item[totFields[0]] *
              item[totFields[1]] *
              calculated_value *
              pcValue
            );
          } else if (field == `pulse_cnt_${_index}` && deviceType == "meter") {
            if (i == arr.length - 1) tmpValue = floatval(tmpValue * pcValue);
            tValue = floatval(
              item[totFields[0]] * item[totFields[1]] * pcValue
            );
          } else if (field == "converted_io_pulse_cnt_" + _index) {
            tValue = floatval(
              item["pulse_cnt_" + _index] * calculated_value * pcValue
            ); //ioStack
          }
        } else {
          tValue = floatval(item[defaultTotalField]);
        }
        // add new tot values array in totFieldValueAry every time refresh
        // happened so that we will new point to get the running total
        totArr.push(new Array(timeStamp, formatNumber(tValue)));
        totFieldValueAry.push(new Array(timeStamp, formatNumber(tValue)));
      }
    });

    array_initial_data = new Array(
      field1.reverse(),
      field2.reverse(),
      field3.reverse(),
      field4.reverse(),
      totArr.reverse()
    );
  }
  return array_initial_data;
} // End getGraphData()

function setGraphFields() {
  let allFields = [];
  let optionsTotalFields = [];
  let barGraphFieldsSGF = [];

  if (deviceType == "meter") {
    allFields = _ALL_FIELDS;
    optionsTotalFields = filterV3Meters();
    // For Summary
    barGraphFieldsSGF = _BAR_GRAPH_FIELDS;
  } else if (deviceType == "iostack") {
    allFields = _ALL_FIELDS_IOSTACK;
    optionsTotalFields = _SUPPORTED_DEFAULTED_TOT_FIELDS_IOSTACK;
    // For Summary
    barGraphFieldsSGF = _BAR_GRAPH_FIELDS_IOSTACK;
  }

  if (graphFields != -1 && graphFieldsForURL == -1) {
    var gFields = graphFields.toLowerCase().split("~");

    var uniqueFields = [];

    $.each(gFields, function (i, el) {
      if ($.inArray(el, uniqueFields) === -1) uniqueFields.push(el);
    });

    graphFieldsForURL = [];
    legendFields = [];
    $.each(uniqueFields, function (index, value) {
      if (
        (allFields.hasOwnProperty(value) ||
          optionsTotalFields.hasOwnProperty(value)) &&
        ((isSummary == 1 && graphFieldsForURL.length < 1) ||
          (isSummary != 1 && graphFieldsForURL.length < 4))
      ) {
        legendFields.push(value);
        graphFieldsForURL.push(_ALL_SUMMARY_FIELDS[value].key);
      } else if (
        barGraphFieldsSGF.hasOwnProperty(value) &&
        isSummary == 1 &&
        graphFieldsForURL.length < 1
      ) {
        legendFields.push(value);
        graphFieldsForURL.push(value);
      }
    });

    if (
      (isSummary == 1 && graphFieldsForURL.length < 1) ||
      (isSummary != 1 && graphFieldsForURL.length < 4)
    ) {
      $.each(defaultFields, function (index, value) {
        if (
          graphFieldsForURL.indexOf(value) == -1 &&
          graphFieldsForURL.length < 4
        ) {
          graphFieldsForURL.push(value);
          legendFields.push(value);
        }
      });
    }

    graphFieldsForURL = graphFieldsForURL.join("~");
    legendFields = legendFields.join("~");
  } else if (graphFieldsForURL == -1) {
    graphFieldsForURL = defaultFields.join("~");
    legendFields = graphFieldsForURL;
  }

  if (isSummary == 1 && graphFieldsForSummaryURL == -1) {
    var gFields = graphFieldsForURL.toLowerCase().split("~");
    graphFieldsForSummaryURL = [];

    $.each(gFields, function (index, value) {
      if (_ALL_SUMMARY_FIELDS.hasOwnProperty(value)) {
        var obj = _ALL_SUMMARY_FIELDS[value];

        if (deviceType == "meter") {
          graphFieldsForSummaryURL.push(obj.value);
        } else if (deviceType == "iostack") {
          graphFieldsForSummaryURL.push(obj.value);
        }
      } else if (barGraphFieldsSGF.hasOwnProperty(value)) {
        var obj = barGraphFieldsSGF[value];
        graphFieldsForSummaryURL.push(obj.value);
      }
    });
    graphFieldsForSummaryURL = graphFieldsForSummaryURL.join("~");
  }
} // End setGraphFields()

function showBarGraphByID(id) {
  if (isSummary == 1) {
    if (deviceType == "meter") {
      allFields = _ALL_FIELDS;
      barGraphFieldsSBGBI = _BAR_GRAPH_FIELDS;
    } else if (deviceType == "iostack") {
      allFields = _ALL_FIELDS_IOSTACK;
      barGraphFieldsSBGBI = _BAR_GRAPH_FIELDS_IOSTACK;
    }

    $.each(chart.series, function (index, series) {
      if (id == index) {
        series.show();
        var fields = legendFields.split("~");
        var fKey = fields[id].toLowerCase();

        if (barGraphFieldsSBGBI.hasOwnProperty(fKey)) {
          var yAxisLabel = barGraphFieldsSBGBI[fKey].name.replace(/_/g, " ");
        } else {
          var yAxisLabel = allFields[fKey].replace(/_/g, " ");
        }

        if (
          (fKey.indexOf("pulse_cnt") == 0 && deviceType == "meter") ||
          fKey.indexOf("converted_pulse_cnt") == 0
        ) {
          var fieldNum = fKey.replace(/[^+-\d.]/g, "");
          yAxisLabel = allFields["pulse_cnt_" + fieldNum];
          var pcName = getQueryVariable(
            devicePrefix + "pc_" + fieldNum + "_name"
          );

          if (pcName != "") yAxisLabel = decodeURIComponent(pcName);
          var unitName = "";
          if (fKey.indexOf("pulse_cnt") == 0 && deviceType == "meter") {
            let pc_value_select = "gl";
            if (
              getQueryVariable(
                devicePrefix + "pc_" + fieldNum + "_value_select"
              ) != "" &&
              _OPTIONS_PULSE_VALUE[
              getQueryVariable(
                devicePrefix + "pc_" + fieldNum + "_value_select"
              )
              ] !== undefined
            )
              pc_value_select = getQueryVariable(
                devicePrefix + "pc_" + fieldNum + "_value_select"
              );
            unitName = _OPTIONS_PULSE_VALUE_PLURAL[pc_value_select];
          } else {
            var pc_converted_select = "gl";
            if (
              getQueryVariable(
                devicePrefix + "pc_" + fieldNum + "_converted_select"
              ) != "" &&
              _OPTIONS_PULSE_VALUE[
              getQueryVariable(
                devicePrefix + "pc_" + fieldNum + "_converted_select"
              )
              ] !== undefined
            )
              pc_converted_select = getQueryVariable(
                devicePrefix + "pc_" + fieldNum + "_converted_select"
              );
            unitName = _OPTIONS_PULSE_VALUE_PLURAL[pc_converted_select];
          }
          yAxisLabel += " (" + unitName + ")";
        }

        if (fKey.indexOf("kwh_tot") == 0) {
          var name = getQueryVariable("kwh_tot_yname");
          if (name != "") {
            yAxisLabel = decodeURIComponent(name);
          }
        }

        // Name yAxis with the converted ai name if it exist
        if (_REQUEST.has("io_bar_graph_field")) {
          let ioBarGraph = _REQUEST.get("io_bar_graph_field");

          if (_BAR_GRAPH_FIELDS_IOSTACK.hasOwnProperty(ioBarGraph)) {
            if (ioBarGraph.indexOf("converted_analog_in_") == 0) {
              let indexConverted = ioBarGraph.replace(/[^+-\d.]/g, "");
              let lastValue = ioBarGraph.split("_");
              lastValue = lastValue[lastValue.length - 1].toUpperCase();
              if (_REQUEST.get("analog_" + indexConverted + "_name"))
                yAxisLabel =
                  _REQUEST.get("analog_" + indexConverted + "_name") +
                  " (" +
                  lastValue +
                  ")";
            }
          }
        }
        chart.yAxis[id].update({
          labels: {
            enabled: true,
          },
          title: {
            text: yAxisLabel,
          },
        });
      } else {
        series.hide();
        chart.yAxis[index].update({
          labels: {
            enabled: false,
          },
          title: {
            text: null,
          },
        });
      }
    });
  }
  return;
} // End showBarGraphByID()

//This function is deprecated.
function getSubString(str) {
  var res = str.length >= 17 ? str.slice(0, 14) + "..." : str;
  return res;
} // End getSubString()

function updatePointWidth(reads) {
  if (isSummary == 1) {
    var w = window.innerWidth;
    var width = parseInt((w / reads) * 0.5);
    var options = {
      pointWidth: width,
    };
    $.each(chart.series, function (index, series) {
      series = chart.get("series-" + index);
      series.update(options);
    });
  }
} // End updatePointWidth()

function setSeriesData(points, isInitialRead) {
  let displaySensorSymbol = ["", "", "", ""];
  let fieldsArr = legendFields.toLowerCase().split("~");
  let allFields = "";
  let barGraphFieldsSSD = "";

  // Add Time diff to all points.
  $.each(points[0], function (index, value) {
    points[0][index][0] = points[0][index][0] + time_diff;
    if (isSummary != 1) {
      points[1][index][0] = points[1][index][0] + time_diff;
      points[2][index][0] = points[2][index][0] + time_diff;
      points[3][index][0] = points[3][index][0] + time_diff;
    }
  });


  if (deviceType == "meter") {
    barGraphFieldsSSD = _BAR_GRAPH_FIELDS;
    allFields = _ALL_FIELDS;
  } else if (deviceType == "iostack" && (isSummary == 0 || isSummary == 1)) {
    barGraphFieldsSSD = _BAR_GRAPH_FIELDS_IOSTACK;
    allFields = _ALL_FIELDS_IOSTACK;
  }

  for (let j = 0; j < fieldsArr.length; j++) {
    let field = fieldsArr[j].toLowerCase();
    let field_value = fieldsArr[j].replace(/_/g, " ");

    if (allFields.hasOwnProperty(field)) {
      field_value = allFields[field].replace(/_/g, " ");
    } else if (barGraphFieldsSSD.hasOwnProperty(field)) {
      field_value = barGraphFieldsSSD[field].name.replace(/_/g, " ");
    }

    let seriesName = field_value;
    let legendValue = field_value;

    if (
      (field.indexOf("pulse_cnt") == 0 && deviceType == "meter") ||
      field.indexOf("converted_pulse_cnt") == 0 ||
      field.indexOf("converted_analog_in_") == 0 ||
      field.indexOf("converted_io_pulse_cnt_") == 0
    ) {
      // Executing loop 4 times for Pulse/Analog 1 to 4
      for (let k = 1; k <= 4; k++) {
        if (
          deviceType == "meter" ||
          field.indexOf("converted_io_pulse_cnt_") == 0
        ) {
          var name = getQueryVariable(devicePrefix + "pc_" + k + "_name");
        } else if (deviceType == "iostack") {
          var name = getQueryVariable("analog_" + k + "_name");
        }

        var isFound = false;
        var pc_value_select = "gl";
        if (
          getQueryVariable(devicePrefix + "pc_" + k + "_value_select") != "" &&
          _OPTIONS_PULSE_VALUE[
          getQueryVariable(devicePrefix + "pc_" + k + "_value_select")
          ] !== undefined
        )
          pc_value_select = getQueryVariable(
            devicePrefix + "pc_" + k + "_value_select"
          );

        var pc_converted_select = "gl";
        if (
          getQueryVariable(devicePrefix + "pc_" + k + "_converted_select") !=
          "" &&
          _OPTIONS_PULSE_VALUE[
          getQueryVariable(devicePrefix + "pc_" + k + "_converted_select")
          ] !== undefined
        )
          pc_converted_select = getQueryVariable(
            devicePrefix + "pc_" + k + "_converted_select"
          );

        var pcSelectVal = _DEFAULT_PC_VALUE[pc_value_select];
        var calculated_value =
          _PC_VALUE_MULTIPLIER[pc_value_select] *
          _DEFAULT_PC_VALUE[pc_converted_select];
        var pcValue = 1;
        if (getQueryVariable(devicePrefix + "pc_" + k + "_value") != "") {
          pcValue = getQueryVariable(devicePrefix + "pc_" + k + "_value");
        }

        if (field == "converted_pulse_cnt_" + k) {
          $.each(points[j], function (index, value) {
            var fetchvalue = floatval(
              points[j][index][1] * calculated_value * pcValue
            );
            fetchvalue = formatNumber(fetchvalue);
            points[j][index][1] = fetchvalue;
          });
          legendValue =
            "PC" + k + " - " + _OPTIONS_PULSE_VALUE_PLURAL[pc_converted_select];
          isFound = true;
        } else if (field == "pulse_cnt_" + k && deviceType == "meter") {
          $.each(points[j], function (index, value) {
            var fetchvalue = floatval(points[j][index][1] * pcValue);
            fetchvalue = formatNumber(fetchvalue);
            points[j][index][1] = fetchvalue;
          });
          legendValue =
            "PC" + k + " - " + _OPTIONS_PULSE_VALUE_PLURAL[pc_value_select];
          isFound = true;
        }

        if (
          ((field == "pulse_cnt_" + k && deviceType == "meter") ||
            field == "converted_pulse_cnt_" + k ||
            field == "converted_analog_in_" + k ||
            field == "converted_analog_in_" + k + "_average" ||
            field == "converted_analog_in_" + k + "_min" ||
            field == "converted_analog_in_" + k + "_max" ||
            field == "converted_io_pulse_cnt_" + k) &&
          name != ""
        ) {
          field_value = decodeURIComponent(name);
          legendValue = field_value;
          isFound = true;
        }

        if (field.indexOf("converted_analog_in_" + k) == 0) {
          displaySensorSymbol[j] = getQueryVariable("sensor_model_symbol_" + k);
        } else {
          displaySensorSymbol[j] = "";
        }

        seriesName = field_value + "," + field;
        if (isFound) break;
      }
    } else if (
      field.indexOf("fwd") == 0 ||
      field.indexOf("rev") == 0 ||
      field.indexOf("net") == 0 ||
      field.indexOf("kwh") == 0
    ) {
      var name = getQueryVariable("kwh_tot_yname");
      if (name != "") {
        field_value = decodeURIComponent(name);
        legendValue = field_value;
      }
      seriesName = field_value + "," + field;
    } else if (field.indexOf("converted_ow_") == 0) {
      let getFieldName = field.replace(/^converted_/, "");
      let name = getQueryVariable(`name_${getFieldName}`);
      displaySensorSymbol[j] = getQueryVariable(`symbol_${getFieldName}`);

      if (name != "") {
        field_value = decodeURIComponent(name);
        legendValue = field_value;
      }

      seriesName = field_value + "," + field;
    } else if (field.startsWith("state_inputs_") || field.startsWith("state_out_")) {
      let realField = ""
      if (field.startsWith("state_inputs_")) {
        realField = 'state_inputs'
      } else if (field.startsWith("state_out_")) {
        realField = 'state_out'
      }
      seriesName = field_value + "," + realField;
    }

    if (field.indexOf("pc_raw_") == 0) {
      seriesName = field_value + "," + field;
    }

    let index = j + 1;

    // This will be called only once to set the label in legend box.
    if (isInitialRead) {
      $("#field_" + index).text(legendValue);
      //Next line is for ranges_table
      document.querySelector("#symbol_field_" + index).innerHTML =
        displaySensorSymbol[j];
      $("#button_" + index).attr("for", field_value);

      if (isSummary == 0 || (isSummary == 1 && j == 0)) {
        chart.series[j].name = seriesName;
      }
    }

    // Set Legend Field value.
    let rowsCount = points[j].length - 1;
    let field1Value = getRangeValue(field, points[j][rowsCount][1]);

    $("#current_field" + index).text(field1Value);
  }
} // End setSeriesData()

function isAllZero(arr) {
  var result = true;
  $.each(arr, function (index, value) {
    if (arr[index][1] != 0) {
      result = false;
      return false;
    }
  });
  return result;
} // End isAllZero()

function convertArrayOfObjectsToCSV(args) {
  var result, ctr, keys, columnDelimiter, lineDelimiter, data;

  data = args.data || null;
  if (data == null || !data.length) {
    return "No Data Found";
  }

  columnDelimiter = args.columnDelimiter || ",";
  lineDelimiter = args.lineDelimiter || "\n";

  var fields = Object.keys(data[0]);
  var exclude_fields = [
    "Good",
    "End_Time_Stamp_UTC_ms",
    "End_Date",
    "Protocol",
  ];
  var rename_fields = {
    Start_Time_Stamp_UTC_ms: "Time_Stamp_UTC_ms",
    Start_Date: "Date",
  };
  var rename_keys = Object.keys(rename_fields);
  keys = [];
  var real_keys = [];
  fields.forEach(function (key) {
    if (rename_keys.indexOf(key) != -1) {
      real_keys.push(key);
      key = rename_fields[key];
    }
    if (exclude_fields.indexOf(key) == -1) {
      keys.push(key);
    }
  });

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach(function (item) {
    ctr = 0;
    keys.forEach(function (key) {
      real_keys.forEach(function (real_key) {
        if (rename_fields[real_key] == key) {
          key = real_key;
        }
      });

      if (ctr > 0) result += columnDelimiter;

      result += item[key];
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
} // End convertArrayOfObjectsToCSV()

function hideLegandLines() {
  var off_colors = ["b2c724", "f3b739", "b67af4", "5ad1ed"];
  var on_colors = ["0080ff", "ff8000", "d400ff", "0080ff"];

  for (var i = 1; i <= 4; i++) {
    var series = chart.series[i - 1];
    var line = "" + i;
    if (hide_lines.indexOf(line) > -1) {
      if (series.visible) {
        series.hide();
        $("#button_" + line).css("background", "#" + off_colors[line - 1]);
        $(".ranges_field_" + line).css(
          "background",
          "#" + off_colors[line - 1]
        );
      }
    } else if (!series.visible) {
      showBarGraphByID(line);
      series.show();
      $("#button_" + line).css("background", "#" + on_colors[line - 1]);
      $(".ranges_field_" + line).css("background", "#" + on_colors[line - 1]);
    }
  }
} // hideLegandLines()

function checkReads(reqReads) {
  if (reqReads <= 0 || reqReads > 1000) {
    show_error(
      "Error! Reads(" +
      reqReads +
      ") must be between 1 and 1000. It is being set to 1000 by default!"
    );
    reqReads = 1000;
  }
  return reqReads;
} // checkReads()

/* The first call to the API for request data */
function initialDataFill(reads, protocol) {
  if (_REQUEST.has("key")) {
    reads = checkReads(reads);

    if (
      getQueryVariable("scale") == "" ||
      getQueryVariable("scale") == _DEFAULT_VALUES.scale
    ) {
      if (isSummary == 1) {
        if (reads == "25") {
          $("#button1").addClass("active");
        } else {
          $("#button1").removeClass("active");
        }
        if (reads == "50") {
          $("#button2").addClass("active");
        } else {
          $("#button2").removeClass("active");
        }
        if (reads == "75") {
          $("#button3").addClass("active");
        } else {
          $("#button3").removeClass("active");
        }
      } else {
        if (reads == "100") {
          $("#button1").addClass("active");
        } else {
          $("#button1").removeClass("active");
        }
        if (reads == "500") {
          $("#button2").addClass("active");
        } else {
          $("#button2").removeClass("active");
        }
        if (reads == "1000") {
          $("#button3").addClass("active");
        } else {
          $("#button3").removeClass("active");
        }
      }
    } else {
      if (getQueryVariable("scale") == "15") {
        if (reads == "48") {
          $("#button1").addClass("active");
        } else {
          $("#button1").removeClass("active");
        }
        if (reads == "96") {
          $("#button2").addClass("active");
        } else {
          $("#button2").removeClass("active");
        }
        if (reads == "192") {
          $("#button3").addClass("active");
        } else {
          $("#button3").removeClass("active");
        }
      }
      if (getQueryVariable("scale") == "hr") {
        if (reads == "48") {
          $("#button1").addClass("active");
        } else {
          $("#button1").removeClass("active");
        }
        if (reads == "120") {
          $("#button2").addClass("active");
        } else {
          $("#button2").removeClass("active");
        }
        if (reads == "168") {
          $("#button3").addClass("active");
        } else {
          $("#button3").removeClass("active");
        }
      }
      if (getQueryVariable("scale") == "dy") {
        if (reads == "7") {
          $("#button1").addClass("active");
        } else {
          $("#button1").removeClass("active");
        }
        if (reads == "14") {
          $("#button2").addClass("active");
        } else {
          $("#button2").removeClass("active");
        }
        if (reads == "30") {
          $("#button3").addClass("active");
        } else {
          $("#button3").removeClass("active");
        }
      }
      if (getQueryVariable("scale") == "wk") {
        if (reads == "13") {
          $("#button1").addClass("active");
        } else {
          $("#button1").removeClass("active");
        }
        if (reads == "26") {
          $("#button2").addClass("active");
        } else {
          $("#button2").removeClass("active");
        }
        if (reads == "52") {
          $("#button3").addClass("active");
        } else {
          $("#button3").removeClass("active");
        }
      }
      if (getQueryVariable("scale") == "mo") {
        if (reads == "6") {
          $("#button1").addClass("active");
        } else {
          $("#button1").removeClass("active");
        }
        if (reads == "12") {
          $("#button2").addClass("active");
        } else {
          $("#button2").removeClass("active");
        }
        if (reads == "24") {
          $("#button3").addClass("active");
        } else {
          $("#button3").removeClass("active");
        }
      }
    }

    protocol = typeof protocol !== undefined ? protocol : "v3";
    totFieldValueAry = [];
    timeoutInit();

    let deviceType;
    if (_REQUEST.has("deviceType")) {
      deviceType = _REQUEST.get("deviceType").toLowerCase();
    }

    if (!deviceType || deviceType == "null") {
      deviceType = defaultDeviceType;
    }

    if (document.querySelector("#key_value").value != "") {
      let selectedValue = document.querySelector(
        "#select_device option:checked"
      ).value;
      if (selectedValue) {
        var meter_id = selectedValue;
      } else {
        var meter_id = "";
      }
    }

    if (!meter_id || meter_id == "null") {
      meter_id = defaultDevice;
    }

    //Check if device in the url exists in the array from the API
    checkUrlParams();

    var meter_span_text = meter_id + "/Real Time";
    if (isSummary == 1) {
      meter_span_text = meter_id + "/" + _SUPPORTED_SCALES[scale].desc;
    }

    document.querySelector("#meter_span").innerHTML = meter_span_text;

    var key_id = document.querySelector("#key_value").value;
    document.querySelector("#select_count").value = reads;
    setGraphFields();
    var graphURL;

    if (isSummary == 1) {
      var timelimit = reads;
      switch (scale) {
        case "15":
          timelimit = parseInt(timelimit / 4) + 1;
          break;
        case "hr":
          break;
        case "dy":
          timelimit *= 24;
          break;
        case "wk":
          timelimit *= 24 * 7;
          break;
        case "mo":
          timelimit *= 24 * 30;
      }

      if (!_REQUEST.get("historical")) {
        historycolor1 = "C4D83A";
        historycolor2 = "B3C135";
      } else {
        historycolor1 = _REQUEST.get("historical");
        if (historycolor1 == "Green") {
          historycolor1 = "C4D83A";
          historycolor2 = "B3C135";
        } else if (historycolor1 == "Blue") {
          historycolor1 = "5FCBE8";
          historycolor2 = "2EC0D7";
        } else if (historycolor1 == "Orange") {
          historycolor1 = "FFC92B";
          historycolor2 = "F3AD1B";
        } else if (historycolor1 == "Purple") {
          historycolor1 = "E566FF";
          historycolor2 = "aa00ff";
        }
      }

      if (deviceType == "meter") {
        graphURL =
          summaryURL +
          "meter?client=Widget&devices=" +
          meter_id +
          "&key=" +
          key_id +
          "&fields=" +
          graphFieldsForSummaryURL +
          "~" +
          defaultTotalField +
          "&report=" +
          scale +
          "&format=json&normalize=1&limit=" +
          reads +
          "&timelimit=" +
          timelimit;
      } else if (deviceType == "iostack") {
        graphURL =
          summaryURL +
          "iostack?client=Widget&devices=" +
          meter_id +
          "&key=" +
          key_id +
          "&fields=" +
          graphFieldsForSummaryURL +
          "~" +
          defaultTotalField +
          "&report=" +
          scale +
          "&format=json&normalize=1&limit=" +
          reads +
          "&timelimit=" +
          timelimit;
      }
      // Realtime
    } else if (deviceType == "iostack") {
      graphURL =
        baseURL +
        "readiostack?key=" +
        key_id +
        "&address=" +
        meter_id +
        "&count=" +
        reads +
        "&format=json&fields=" +
        graphFieldsForURL +
        "~" +
        defaultTotalField;
      for (var i = 1; i <= 4; i++) {
        graphURL = graphURL.replace(
          "converted_analog_in_" + i,
          "analog_in_" + i
        );
        graphURL = graphURL.replace(
          "converted_io_pulse_cnt_" + i,
          "pulse_cnt_" + i
        );
      }
    } else {
      graphURL =
        baseURL +
        "readMeter?key=" +
        key_id +
        "&meters=" +
        meter_id +
        "&count=" +
        reads +
        "&format=json&fields=" +
        graphFieldsForURL +
        "~" +
        defaultTotalField;
      graphURL = graphURL.replace(
        "Power_Factor_Ln_1",
        "Cos_Theta_Ln_1~Power_Factor_Ln_1"
      );
      graphURL = graphURL.replace(
        "Power_Factor_Ln_2",
        "Cos_Theta_Ln_2~Power_Factor_Ln_2"
      );
      graphURL = graphURL.replace(
        "Power_Factor_Ln_3",
        "Cos_Theta_Ln_3~Power_Factor_Ln_3"
      );
    }

    if (_timezone != "") {
      graphURL += "&timezone=" + _timezone;
    }

    // For initialDataFill()
    $.ajax({
      url: graphURL + "&status=good",
      cache: true,
      error: function () {
        if (protocol == "v3") {
          protocol = "v4";
          setTimeout(initialDataFill(reads, protocol), 1000);
        } else {
          //timeoutTrigger();
          validateURLInfo(meter_id);
        }
      },
      success: function (responseJSON) {
        let arrayInitialData = responseJSON

        if (isSummary === 0) {
          arrayInitialData = readStateInOut(responseJSON);
          if (deviceType == "meter") {
            arrayInitialData =
              responseJSON["readMeter"]["ReadSet"][0]["ReadData"];
          } else if (deviceType == "iostack") {
            arrayInitialData =
              responseJSON["readiostack"]["ReadSet"][0]["ReadData"];
          }
        }

        if (deviceType == "iostack") {
          arrayInitialData = convertData(arrayInitialData);
        }

        if (arrayInitialData.length == 0 && isSummary == 0) {
          var badResponseData = [];
          if (deviceType == "meter") {
            badResponseData =
              responseJSON["readMeter"]["ReadSet"][0]["ReadData"];
          } else if (deviceType == "iostack") {
            badResponseData =
              responseJSON["readiostack"]["ReadSet"][0]["ReadData"];
          }

          if (badResponseData.length == reads) {
            show_error(
              "No good reads reported by this device for the range selected",
              6500
            );
          }
        }

        if (arrayInitialData.length == 0 && protocol == "v3") {
          protocol = "v4";
          setTimeout(initialDataFill(reads, protocol), 1000);
        } else {
          var array_initial_data = getGraphData(arrayInitialData, true);
          // join the totFieldValueAry with current tot field array initially so that we can use to do the running total
          // totFieldValueAry = totFieldValueAry.concat(array_initial_data[4]);
          var date_obj = new Date();
          var usertimestamp = new Date().getTime();
          if (array_initial_data[0] == null) {
            validateURLInfo(meter_id, "null");
          } else {
            if (isSummary == 1 && !isAllZero(array_initial_data[0])) {
              chart.options.plotOptions.column.minPointLength = 2;
            }

            updatePointWidth(reads);
            setSeriesData(array_initial_data, true);

            if (isSummary == 1) {
              //Start xAxis tick interval
              var minTickInterval = 1000;
              switch (scale) {
                case "15":
                  //minTickInterval *= 60* 15;
                  break;
                case "hr":
                  minTickInterval *= 60 * 60;
                  break;
                case "dy":
                  minTickInterval *= 60 * 60 * 24;
                  break;
                case "wk":
                  minTickInterval *= 60 * 60 * 24 * 7;
                  break;
                case "mo":
                  minTickInterval *= 60 * 60 * 24 * 30;
                  break;
                default:
                  minTickInterval *= 5;
              }
              if (scale != "15") {
                chart.xAxis[0].update({
                  minTickInterval: minTickInterval,
                });
              }

              if (isNetKWHField == 1) {
                var serName = chart.series[0].name;
                chart.series[0].yAxis.update({
                  min: null,
                  reversed: true,
                });

                chart.series[0].update({
                  color: "#" + historycolor2,
                  negativeColor: "#" + historycolor1,
                });

                chart.series[0].yAxis.addPlotLine({
                  value: 0,
                  width: 1,
                  color: "white",
                });
                chart.series[0].name = serName;
              }
              //End xAxis tick interval
              chart.series[0].setData(array_initial_data[0]);
            } else {
              // Realtime
              if (compare == 0 && isEnableAreaGraph == 0) {
                if (isAllZero(array_initial_data[0])) {
                  chart.series[0].yAxis.setExtremes(null, 0.1);
                }
                if (isAllZero(array_initial_data[1])) {
                  chart.series[1].yAxis.setExtremes(null, 0.4);
                }
                if (isAllZero(array_initial_data[2])) {
                  chart.series[2].yAxis.setExtremes(null, 0.7);
                }
                if (isAllZero(array_initial_data[3])) {
                  chart.series[3].yAxis.setExtremes(null, 0.2);
                }
              }

              chart.series[0].setData(array_initial_data[0]);
              chart.series[1].setData(array_initial_data[1]);
              chart.series[2].setData(array_initial_data[2]);
              chart.series[3].setData(array_initial_data[3]);
              hideLegandLines();
              for (let i = 0; i < ALL_DEVICES[deviceType].length; i++) {
                if (ALL_DEVICES[deviceType][i].address == meter_id) {
                  if (deviceType == "meter") {
                    var read_rate = ALL_DEVICES[deviceType][i].read_interval;
                  } else if (deviceType == "iostack") {
                    var read_rate =
                      ALL_DEVICES[deviceType][i].send_interval * 1000;
                  }
                }
              }
              //var read_rate = getReadRate(key_id, deviceType, meter_id);
              var curTime = new Date().getTime() - _offset;
              var error_time = curTime - read_rate * reads * 1.25;
              var oldestReadTime = array_initial_data[0][0][0];
              if (oldestReadTime < error_time) {
                show_error(
                  "Error! Reads missing, device failed to report due to network issues or was offline  more then 25% of the time",
                  2500
                );
              }
            }

            setTotFieldValue(array_initial_data[4]);
            showBarGraphByID(0);

            if ($("#ranges_table").css("display") != "none") {
              var button_text = $("#toggle_button");
              button_text.text("Hide reads");
              $("#toggle_button").addClass("active");
            }

            if ($(".headbtndiv").css("display") == "none") {
              $("#toggleimage").attr("src", "./static/buttons/Down_Toggle.svg");
            }

            if ($("#extremes_div").css("display") != "none") {
              var button_text = $("#button_ranges");
              button_text.text("Hide range");
              $("#button_ranges").addClass("active");
              update_ranges();
            }
            if ($("#info_table").css("display") != "none") {
              showTable();
            }
            timeoutTrigger();
          }
        }
      },
    });
  }
} // End initialDataFill()

function fillDeviceTypes() {
  let create = "";

  for (let checkDevice in ALL_DEVICES) {
    let addDeviceType = "";
    if (checkDevice == "meter") {
      addDeviceType = "Meter";
    } else if (checkDevice == "iostack") {
      addDeviceType = "ioStack";
    }
    if (ALL_DEVICES[checkDevice].length > 0) {
      create +=
        "<option value=" + checkDevice + ">" + addDeviceType + "</option>";
    }
  }
  $("#select_device_type").find("option").remove().end().append(create);
}

// This function is for table data.
function calculatePowerFactorForTable(value) {
  var result;
  if (value < 100) {
    result = value / 100 + "L";
  } else if (value == 100) {
    result = 1.0;
  } else {
    result = (200 - value) / 100 + "C";
  }
  return result;
} // End calculatePowerFactorForTable()

// This is old power factor function which is used on graph
function calculate_power_factor(value) {
  var result;
  if (value < 1) {
    result = value + "L";
  } else if (value == 1) {
    result = 1.0;
  } else {
    result = (2 - value).toFixed(2) + "C";
  }
  return result;
} // End calculate_power_factor()

function downloadURL(url) {
  var iframe;
  iframe = document.getElementById("hiddenDownloader");
  if (iframe === null) {
    iframe = document.createElement("iframe");
    iframe.id = "hiddenDownloader";
    iframe.style.visibility = "hidden";
    iframe.style.display = "none";
    document.body.appendChild(iframe);
  }
  iframe.src = url;
} // downloadURL()

function fillCountryOptions() {
  $("#country").find("option").remove();
  $("#country").append(
    '<option value="" selected="selected">-- Select Country --</option>'
  );
  for (let key in _ALL_COUNTRY) {
    if (_country == key) {
      $("#country").append(
        '<option value="' +
        key +
        '" selected>' +
        _ALL_COUNTRY[key] +
        "</option>"
      );
    } else {
      $("#country").append(
        '<option value="' + key + '">' + _ALL_COUNTRY[key] + "</option>"
      );
    }
  }
  change_tzone();
} // fillCountryOptions()

function fillGraphFieldOptions() {
  var tmptable_fields_value;
  var tmpLineGraphFieldsValue;
  var tmpBarGraphFieldValue;
  var tmplayout_fields_value;
  var selectedGraph;

  deviceType = document.querySelector("#select_device_type").value;
  deviceType = deviceType.toLowerCase();

  if (isOnLoad == true) {
    isOnLoad = false;
    tmptable_fields_value = tableFields == -1 ? null : tableFields.split("~");
    tmpLineGraphFieldsValue =
      lineGraphFields == -1 ? null : lineGraphFields.split("~");
    tmpBarGraphFieldValue = barGraphField;
    tmplayout_fields_value = layout == -1 ? null : layout.split("~");
    selectedGraph = _GRAPH_TYPE == -1 ? "line" : _GRAPH_TYPE;
  } else {
    tmptable_fields_value = $("#tablefields").val();
    tmpLineGraphFieldsValue = $("#line_graph_fields").val();
    tmpBarGraphFieldValue = $("#bar_graph_field").val();
    tmplayout_fields_value = $("#layout").val();
    selectedGraph = $("#select_graph").val();
  }

  $("#layout").find("option").remove();
  $("#tablefields").find("option").remove();
  $("#line_graph_fields").find("option").remove();
  $("#bar_graph_field").find("option").remove();

  for (var key in _SUPPORTED_LAYOUTS) {
    var isSelected = "";
    if (tmplayout_fields_value) {
      $.each(tmplayout_fields_value, function (i, pSelectVal) {
        if (pSelectVal == key) {
          isSelected = "selected";
          return false;
        }
      });
    }
    $("#layout").append(
      '<option value="' +
      key +
      '" ' +
      isSelected +
      ">" +
      _SUPPORTED_LAYOUTS[key] +
      "</option>"
    );
  }
  let fieldGraph = "";
  let optionsTotalFields = "";
  let barGraphFieldsFGFO = "";

  if (deviceType == "meter") {
    fieldGraph = _ALL_FIELDS;
    optionsTotalFields = filterV3Meters();
    barGraphFieldsFGFO = _BAR_GRAPH_FIELDS;
  } else if (deviceType == "iostack") {
    fieldGraph = _ALL_FIELDS_IOSTACK;
    optionsTotalFields = _SUPPORTED_DEFAULTED_TOT_FIELDS_IOSTACK;
    barGraphFieldsFGFO = _BAR_GRAPH_FIELDS_IOSTACK;
  }

  // For table fields and line graph fields
  for (var key in fieldGraph) {
    var isTableFieldSelected = "";
    var isGraphFieldSelected = "";

    if (tmptable_fields_value) {
      $.each(tmptable_fields_value, function (i, pSelectVal) {
        if (pSelectVal == key) {
          isTableFieldSelected = "selected";
          return false;
        }
      });
    }
    if (tmpLineGraphFieldsValue instanceof Array) {
      $.each(tmpLineGraphFieldsValue, function (i, pSelectVal) {
        if (pSelectVal == key) {
          isGraphFieldSelected = "selected";
          return false;
        }
      });
    } else if (tmpLineGraphFieldsValue == key) {
      isGraphFieldSelected = "selected";
    }
    meter_id = $("#select_device option:selected").val();

    if (deviceType == "meter" && meter_version == "v3") {
      for (var v3Key in _V3_METER_DEFAULT_FIELDS) {
        if (key == _V3_METER_DEFAULT_FIELDS[v3Key]) {
          $("#tablefields").append(
            '<option value="' +
            key +
            '" ' +
            isTableFieldSelected +
            ">" +
            fieldGraph[key] +
            "</option>"
          );
          $("#line_graph_fields").append(
            '<option value="' +
            key +
            '" ' +
            isGraphFieldSelected +
            ">" +
            fieldGraph[key] +
            "</option>"
          );
          break;
        }
      }
    } else {
      if (key.indexOf("converted_") != 0) {
        $("#tablefields").append(
          '<option value="' +
          key +
          '" ' +
          isTableFieldSelected +
          ">" +
          fieldGraph[key] +
          "</option>"
        );
      }
      $("#line_graph_fields").append(
        '<option value="' +
        key +
        '" ' +
        isGraphFieldSelected +
        ">" +
        fieldGraph[key] +
        "</option>"
      );
    }
  }

  // Fill bar_graph_field option.
  $.each(optionsTotalFields, function (key, val) {
    let isGraphFieldSelected = "";
    if (deviceType == "meter") {
      if (
        _V4_GRAPH_FIELDS.indexOf(key) == -1 ||
        meter_version == "v4" ||
        meter_version == "v3"
      ) {
        if (tmpBarGraphFieldValue == key) {
          isGraphFieldSelected = "selected";
        } else if (_REQUEST.get("bar_graph_field")) {
          if (_REQUEST.get("bar_graph_field") == key) {
            isGraphFieldSelected = "selected";
          }
        }

        $("#bar_graph_field").append(
          '<option value="' +
          key +
          '" ' +
          isGraphFieldSelected +
          ">" +
          val.name +
          "</option>"
        );
      }
    } else if (deviceType == "iostack") {
      if (tmpBarGraphFieldValue == key) {
        isGraphFieldSelected = "selected";
      } else if (_REQUEST.get("io_bar_graph_field")) {
        if (_REQUEST.get("io_bar_graph_field") == key) {
          isGraphFieldSelected = "selected";
        }
      }

      $("#bar_graph_field").append(
        '<option value="' +
        key +
        '" ' +
        isGraphFieldSelected +
        ">" +
        val.name +
        "</option>"
      );
    }
  });

  $("#bar_graph_field").append(
    "<option disabled>   --------------------   </option>"
  );

  $.each(barGraphFieldsFGFO, function (key, obj) {
    var isGraphFieldSelected = "";
    if (deviceType == "meter") {
      if (obj.type == "v3" || meter_version == "v4") {
        if (tmpBarGraphFieldValue == key) {
          isGraphFieldSelected = "selected";
        } else if (_REQUEST.get("bar_graph_field")) {
          if (_REQUEST.get("bar_graph_field") == key) {
            isGraphFieldSelected = "selected";
          }
        }
        $("#bar_graph_field").append(
          '<option value="' +
          key +
          '" ' +
          isGraphFieldSelected +
          ">" +
          obj.name +
          "</option>"
        );
      }
    } else if (deviceType == "iostack") {
      if (tmpBarGraphFieldValue == key) {
        isGraphFieldSelected = "selected";
      } else if (_REQUEST.get("io_bar_graph_field")) {
        if (_REQUEST.get("io_bar_graph_field") == key) {
          isGraphFieldSelected = "selected";
        }
      }
      $("#bar_graph_field").append(
        '<option value="' +
        key +
        '" ' +
        isGraphFieldSelected +
        ">" +
        obj.name +
        "</option>"
      );
    }
  });

  // COMMON OPTION FOR METERS AND IOSTACK
  $("#layout").each(function () {
    var select = $(this),
      values = {};
    $("option", select)
      .each(function (i, option) {
        values[option.value] = option.selected;
      })
      .click(function (event) {
        var curSelection = this.value;
        values[this.value] = !values[this.value];
        var cnt = 0;
        $("option", select).each(function (i, option) {
          if (values[option.value] && option.value == "hide_all") {
            if (curSelection == "hide_all") {
              $("option", select).each(function (i, opt) {
                values[opt.value] = false;
                opt.selected = false;
              });
              $("select#layout option").removeAttr("selected");
              values["hide_all"] = true;
            } else {
              values[option.value] = false;
              option.selected = false;
            }
          }
          option.selected = values[option.value];
        });
      });
  });

  $("#line_graph_fields").each(function () {
    var select = $(this),
      values = {};
    $("option", select)
      .each(function (i, option) {
        values[option.value] = option.selected;
      })
      .click(function (event) {
        values[this.value] = !values[this.value];
        var cnt = 0;
        $("option", select).each(function (i, option) {
          if (values[option.value]) cnt++;

          if (cnt > 4 && values[option.value]) {
            alert("Please select only 4 values");
            $("option", select).each(function (i, opt) {
              values[opt.value] = false;
              opt.selected = false;
            });
            $("select#graphfields option").removeAttr("selected");
          } else {
            option.selected = values[option.value];
          }
        });
      });
  });

  $("#tablefields").each(function () {
    var select = $(this),
      values = {};
    $("option", select)
      .each(function (i, option) {
        values[option.value] = option.selected;
      })
      .click(function (event) {
        values[this.value] = !values[this.value];
        var cnt = 0;
        $("option", select).each(function (i, option) {
          option.selected = values[option.value];
        });
      });
  });
  fillTableFields();
} // End fillGraphFieldOptions()

function getRangeValue(fieldName, value) {
  let result = "";
  let direction = ""

  if (
    fieldName.indexOf("net_calc_watts") > -1 ||
    fieldName.indexOf("net_kwh") > -1
  ) {
    if (fieldName.indexOf("net_calc_watts") == 0 && isEnableAreaGraph == 0) {
      direction = "Rev";
      if (value <= 0) {
        direction = "Fwd";
        value *= -1;
      }
    } else {
      direction = "Fwd";
      if (value < 0) {
        direction = "Rev";
        value *= -1;
      }
    }
    result = numberWithCommas(value) + " " + direction;
  } else if (fieldName.indexOf("analog_in_") == 0) {
    result = value;
  } else if (fieldName.startsWith("state_inputs_") || fieldName.startsWith("state_out_")) {
    result = value;
  } else {
    result =
      fieldName.indexOf("power_factor_ln") > -1
        ? calculate_power_factor(value)
        : numberWithCommas(value);
  }

  return result;
} // End getRangeValue()

// Function for update table with MIN - MAX ranges
function update_ranges() {
  let extremes1 = chart.series[0].yAxis.getExtremes();
  let extremes2 = chart.series[1].yAxis.getExtremes();
  let extremes3 = chart.series[2].yAxis.getExtremes();
  let extremes4 = chart.series[3].yAxis.getExtremes();
  let fieldsArr = legendFields.toLowerCase().split("~");

  extremes1DataMin = getRangeValue(fieldsArr[0], extremes1.dataMin);
  extremes1DataMax = getRangeValue(fieldsArr[0], extremes1.dataMax);
  $("#field_1_min").text(extremes1DataMin);
  $("#field_1_max").text(extremes1DataMax);

  extremes2DataMin = getRangeValue(fieldsArr[1], extremes2.dataMin);
  extremes2DataMax = getRangeValue(fieldsArr[1], extremes2.dataMax);
  $("#field_2_min").text(extremes2DataMin);
  $("#field_2_max").text(extremes2DataMax);

  extremes3DataMin = getRangeValue(fieldsArr[2], extremes3.dataMin);
  extremes3DataMax = getRangeValue(fieldsArr[2], extremes3.dataMax);
  $("#field_3_min").text(extremes3DataMin);
  $("#field_3_max").text(extremes3DataMax);

  extremes4DataMin = getRangeValue(fieldsArr[3], extremes4.dataMin);
  extremes4DataMax = getRangeValue(fieldsArr[3], extremes4.dataMax);

  if (extremes4DataMin !== null && typeof extremes4DataMin === "string") {
    extremes4DataMin = extremes4DataMin.includes("null")
      ? ""
      : extremes4DataMin;
  }
  if (extremes4DataMax !== null && typeof extremes4DataMax === "string") {
    extremes4DataMax = extremes4DataMax.includes("null")
      ? ""
      : extremes4DataMax;
  }

  $("#field_4_min").text(extremes4DataMin);
  $("#field_4_max").text(extremes4DataMax);
} // End update_ranges()

//return formatted table
function getFormattedTable(data) {
  let excludedHeaderFields = [
    "Good",
    "Date",
    "Time",
    "Firmware",
    "Model",
    "End_Time_Stamp_UTC_ms",
    "Start_Date",
    "End_Date",
    "Meter",
    "Protocol",
    "Count",
    "device_time",
    "version_major",
    "version_minor",
    "hw_type",
    "model",
  ];
  let tmpHeaderFields = -1;
  if (headerArray != -1 && headerArray.length > 0) {
    tmpHeaderFields = "Time_Stamp_UTC_ms~" + headerArray.join("~");
  }
  let table = $('<table id="data-table" border="1" cellpadding="4" > <tbody>');

  if (deviceType == "meter") {
    summaryHeaderFields = _SUMMARY_HEADER_FIELDS;
  } else if (deviceType == "iostack") {
    summaryHeaderFields = _SUMMARY_HEADER_FIELDS_IOSTACK;
  }

  let checkHeader = true;

  $.each(data, function (i, item) {
    // Table header
    $.each(item, function (key, val) {
      if (key === "Good") {
        if (item[key] === 1 && checkHeader) {
          checkHeader = false;
          let row = $('<tr style="font-weight: bold;">');
          $.each(item, function (key, val) {
            if (excludedHeaderFields.indexOf(key) < 0) {
              if (isSummary == 1) {
                if (summaryHeaderFields.hasOwnProperty(key)) {
                  $(
                    "<td>" +
                    summaryHeaderFields[key].replace(/_/g, " ") +
                    "</td>"
                  ).appendTo(row);
                }
              } else if (
                tmpHeaderFields == -1 ||
                tmpHeaderFields.indexOf(key) > -1
              ) {
                if (_ALL_FIELDS.hasOwnProperty(key.toLowerCase())) {
                  key = _ALL_FIELDS[key.toLowerCase()];
                }
                if (key == "Time_Stamp_UTC_ms") {
                  key = "Date";
                }
                $("<td>" + key.replace(/_/g, " ") + "</td>").appendTo(row);
              }
            }
          });
          $("</tr>").appendTo(row);
          row.appendTo(table);
        }
      }
    });
  });

  $.each(data, function (i, item) {
    // Table body
    let row = $("<tr>");
    $.each(item, function (key, val) {
      if (excludedHeaderFields.indexOf(key) < 0) {
        if (
          excludedHeaderFields.indexOf(key) < 0 &&
          (tmpHeaderFields == -1 ||
            tmpHeaderFields.indexOf(key) > -1 ||
            isSummary == 1)
        ) {
          if (isSummary == 1) {
            if (summaryHeaderFields.hasOwnProperty(key)) {
              if (key == "Start_Time_Stamp_UTC_ms")
                val = new (Number(val - _offset))().toUTCString().slice(0, -4);
              $("<td>" + val + "</td>").appendTo(row);
            }
          } else {
            if (key.toLowerCase().indexOf("power_factor_ln") == 0)
              val = calculatePowerFactorForTable(val);
            else if (key == "Time_Stamp_UTC_ms")
              val = new Date(Number(val - _offset)).toUTCString().slice(0, -4);
            $("<td>" + val + "</td>").appendTo(row);
          }
        }
      }
    });
    $("</tr>").appendTo(row);
    row.appendTo(table);
  });
  $("<tbody>").appendTo(table);
  return table;
} // End getFormattedTable()

function decTo4BitBinary(dec, stateType) {
  let binary = ""
  let bitSize

  if (deviceType === 'iostack') {
    // Convert decimal to binary and pad with zeros to ensure 2, 3 and 4 bits
    bitSize = 4
  } else if (deviceType === 'meter') {
    if (stateType === "State_Inputs") {
      bitSize = 3
    } else if (stateType === "State_Out") {
      dec = dec - 1
      bitSize = 2
    }
  }

  binary = (dec >>> 0).toString(2).padStart(bitSize, "0");
  binary = bitSize == 4 ? binary.split("").reverse().join(":") : binary.split("").join(":");

  return binary;
}

/**
 * Request data from the server, add it to the TABLE and set a timeout to request again
 */
function requestTableData(start, freeze_widget, protocol) {
  if (_REQUEST.has("key")) {
    protocol = typeof protocol !== undefined ? protocol : "v3";
    let deviceType = $("#select_device_type option:selected").val();
    deviceType = deviceType.toLowerCase();
    let meter_id = $("#select_device option:selected").val();

    meter_id = typeof meter_id !== undefined ? meter_id : defaultDevice;
    let interval_is = $("#select_device option:selected").attr(
      "read_rate_attr"
    );

    let reads = $("#reads_amount").val();
    let key_id = $("#key_value").val();
    let defaultInterval = 5000;

    if (interval_is === undefined || interval_is < defaultInterval) {
      interval_is = defaultInterval;
    }

    let isFreeze = freeze_widget;
    if (widget_status == "hidden" || widget_status == "idle") {
      if (start == 0) {
        start = new Date().getTime();
      } else {
        var diff = new Date().getTime() - start;
        if (diff > freeze_time) {
          freeze_widget = true;
          document.body.style.opacity = 0.5;
        }
      }
    } else if (widget_status == "active") {
      start = 0;
      if (freeze_widget == true) {
        document.body.style.opacity = 1;
        initialDataFill(reads);
      }
      freeze_widget = false;
    }

    if (freeze_widget == false) {
      if (tableFields != -1 && headerArray == -1) {
        var tFields = tableFields.toLowerCase();
        tFields = tFields.split("~");
        var uniqueFields = [];
        $.each(tFields, function (i, el) {
          if ($.inArray(el, uniqueFields) === -1) uniqueFields.push(el);
        });

        headerArray = [];
        let allFields = "";
        if (deviceType == "meter") {
          allFields = _ALL_FIELDS;
        } else if (deviceType == "iostack") {
          allFields = _ALL_FIELDS_IOSTACK;
        }

        $.each(uniqueFields, function (index, value) {
          if (allFields.hasOwnProperty(value)) {
            headerArray.push(_ALL_SUMMARY_FIELDS[value]["key"]);
          }
        });
      }

      var tableURL;

      if (isSummary == 1) {
        tableURL =
          summaryURL +
          "meter?client=Widget&devices=" +
          meter_id +
          "&key=" +
          key_id +
          "&report=" +
          scale +
          "&format=json&limit=10&normalize=1";
        if (headerArray != -1 && headerArray.length > 0) {
          tableURL = tableURL + "&fields=" + headerArray.join("~");
        }

        interval_is = _SUPPORTED_SCALES[scale].interval;
        freeze_time = _SUPPORTED_SCALES[scale].freeze_time;
      } else if (deviceType == "iostack") {
        tableURL =
          baseURL +
          "readiostack?key=" +
          key_id +
          "&address=" +
          meter_id +
          "&count=10&format=json";
        if (headerArray != -1 && headerArray.length > 0) {
          tableURL = tableURL + "&fields=" + headerArray.join("~");
        }
      } else {
        tableURL =
          baseURL +
          "readMeter?key=" +
          key_id +
          "&meters=" +
          meter_id +
          "&count=10&format=json";
        if (headerArray != -1 && headerArray.length > 0) {
          tableURL = tableURL + "&fields=" + headerArray.join("~");
          tableURL = tableURL.replace(
            "Power_Factor_Ln_1",
            "Cos_Theta_Ln_1~Power_Factor_Ln_1"
          );
          tableURL = tableURL.replace(
            "Power_Factor_Ln_2",
            "Cos_Theta_Ln_2~Power_Factor_Ln_2"
          );
          tableURL = tableURL.replace(
            "Power_Factor_Ln_3",
            "Cos_Theta_Ln_3~Power_Factor_Ln_3"
          );
        }
      }

      if (_timezone != "") {
        tableURL += "&timezone=" + _timezone;
      }

      // For requestTableData()
      $.ajax({
        url: tableURL,
        cache: true,
        success: function (responseJSON) {
          let dataArray = responseJSON;
          if (isSummary === 0) {
            dataArray = readStateInOut(responseJSON);
          }

          if (dataArray.length == 0 && protocol == "v3") {
            // check the data for v4 protocol if v3 returns no data
            protocol = "v4";
            setTimeout(requestTableData(start, false, protocol), 1000);
          } else {
            var table = $("#info_table");

            table.html(getFormattedTable(dataArray));
            var rowCount = $("#info_table tr").length;
            if (!isFreeze) timeoutTrigger();

            if (rowCount === 0) {
              show_error("Error! No device data present.", 2500);
              setTimeout(requestTableData(start, false, protocol), interval_is);
            } else {
              if (table.css("display") === "block") {
                setTimeout(function () {
                  requestTableData(start, false, protocol);
                }, interval_is);
              }
            }
          }
        },
        error: function () {
          timeoutTrigger();
          show_error("Error! No device data present.", 2500);
          setTimeout(requestTableData(start, true), 1000);
        },
      });
    } else {
      setTimeout(function () {
        requestTableData(start, true);
      }, 1000);
    }
  }
} // End requestTableData()

function executeFormula(
  arrayData,
  formulaToConvert,
  originValue,
  keyToConvert,
  index
) {
  let sensorIndex;
  if (formulaToConvert != null) {
    let valueToConvert = parseFloat(arrayData[index][originValue]);

    if (keyToConvert.includes("converted_analog_in")) {
      sensorIndex = originValue.split("_");
      sensorIndex = sensorIndex[sensorIndex.length - 1];

      const analogMinValue = parseInt(
        getQueryVariable("analog_min_" + sensorIndex)
      );
      const analogMaxValue = parseInt(
        getQueryVariable("analog_max_" + sensorIndex)
      );
      if (valueToConvert < analogMinValue) {
        valueToConvert = analogMinValue;
      } else if (valueToConvert > analogMaxValue) {
        valueToConvert = analogMaxValue;
      }
    }

    formulaToConvert = formulaToConvert.replace("value", valueToConvert);

    // Evaluate the formula and show the result
    try {
      arrayData[index][keyToConvert] = math.evaluate(formulaToConvert);
      arrayData[index][keyToConvert] = parseFloat(
        (Math.round(arrayData[index][keyToConvert] * 100) / 100).toFixed(2)
      );
    } catch (e) {
      let deviceId = keyToConvert.includes("converted_analog_in")
        ? `sensor: ${sensorIndex}`
        : originValue;
      show_error("There is an error in the formula for " + deviceId, 5500);
    }
  } else {
    arrayData[index][keyToConvert] = arrayData[index][originValue];
  }
}

function convertData(arrayData) {
  let checkTypeOfGraph =
    isSummary == 1 ? "io_bar_graph_field" : "io_line_graph_fields";
  let lineGraphParam = _REQUEST.has(checkTypeOfGraph)
    ? _REQUEST.get(checkTypeOfGraph).toLowerCase().split("~")
    : [];
  let formulaToConvert;
  let keyToConvert;
  let originValue;
  let includeConverted = false;
  let paramsToConvert = [];

  if (lineGraphParam.length > 0) {
    lineGraphParam.forEach(function (param) {

      if (param.includes("converted_")) {
        includeConverted = true;
        paramsToConvert.push(param);
      }
    });
  } else {
    if (deviceType === "meter") {
      paramsToConvert.push("kwh_tot");
    } else if (deviceType == "iostack") {
      paramsToConvert.push("pulse_cnt_1");
    }
  }

  if (includeConverted) {
    for (let index in arrayData) {
      for (let key in arrayData[index]) {
        if (isSummary == 0) {
          paramsToConvert.forEach(function (param) {
            if (key.toLowerCase() == param.replace(/^converted_/, "")) {
              formulaToConvert = _REQUEST.get("formula_" + key.toLowerCase());
              formulaToConvert =
                formulaToConvert !== null
                  ? formulaToConvert.toLowerCase()
                  : formulaToConvert;
              originValue = key;
              keyToConvert = param;
            }
          });
        } else {
          keyToConvert = _REQUEST.get("io_bar_graph_field");
          if (_BAR_GRAPH_FIELDS_IOSTACK.hasOwnProperty(keyToConvert)) {
            paramsToConvert.forEach(function (param) {
              if (key.toLowerCase() == param.replace(/^converted_/, "")) {
                let getKey = key.toLowerCase().replace(/_[^_]*$/, "");
                formulaToConvert = _REQUEST.get("formula_" + getKey);
                formulaToConvert =
                  formulaToConvert !== null
                    ? formulaToConvert.toLowerCase()
                    : formulaToConvert;
                originValue = _BAR_GRAPH_FIELDS_IOSTACK[keyToConvert].value;
                keyToConvert = param;
              }
            });
          }
        }

        executeFormula(
          arrayData,
          formulaToConvert,
          originValue,
          keyToConvert,
          index
        );
      }
    }
  }

  return arrayData;
}

/**
 * Request data from the server, add it to the graph and set a timeout to request again
 */
function requestData(start, lastGoodReadTime, freeze_widget, protocol) {
  if (_REQUEST.has("key")) {
    protocol = typeof protocol !== undefined ? protocol : "v3";
    var meter_id = $("#select_device option:selected").val();
    if (!meter_id || meter_id == "null") {
      meter_id = defaultDevice;
    }

    var read_rate = $("#device_rate_value").text() * 1000;
    var key_id = $("#key_value_reserve").val();
    var reads = $("#reads_amount").val();

    setGraphFields();
    // Minimum read interval is 5 Sec
    var refreshRate = read_rate;
    var defaultMinRefreshRate = 5000;
    var refreshCount = 3;

    if (isSummary == 1) {
      // once summary support is removed from line graph
      //		then below logic can be removed

      defaultMinRefreshRate = _SUPPORTED_SCALES[scale].interval;
      freeze_time = _SUPPORTED_SCALES[scale].freeze_time;
      refreshRate = defaultMinRefreshRate;
      if (reads >= 1000) {
        refreshRate = defaultMinRefreshRate * 3;
      } else if (reads >= 500) {
        refreshRate = defaultMinRefreshRate * 2;
      }
      //refreshCount = (refreshRate/defaultMinRefreshRate)+2;
      if (lastGoodReadTime != 0) {
        var diffInSec = new Date().getTime() - lastGoodReadTime;
        refreshCount = parseInt(diffInSec / defaultMinRefreshRate + 2);
      }
    } else {
      if (refreshRate < defaultMinRefreshRate) {
        refreshRate = defaultMinRefreshRate;
      }

      refreshRate = refreshRate * 100 * 0.01;

      if (lastGoodReadTime != 0) {
        var diffInSec = new Date().getTime() - lastGoodReadTime;
        refreshCount = parseInt(diffInSec / read_rate + 2);
      }
    }

    if (widget_status == "hidden" || widget_status == "idle") {
      if (start == 0) {
        start = new Date().getTime();
      } else {
        var diff = new Date().getTime() - start;
        if (diff > freeze_time) {
          freeze_widget = true;
          document.body.style.opacity = 0.5;
        }
      }
    }

    if (widget_status == "active") {
      start = 0;
      if (freeze_widget == true) {
        document.body.style.opacity = 1;
        initialDataFill(reads, protocol);
      }
      freeze_widget = false;
    }
    if (freeze_widget == false) {
      var graphURL;
      if (isSummary == 1) {
        var timelimit = parseInt(refreshCount);
        switch (scale) {
          case "15":
            timelimit = parseInt(timelimit / 4) + 1;
            break;
          case "hr":
            break;
          case "dy":
            timelimit *= 24;
            break;
          case "wk":
            timelimit *= 24 * 7;
            break;
          case "mo":
            timelimit *= 24 * 30;
        }
        if (deviceType == "meter") {
          graphURL =
            summaryURL +
            "meter?client=Widget&devices=" +
            meter_id +
            "&key=" +
            key_id +
            "&fields=" +
            graphFieldsForSummaryURL +
            "~" +
            defaultTotalField +
            "&report=" +
            scale +
            "&format=json&normalize=1&limit=" +
            parseInt(refreshCount) +
            "&timelimit=" +
            timelimit;
        } else if (deviceType == "iostack") {
          graphURL =
            summaryURL +
            "iostack?client=Widget&devices=" +
            meter_id +
            "&key=" +
            key_id +
            "&fields=" +
            graphFieldsForSummaryURL +
            "~" +
            defaultTotalField +
            "&report=" +
            scale +
            "&format=json&normalize=1&limit=" +
            parseInt(refreshCount) +
            "&timelimit=" +
            timelimit;

          for (var i = 1; i <= 4; i++) {
            graphURL = graphURL.replace(
              "converted_analog_in_" + i,
              "analog_in_" + i + "_Last"
            );
            graphURL = graphURL.replace(
              "converted_io_pulse_cnt_" + i,
              "pulse_cnt_" + i + "_Last"
            );
          }
        }
      } else if (deviceType == "iostack") {
        graphURL =
          baseURL +
          "readiostack?key=" +
          key_id +
          "&address=" +
          meter_id +
          "&count=" +
          reads +
          "&format=json&fields=" +
          graphFieldsForURL +
          "~" +
          defaultTotalField;

        for (var i = 1; i <= 4; i++) {
          graphURL = graphURL.replace(
            "converted_analog_in_" + i,
            "analog_in_" + i
          );
          graphURL = graphURL.replace(
            "converted_io_pulse_cnt_" + i,
            "pulse_cnt_" + i
          );
        }
      } else {
        graphURL =
          baseURL +
          "readMeter?key=" +
          key_id +
          "&meters=" +
          meter_id +
          "&count=" +
          reads +
          "&format=json&fields=" +
          graphFieldsForURL +
          "~" +
          defaultTotalField;
        graphURL = graphURL.replace(
          "Power_Factor_Ln_1",
          "Cos_Theta_Ln_1~Power_Factor_Ln_1"
        );
        graphURL = graphURL.replace(
          "Power_Factor_Ln_2",
          "Cos_Theta_Ln_2~Power_Factor_Ln_2"
        );
        graphURL = graphURL.replace(
          "Power_Factor_Ln_3",
          "Cos_Theta_Ln_3~Power_Factor_Ln_3"
        );
      }

      if (_timezone != "") {
        graphURL += "&timezone=" + _timezone;
      }

      // For requestData()
      $.ajax({
        url: graphURL + "&status=good",
        cache: true,
        error: function () {
          if (protocol == "v3" && isSummary != 1) {
            protocol = "v4";
            setTimeout(
              requestData(start, lastGoodReadTime, false, protocol),
              1000
            );
          } else {
            const checkError = validateURLInfo(meter_id, "");
            if (checkError == "connectionServerError") {
              setTimeout(function () {
                requestData(start, lastGoodReadTime, false, protocol);
              }, 60000);
            }
          }
        },
        success: function (responseJSON) {
          let arrayInitialData = responseJSON;

          if (isSummary === 0) {
            arrayInitialData = readStateInOut(responseJSON);
            if (deviceType == "iostack") {
              arrayInitialData =
                responseJSON["readiostack"]["ReadSet"][0]["ReadData"];
            } else if (deviceType == "meter") {
              arrayInitialData =
                responseJSON["readMeter"]["ReadSet"][0]["ReadData"];
            }
          }

          if (deviceType == "iostack") {
            arrayInitialData = convertData(arrayInitialData);
          }

          var points = getGraphData(arrayInitialData, false);
          // add new tot values array in totFieldValueAry every time refresh
          // happened so that we will new point to get the running total
          // totFieldValueAry = totFieldValueAry.concat(points[4]);

          if (
            arrayInitialData.length == 0 &&
            protocol == "v3" &&
            isSummary != 1
          ) {
            protocol = "v4";
            setTimeout(
              requestData(start, lastGoodReadTime, false, protocol),
              1000
            );
          } else if (points[0]) {
            var last_val_set = 0;
            var pointsLength = points[0].length;

            setSeriesData(points, false);

            if (chart.series[0] && chart.series[0].data) {
              if (chart.series[0].data.length > 0) {
                for (i = 0; i < pointsLength; i++) {
                  var field1 = [points[0][i][0], points[0][i][1]];

                  if (
                    chart.series[0].data[chart.series[0].data.length - 1].x <
                    field1[0]
                  ) {
                    if (isSummary == 1) {
                      // For Bar Graph
                      if (pointsLength - i == 1) {
                        var animate = { duration: 20 };
                        chart.series[0].addPoint(field1, true, 1, animate);
                        last_val_set = 1;
                      } else {
                        chart.series[0].addPoint(field1, false, 1);
                      }
                    } else {
                      // For Line Graph
                      var field2 = [points[1][i][0], points[1][i][1]];
                      var field3 = [points[2][i][0], points[2][i][1]];
                      var field4 = [points[3][i][0], points[3][i][1]];
                      chart.series[0].addPoint(field1, false, 1);
                      chart.series[1].addPoint(field2, false, 1);
                      chart.series[2].addPoint(field3, false, 1);
                      if (pointsLength - i == 1) {
                        var animate = { duration: 20 };
                        chart.series[3].addPoint(field4, true, 1, animate);
                        last_val_set = 1;
                      } else {
                        chart.series[3].addPoint(field4, false, 1);
                      }
                    }
                  }

                }
              }
            }

            setTotFieldValue(points[4]);
            if ($("#extremes_div").css("display") != "none") {
              update_ranges();
            }
          }

          lastGoodReadTime = new Date().getTime();
          const readableTime = new Date(lastGoodReadTime).toLocaleString();

          setTimeout(function () {
            requestData(start, lastGoodReadTime, false, protocol);
          }, refreshRate);
        },
      });
    } else {
      setTimeout(function () {
        requestData(start, lastGoodReadTime, true);
      }, 1000);
    }
  }
} // End requestData()

// Function to get pulse ratio by meter id
function getpulseRatioByMeterID(meter_id, meterVersion) {
  meter_version = meterVersion;
  deviceType = document.querySelector("#select_device_type").value;
  deviceType = deviceType.toLowerCase();
  var key = document.querySelector("#key_value").value;
  let indexValue = "";
  var url = "";
  let deviceSize = 0;

  if (deviceType == "meter") {
    url =
      baseURL +
      "readMeter?key=" +
      key +
      "&meters=" +
      meter_id +
      "&count=1&format=json&fields=Pulse_Ratio_1~Pulse_Ratio_2~Pulse_Ratio_3";
    indexValue = "Pulse_Ratio_";
    deviceSize = 3;
  }

  if (meterVersion == "v4") {
    // For getpulseRatioByMeterID()
    $.ajax({
      url: url,
      success: function (responseJSON) {
        let arrayInitialData
        if (deviceType == "meter") {
          arrayInitialData =
            responseJSON["readMeter"]["ReadSet"][0]["ReadData"];
        }

        for (let i = 1; i <= deviceSize; i++) {
          if (arrayInitialData.length == 1) {
            document.getElementById("pc_" + i + "_ratio").value =
              arrayInitialData[0][indexValue + i] === undefined
                ? 1
                : arrayInitialData[0][indexValue + i];
          } else {
            document.getElementById("pc_" + i + "_ratio").value = 1;
          }
          getConvertedValue(i);
        }
      },
      cache: true,
    });
  }
} // End getpulseRatioByMeterID()

//Check is call is coming from iframe.
function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
} // End inIframe()

//Tooltip for Realtime
function showToolTip(curChart) {
  let displaySymbol = "";
  var tooltipText = "";
  var tmpSeriesName = curChart.series.name.split(",");
  var seriesName = tmpSeriesName;

  if (deviceType == "iostack" && tmpSeriesName[0] === undefined) {
    seriesName = tmpSeriesName;
    seriesName[0] = _ALL_FIELDS_IOSTACK[tmpSeriesName[1]];
  }

  if (seriesName.length > 1) {
    var field = seriesName[1];

    if (
      field.indexOf("converted_pulse_cnt_") == 0 ||
      (field.indexOf("pulse_cnt_") == 0 && deviceType == "meter") ||
      field.indexOf("converted_io_pulse_cnt_") == 0
    ) {
      var pulseIndex = field.replace(/[^+-\d.]/g, "");
      var pc_value_select = "gl";
      if (
        getQueryVariable(devicePrefix + "pc_" + pulseIndex + "_value_select") !=
        "" &&
        _OPTIONS_PULSE_VALUE[
        getQueryVariable(devicePrefix + "pc_" + pulseIndex + "_value_select")
        ] !== undefined
      )
        pc_value_select = getQueryVariable(
          devicePrefix + "pc_" + pulseIndex + "_value_select"
        );

      var pc_converted_select = "gl";
      if (
        getQueryVariable(
          devicePrefix + "pc_" + pulseIndex + "_converted_select"
        ) != "" &&
        _OPTIONS_PULSE_VALUE[
        getQueryVariable(
          devicePrefix + "pc_" + pulseIndex + "_converted_select"
        )
        ] !== undefined
      )
        pc_converted_select = getQueryVariable(
          devicePrefix + "pc_" + pulseIndex + "_converted_select"
        );

      if (
        field == "converted_pulse_cnt_" + pulseIndex ||
        field == "converted_io_pulse_cnt_" + pulseIndex
      ) {
        var unitName = _OPTIONS_PULSE_VALUE[pc_converted_select];
        if (curChart.y > 1) {
          unitName = _OPTIONS_PULSE_VALUE_PLURAL[pc_converted_select];
        }
      } else if (field == "pulse_cnt_" + pulseIndex && deviceType == "meter") {
        var unitName = _OPTIONS_PULSE_VALUE[pc_value_select];
        if (curChart.y > 1) {
          unitName = _OPTIONS_PULSE_VALUE_PLURAL[pc_value_select];
        }
      }
      tooltipText = " " + unitName;
    } else if (field.indexOf("pc_raw_") == 0) {
      tooltipText = " Pulses";
    }
  }

  var result = "";

  if (
    curChart.series.name.indexOf("Net Watts") > -1 ||
    curChart.series.name.indexOf("Net kWh") > -1
  ) {
    if (
      curChart.series.name.indexOf("Net Watts") == 0 &&
      isEnableAreaGraph == 0
    ) {
      var direction = "Reverse";
      var value = curChart.y;
      if (value <= 0) {
        direction = "Forward";
        value *= -1;
      }
    } else {
      var direction = "Forward";
      var value = curChart.y;
      if (value < 0) {
        direction = "Reverse";
        value *= -1;
      }
    }
    result = numberWithCommas(value) + " " + direction;
  } else {
    result =
      curChart.series.name.indexOf("Power Factor Line") > -1
        ? calculate_power_factor(curChart.y)
        : numberWithCommas(curChart.y);
  }

  if (seriesName.length > 1) {
    for (const element of seriesName) {
      if (element.includes("converted_analog_in_")) {
        let analogIndex = field.replace(/[^+-\d.]/g, "");
        displaySymbol = analogSensorSymbol[analogIndex - 1];
      } else if (element.startsWith("converted_ow_")) {
        let getSymbol = field.replace("converted_", "symbol_");
        displaySymbol = _REQUEST.get(getSymbol);
      }
    }
  }

  return (
    '<span style="font-size: 110%;">' +
    seriesName[0] +
    "</span>" +
    ': <span style="font-size: 120%; font-weight: bold; ">' +
    result +
    tooltipText +
    " " +
    displaySymbol +
    "</span><br/>" +
    getFormattedDate(curChart.x) +
    " "
  );
} // End showToolTip()

// Tooltip for Summary
function barGraphTooltip(curChart) {
  var result = "";
  let displaySymbol = "";

  if (
    curChart.series.name.indexOf("Net Watts") > -1 ||
    curChart.series.name.indexOf("Net kWh") > -1
  ) {
    var direction = "Forward";
    var value = curChart.y;
    if (value < 0) {
      direction = "Reverse";
      value *= -1;
    }
    result = numberWithCommas(value) + " " + direction;
  } else {
    result =
      curChart.series.name.indexOf("Power Factor Line") > -1
        ? calculate_power_factor(curChart.y)
        : numberWithCommas(curChart.y);
  }

  var tooltipText = "";
  var seriesName = curChart.series.name.split(",");

  if (seriesName.length > 1) {
    var field = seriesName[1];

    if (
      field.indexOf("fwd") == 0 ||
      field.indexOf("rev") == 0 ||
      field.indexOf("net") == 0 ||
      field.indexOf("kwh") == 0
    ) {
      tooltipText = " kWh";
      var pCost = 0;
      if (getQueryVariable("kwh_tot_cost") != "") {
        pCost = getQueryVariable("kwh_tot_cost");
      }

      var pCurrency = "USD";
      if (
        getQueryVariable("kwh_currency") != "" &&
        _CURRENCY_OPTIONS[getQueryVariable("kwh_currency")] !== undefined
      )
        pCurrency = getQueryVariable("kwh_currency");

      pCost = numberWithTwoDecimalPlace(pCost * curChart.y);
      if (pCost != 0)
        tooltipText +=
          '</span><br/>Cost:<span style="font-size: 120%; font-weight: bold; "> ' +
          getHexCode(_CURRENCY_OPTIONS[pCurrency]["hex-code"]) +
          numberWithCommas(pCost);
    } else if (
      field.indexOf("converted_pulse_cnt_") == 0 ||
      (field.indexOf("pulse_cnt_") == 0 && deviceType == "meter")
    ) {
      var pulseIndex = field.replace(/[^+-\d.]/g, "");
      var pc_value_select = "gl";
      if (
        getQueryVariable(devicePrefix + "pc_" + pulseIndex + "_value_select") !=
        "" &&
        _OPTIONS_PULSE_VALUE[
        getQueryVariable(devicePrefix + "pc_" + pulseIndex + "_value_select")
        ] !== undefined
      )
        pc_value_select = getQueryVariable(
          devicePrefix + "pc_" + pulseIndex + "_value_select"
        );

      var pc_converted_select = "gl";
      if (
        getQueryVariable(
          devicePrefix + "pc_" + pulseIndex + "_converted_select"
        ) != "" &&
        _OPTIONS_PULSE_VALUE[
        getQueryVariable(
          devicePrefix + "pc_" + pulseIndex + "_converted_select"
        )
        ] !== undefined
      )
        pc_converted_select = getQueryVariable(
          devicePrefix + "pc_" + pulseIndex + "_converted_select"
        );

      var pCost = 0;
      if (getQueryVariable(devicePrefix + "pc" + pulseIndex + "_cost") != "")
        pCost = getQueryVariable(devicePrefix + "pc" + pulseIndex + "_cost");

      var pCurrency = "USD";

      if (
        getQueryVariable(devicePrefix + "pc" + pulseIndex + "_currency") !=
        "" &&
        _CURRENCY_OPTIONS[
        getQueryVariable(devicePrefix + "pc" + pulseIndex + "_currency")
        ] !== undefined
      )
        pCurrency = getQueryVariable(
          devicePrefix + "pc" + pulseIndex + "_currency"
        );

      if (field == "converted_pulse_cnt_" + pulseIndex) {
        var unitName = _OPTIONS_PULSE_VALUE[pc_converted_select];
        var cost_multiplier =
          _PC_VALUE_MULTIPLIER[pc_value_select] *
          _DEFAULT_PC_VALUE[pc_converted_select];
        pCost = numberWithTwoDecimalPlace(
          (pCost * curChart.y) / cost_multiplier
        );

        var unitName = _OPTIONS_PULSE_VALUE[pc_converted_select];
        if (curChart.y > 1) {
          unitName = _OPTIONS_PULSE_VALUE_PLURAL[pc_converted_select];
        }
      } else if (field == "pulse_cnt_" + pulseIndex && deviceType == "meter") {
        pCost = numberWithTwoDecimalPlace(pCost * curChart.y);
        var unitName = _OPTIONS_PULSE_VALUE[pc_value_select];
        if (curChart.y > 1) {
          unitName = _OPTIONS_PULSE_VALUE_PLURAL[pc_value_select];
        }
      }

      if (pCost == 0) {
        tooltipText = " " + unitName;
      } else {
        tooltipText =
          " " +
          unitName +
          '</span><br/>Cost:<span style="font-size: 120%; font-weight: bold; "> ' +
          getHexCode(_CURRENCY_OPTIONS[pCurrency]["hex-code"]) +
          numberWithCommas(pCost);
      }
    } else if (field.indexOf("pc_raw_") == 0) {
      tooltipText = " Pulses";
    }
  }

  if (seriesName.length > 1) {
    for (const element of seriesName) {
      if (element.includes("converted_analog_in_")) {
        let analogIndex = field.replace(/[^+-\d.]/g, "");
        displaySymbol = analogSensorSymbol[analogIndex - 1];
      } else if (element.startsWith("converted_ow_")) {
        let getSymbol = field.replace("converted_", "symbol_");
        getSymbol = getSymbol.replace(/_[^_]*$/, "");
        displaySymbol = _REQUEST.get(getSymbol);
      }
    }
  }
  if (
    getQueryVariable("scale") == "dy" ||
    getQueryVariable("scale") == "wk" ||
    getQueryVariable("scale") == "mo"
  ) {
    return (
      '<span style="font-size: 110%;">' +
      seriesName[0] +
      "</span>" +
      ': <span style="font-size: 120%; font-weight: bold; ">' +
      result +
      tooltipText +
      " " +
      displaySymbol +
      "</span><br/>" +
      getFormattedDate(curChart.x, true) +
      " "
    );
  } else {
    return (
      '<span style="font-size: 110%;">' +
      seriesName[0] +
      "</span>" +
      ': <span style="font-size: 120%; font-weight: bold; ">' +
      result +
      tooltipText +
      " " +
      displaySymbol +
      "</span><br/>" +
      getFormattedDate(curChart.x) +
      " "
    );
  }
} // End barGraphTooltip()

function validateURLInfo(meter_id, errorReason = "") {
  let isMeterMatched = false;
  timeoutTrigger();

  $.each(ALL_DEVICES[deviceType], function (index) {
    if (deviceType == "meter" || deviceType == "iostack") {
      if (meter_id == ALL_DEVICES[deviceType][index].address)
        isMeterMatched = true;
    }
  });
  if (!isMeterMatched) {
    show_error("Error! Bad device number.");
    return "badDeviceNumber";
  } else {
    if (isSummary == 1) {
      show_error(
        "No good reads reported by this device for the range selected"
      );
      return "notGoodReads";
    } else if (errorReason == "null") {
      show_error(
        `There is no recent real-time data for ${deviceType}: ${meter_id}`,
        5000
      );
      return "notDataforDevice";
    } else {
      show_error("Error! Could not connect to server. Please try later.");
      return "connectionServerError";
    }
  }
}

function showTable() {
  var button_text = $("#button_table");
  $("#info_table").css({ display: "block" });
  button_text.text("Hide table");
  $("#button_table").addClass("active");
  timeoutInit();
  requestTableData(0, false);
}

function setOptionsValues(optionName, i) {
  let returnValue = "";
  let optionValue = document.querySelector(`#${optionName}`).value;
  if (optionValue != "") {
    if (optionName.includes("_symbol")) {
      returnValue =
        "&sensor_model_symbol_" + i + "=" + encodeURIComponent(optionValue);
    } else if (optionName.includes("_convert")) {
      returnValue = `&${optionName}=${encodeURIComponent(
        optionValue.toLowerCase()
      )}`;
    } else {
      returnValue = `&${optionName}=${encodeURIComponent(optionValue)}`;
    }
  }

  return returnValue;
}

async function setSettingsPopupValues() {
  if (document.getElementById("select_device_type").value != "")
    var deviceType = document.getElementById("select_device_type").value;
  deviceType = deviceType.toLowerCase();

  if (document.getElementById("select_device").value != "")
    var deviceRead = document.getElementById("select_device").value;

  const selectCount = document.getElementById("select_count");
  let reads_read =
    selectCount.value != "" &&
      parseInt(selectCount.value) !== _DEFAULT_VALUES.reads
      ? selectCount.value
      : "";

  if (document.getElementById("total_field").value != "") {
    var total_field = document.getElementById("total_field").value;
  }

  if (document.getElementById("key_value").value != "") {
    var key_read_val = document.getElementById("key_value").value;
  }

  if (document.getElementById("line_graph_fields").value != "") {
    var selectedlinegraph_value = $("#line_graph_fields").val();
    selectedlinegraph_value = selectedlinegraph_value.join("~");
  }
  // Relative View
  if (document.getElementById("compare").value != "") {
    var compareField = document.getElementById("compare").value;
  }

  const setBarGraphField = document.querySelector("#bar_graph_field");
  if (setBarGraphField.value != "") {
    var selectedbargraph_value = setBarGraphField.value;
    if (selectedbargraph_value instanceof Array) {
      selectedbargraph_value = selectedbargraph_value.join("~");
    }
  }

  let selectedtable_value;
  if (document.getElementById("tablefields").value != "") {
    let table_fields_value = $("#tablefields").val();
    selectedtable_value = table_fields_value.join("~");
  }

  let baseURL = location.protocol + "//" + location.host + location.pathname;
  let reloadURL =
    "key=" +
    key_read_val +
    "&deviceType=" +
    deviceType +
    "&deviceAddress=" +
    deviceRead;

  // First Tab
  if (reads_read !== "") reloadURL += "&reads=" + reads_read;
  const selectScaleElement = document.querySelector("#select_scale");
  const selectGraphElement = document.querySelector("#select_graph");
  reloadURL +=
    selectGraphElement.value == 1 ? "&scale=" + selectScaleElement.value : "";

  // Layout/Fields Tab
  if (deviceType == "meter") {
    if (
      total_field != undefined &&
      total_field !== _DEFAULT_VALUES.meter.total_field
    )
      reloadURL += "&total_field=" + total_field;

    if (document.getElementById("line_graph_fields").value != "")
      reloadURL += "&line_graph_fields=" + selectedlinegraph_value;

    if (
      setBarGraphField.value != "" &&
      setBarGraphField.value !== _DEFAULT_VALUES.meter.bar_graph_field
    )
      reloadURL += "&bar_graph_field=" + selectedbargraph_value;

    if (document.getElementById("tablefields").value != "")
      reloadURL += "&table_fields=" + selectedtable_value;

    // Get values from url for iostack
    if (_REQUEST.has("io_total_field"))
      reloadURL += "&io_total_field=" + _REQUEST.get("io_total_field");
    if (_REQUEST.has("io_line_graph_fields"))
      reloadURL +=
        "&io_line_graph_fields=" + _REQUEST.get("io_line_graph_fields");
    //Compare
    if (_REQUEST.has("io_compare"))
      reloadURL += "&io_compare=" + _REQUEST.get("io_compare");
    if (_REQUEST.has("io_compare_min_y"))
      reloadURL += "&io_compare_min_y=" + _REQUEST.get("io_compare_min_y");
    if (_REQUEST.has("io_compare_max_y"))
      reloadURL += "&io_compare_max_y=" + _REQUEST.get("io_compare_max_y");

    if (_REQUEST.has("io_bar_graph_field"))
      reloadURL += "&io_bar_graph_field=" + _REQUEST.get("io_bar_graph_field");
    if (_REQUEST.has("io_table_fields"))
      reloadURL += "&io_table_fields=" + _REQUEST.get("io_table_fields");
  } else if (deviceType == "iostack") {
    if (
      total_field != undefined &&
      total_field !== _DEFAULT_VALUES.iostack.io_total_field
    )
      reloadURL += "&io_total_field=" + total_field;

    if (document.getElementById("line_graph_fields").value != "")
      reloadURL += "&io_line_graph_fields=" + selectedlinegraph_value;

    if (
      setBarGraphField.value != "" &&
      setBarGraphField.value !== _DEFAULT_VALUES.iostack.io_bar_graph_field
    )
      reloadURL += "&io_bar_graph_field=" + selectedbargraph_value;

    if (document.getElementById("tablefields").value != "")
      reloadURL += "&io_table_fields=" + selectedtable_value;

    // Get values from url for meter
    if (_REQUEST.has("total_field"))
      reloadURL += "&total_field=" + _REQUEST.get("total_field");

    if (_REQUEST.has("line_graph_fields"))
      reloadURL += "&line_graph_fields=" + _REQUEST.get("line_graph_fields");

    //Compare
    if (_REQUEST.has("compare"))
      reloadURL += "&compare=" + _REQUEST.get("compare");
    if (_REQUEST.has("compare_min_y"))
      reloadURL += "&compare_min_y=" + _REQUEST.get("compare_min_y");
    if (_REQUEST.has("compare_max_y"))
      reloadURL += "&compare_max_y=" + _REQUEST.get("compare_max_y");

    if (_REQUEST.has("bar_graph_field"))
      reloadURL += "&bar_graph_field=" + _REQUEST.get("bar_graph_field");
    if (_REQUEST.has("table_fields"))
      reloadURL += "&table_fields=" + _REQUEST.get("table_fields");
  }

  // Relative View
  if (
    compareField != "" &&
    parseInt(compareField) !== _DEFAULT_VALUES.compare
  ) {
    let compareParam = "";

    if (deviceType == "meter") {
      compareParam = "&compare=";
      compareMinParam = "&compare_min_y=";
      compareMaxParam = "&compare_max_y=";
    } else if (deviceType == "iostack") {
      compareParam = "&io_compare=";
      compareMinParam = "&io_compare_min_y=";
      compareMaxParam = "&io_compare_max_y=";
    }

    reloadURL += compareParam + compareField;
    if (compareField == 1) {
      // Set min / max value for relative view
      let minYValue = document.querySelector("#compare_min");
      let maxYValue = document.querySelector("#compare_max");
      minYValue = parseFloat(minYValue.value);
      maxYValue = parseFloat(maxYValue.value);

      if (!isNaN(minYValue)) {
        minYValue = compareMinParam + minYValue;
      } else {
        minYValue = "";
      }
      if (!isNaN(maxYValue)) {
        maxYValue = compareMaxParam + maxYValue;
      } else {
        maxYValue = "";
      }

      reloadURL += minYValue + maxYValue;
    }
  }

  var appendURL = "";
  var kwh_tot_yname = document.getElementById("kwh_tot_yname").value;
  var kwh_tot_cost = document.getElementById("kwh_tot_cost").value;
  var kwh_currency = document.getElementById("kwh_currency").value;

  if (kwh_tot_yname != "")
    appendURL += "&kwh_tot_yname=" + encodeURIComponent(kwh_tot_yname);
  if (kwh_tot_cost != "")
    appendURL += "&kwh_tot_cost=" + encodeURIComponent(kwh_tot_cost);
  if (kwh_currency != "" && kwh_currency !== _DEFAULT_VALUES.kwh_currency)
    appendURL += "&kwh_currency=" + encodeURIComponent(kwh_currency);

  // For Analog Tab data
  for (let i = 1; i <= 4; i++) {
    let analogName = document.querySelector("#analog_" + i + "_name").value;
    appendURL +=
      analogName != ""
        ? `&analog_${i}_name=${encodeURIComponent(analogName)}`
        : "";

    let analogList = document.querySelector("#analog_list_" + i).value;

    appendURL +=
      analogList !== "" && analogList !== _DEFAULT_VALUES.iostack.analog_list
        ? `&analog_list_${i}=${encodeURIComponent(analogList)}`
        : "";

    let sensorModel = document.querySelector("#sensor_model_" + i).value;
    appendURL +=
      sensorModel != ""
        ? `&sensor_model_${i}=${encodeURIComponent(sensorModel)}`
        : "";

    let analogMin = document.querySelector("#analog_min_" + i).value;
    appendURL +=
      analogMin != "" &&
        parseInt(analogMin) !== _DEFAULT_VALUES.iostack.analog_min
        ? `&analog_min_${i}=${encodeURIComponent(analogMin)}`
        : "";

    let analogMax = document.querySelector("#analog_max_" + i).value;
    appendURL +=
      analogMax != "" &&
        parseInt(analogMax) !== _DEFAULT_VALUES.iostack.analog_max
        ? `&analog_max_${i}=${encodeURIComponent(analogMax)}`
        : "";

    let analogSymbol = document.querySelector("#analog_" + i + "_symbol").value;
    appendURL +=
      analogSymbol != ""
        ? `&sensor_model_symbol_${i}=${encodeURIComponent(analogSymbol)}`
        : "";

    let analogConvert = document.querySelector("#formula_analog_in_" + i).value;
    appendURL +=
      analogConvert != ""
        ? `&formula_analog_in_${i}=${encodeURIComponent(
          analogConvert.toLowerCase()
        )}`
        : "";
  }

  // For Pulse Tab
  for (let i = 1; i <= 4; i++) {
    if (deviceType == "meter") {
      checkPcName = "pc_" + i + "_name";
      checkPcValue = "pc_" + i + "_value";
      checkPcValueSelect = "pc_" + i + "_value_select";
      checkPcConvertedSelect = "pc_" + i + "_converted_select";
      checkPcCost = "pc" + i + "_cost";
      checkPcCurrency = "pc" + i + "_currency";
      appendURL += _REQUEST.get("io_pc_" + i + "_name")
        ? "&io_pc_" + i + "_name=" + _REQUEST.get("io_pc_" + i + "_name")
        : "";
      appendURL += _REQUEST.get("io_pc_" + i + "_value")
        ? "&io_pc_" + i + "_value=" + _REQUEST.get("io_pc_" + i + "_value")
        : "";
      appendURL += _REQUEST.get("io_pc_" + i + "_value_select")
        ? "&io_pc_" +
        i +
        "_value_select=" +
        _REQUEST.get("io_pc_" + i + "_value_select")
        : "";
      appendURL += _REQUEST.get("io_pc_" + i + "_converted_select")
        ? "&io_pc_" +
        i +
        "_converted_select=" +
        _REQUEST.get("io_pc_" + i + "_converted_select")
        : "";
      appendURL += _REQUEST.get("io_pc" + i + "_cost")
        ? "&io_pc" + i + "_cost=" + _REQUEST.get("io_pc" + i + "_cost")
        : "";
      appendURL += _REQUEST.get("io_pc" + i + "_currency")
        ? "&io_pc" + i + "_currency=" + _REQUEST.get("io_pc" + i + "_currency")
        : "";
    } else if (deviceType == "iostack") {
      checkPcName = "io_pc_" + i + "_name";
      checkPcValue = "io_pc_" + i + "_value";
      checkPcValueSelect = "io_pc_" + i + "_value_select";
      checkPcConvertedSelect = "io_pc_" + i + "_converted_select";
      checkPcCost = "io_pc" + i + "_cost";
      checkPcCurrency = "io_pc" + i + "_currency";
      appendURL += _REQUEST.get("pc_" + i + "_name")
        ? "&pc_" + i + "_name=" + _REQUEST.get("pc_" + i + "_name")
        : "";
      appendURL += _REQUEST.get("pc_" + i + "_value")
        ? "&pc_" + i + "_value=" + _REQUEST.get("pc_" + i + "_value")
        : "";
      appendURL += _REQUEST.get("pc_" + i + "_value_select")
        ? "&pc_" +
        i +
        "_value_select=" +
        _REQUEST.get("pc_" + i + "_value_select")
        : "";
      appendURL += _REQUEST.get("pc_" + i + "_converted_select")
        ? "&pc_" +
        i +
        "_converted_select=" +
        _REQUEST.get("pc_" + i + "_converted_select")
        : "";
      appendURL += _REQUEST.get("pc" + i + "_cost")
        ? "&pc" + i + "_cost=" + _REQUEST.get("pc" + i + "_cost")
        : "";
      appendURL += _REQUEST.get("pc" + i + "_currency")
        ? "&pc" + i + "_currency=" + _REQUEST.get("pc" + i + "_currency")
        : "";
    }

    var name = document.getElementById("pc_" + i + "_name").value;
    var pValue = document.getElementById("pc_" + i + "_value").value;
    var pValueSelect = document.getElementById(
      "pc_" + i + "_value_select"
    ).value;
    var pConvertedSelect = document.getElementById(
      "pc_" + i + "_converted_select"
    ).value;
    var pCost = document.getElementById("pc" + i + "_cost").value;
    var pCurrency = document.getElementById("pc" + i + "_currency").value;

    if (name != "") appendURL += "&" + checkPcName + "=" + name;
    if (pValue != "") appendURL += "&" + checkPcValue + "=" + pValue;

    if (
      pValueSelect != "" &&
      pValueSelect != _DEFAULT_VALUES.pc_value_select &&
      _REQUEST.get(checkPcValueSelect) != ""
    )
      appendURL += "&" + checkPcValueSelect + "=" + pValueSelect;

    if (
      pConvertedSelect != "" &&
      pConvertedSelect != _DEFAULT_VALUES.pc_converted_select &&
      _REQUEST.get(checkPcConvertedSelect) != ""
    )
      appendURL += "&" + checkPcConvertedSelect + "=" + pConvertedSelect;

    if (pCost != "") appendURL += "&" + checkPcCost + "=" + pCost;

    if (
      pCurrency != "" &&
      pCurrency != _DEFAULT_VALUES.pc_currency &&
      _REQUEST.get(checkPcCurrency) != ""
    )
      appendURL += "&" + checkPcCurrency + "=" + pCurrency;
  }

  // For Wire Tab data
  for (let key in _ALL_FIELDS_IOSTACK) {
    if (key.startsWith("ow_")) {
      let wireName = document.querySelector(`#name_${key}`).value;
      let wireFormula = document.querySelector(`#formula_${key}`).value;
      let wireSymbol = document.querySelector(`#symbol_${key}`).value;

      if (wireName != "") {
        appendURL += `&name_${key}=${encodeURIComponent(wireName)}`;
      }
      if (wireFormula != "") {
        appendURL += `&formula_${key}=${encodeURIComponent(
          wireFormula.toLowerCase()
        )}`;
      }
      if (wireSymbol != "") {
        appendURL += `&symbol_${key}=${encodeURIComponent(wireSymbol)}`;
      }
    }
  }

  reloadURL += appendURL;

  if (document.getElementById("tz").value != "") {
    var timezonevalue = document.getElementById("tz").value;
    reloadURL += "&timezone=" + timezonevalue;
  }

  const historyColor = document.querySelector("#history_color");
  if (
    historyColor.value != "" &&
    historyColor.value !== _DEFAULT_VALUES.historical
  ) {
    reloadURL += "&historical=" + historyColor.value;
  }

  let layoutField = document.getElementById("layout").value;
  if (layoutField != "") {
    var layout_value = $("#layout").val();
    if (compareField == 1) {
      if (layout_value.includes("show_range")) {
        let tmpLayout = [];
        layout_value.forEach(function (element) {
          if (element !== "show_range") {
            tmpLayout.push(element);
          }
        });
        layout_value = tmpLayout;
      }
    }
    layout_value = layout_value.join("~");
    reloadURL += "&layout=" + layout_value;
  }

  if (_REQUEST.get("deviceType") == "meter" && deviceType == "iostack") {
    reloadURL += "&last_meter=" + _REQUEST.get("deviceAddress");
  } else if (_REQUEST.get("last_iostack")) {
    reloadURL += "&last_iostack=" + _REQUEST.get("last_iostack");
  }
  if (_REQUEST.get("deviceType") == "iostack" && deviceType == "meter") {
    reloadURL += "&last_iostack=" + _REQUEST.get("deviceAddress");
  } else if (_REQUEST.get("last_meter")) {
    reloadURL += "&last_meter=" + _REQUEST.get("last_meter");
  }

  let paramToUpdate = reloadURL;
  if (reloadURL.length > 512) {
    reloadURL = await compressData(reloadURL);
    paramToUpdate = `q=${reloadURL}`;
  }

  reloadURL = `${baseURL}?${paramToUpdate}`;
  location.replace(reloadURL);
} // Ends setSettingsPopupValues()

function getSelectValues(select) {
  var result = [];
  var options = select && select.options;
  var opt;

  for (var i = 0, iLen = options.length; i < iLen; i++) {
    opt = options[i];

    if (opt.selected) {
      result.push(opt.value || opt.text);
    }
  }

  return result;
}

function createHTMLElement(
  HTMLTag = "",
  elementId = "",
  elementClass = "",
  elementText = "",
  elementStyle = [],
  elementAttribute = []
) {
  let newHTMLElement = document.createElement(HTMLTag);

  if (elementId != "") {
    newHTMLElement.id = elementId;
  }

  if (elementClass != "") {
    elementClass = elementClass.split(" ");
    for (i = 0; i < elementClass.length; i++) {
      newHTMLElement.classList.add(elementClass[i]);
    }
  }
  if (elementText != "") {
    newHTMLElement.innerText = elementText;
  }
  if (elementStyle.length > 0) {
    if (elementStyle.length % 2 == 0) {
      for (i = 0; i < elementStyle.length - 1; i += 2) {
        newHTMLElement.style[elementStyle[i]] = elementStyle[i + 1];
      }
    } else {
      console.warn("You have to add styles in couples");
    }
  }
  if (elementAttribute.length > 0) {
    if (elementAttribute.length % 2 == 0) {
      for (i = 0; i < elementAttribute.length - 1; i += 2) {
        newHTMLElement.setAttribute(
          elementAttribute[i],
          elementAttribute[i + 1]
        );
      }
    } else {
      console.warn("You have to add styles in couples");
    }
  }
  return newHTMLElement;
}

function createInput(
  inputId = "",
  inputClass = "",
  inputValue = "",
  inputMaxLength = "",
  inputType = "",
  inputStyle = [],
  inputAttribute = []
) {
  let newInput = document.createElement("input");

  if (inputId != "") {
    newInput.id = inputId;
  }
  if (inputClass != "") {
    inputClass = inputClass.split(" ");
    for (i = 0; i < inputClass.length; i++) {
      newInput.classList.add(inputClass[i]);
    }
  }
  if (inputValue != "") {
    newInput.value = inputValue;
  }
  if (inputMaxLength != "") {
    newInput.maxLength = inputMaxLength;
  }
  if (inputType != "") {
    newInput.type = inputType;
  }
  if (inputStyle.length > 0) {
    if (inputStyle.length % 2 == 0) {
      for (i = 0; i < inputStyle.length - 1; i += 2) {
        newInput.style[inputStyle[i]] = inputStyle[i + 1];
      }
    } else {
      console.warn("You have to add styles in couples");
    }
  }
  if (inputAttribute.length > 0) {
    if (inputAttribute.length % 2 == 0) {
      for (i = 0; i < inputAttribute.length - 1; i += 2) {
        newInput.setAttribute(inputAttribute[i], inputAttribute[i + 1]);
      }
    } else {
      console.warn("You have to add attributes in couples");
    }
  }
  return newInput;
}

function initialAnalogSensorSymbol() {
  for (x = 0; x < 4; x++) {
    sensorIndex = x + 1;
    analogSensorSymbol[x] = getQueryVariable(
      "sensor_model_symbol_" + sensorIndex
    );
  }
}

function createTabAnalog() {
  const tabSize = 4;
  const tabAnalogData = document.querySelector("#tab_analog_data");
  const tabsControlAnalog = document.querySelector("#tabs_control_analog");
  for (let x = 0; x < tabSize; x++) {
    let tabIndex = x + 1;

    let tabAnalog = createHTMLElement(
      (HTMLTag = "li"),
      (elementId = "li_tab_analog_" + tabIndex),
      (elementClass = ""),
      (elementText = ""),
      (elementStyle = ["opacity", 1]),
      (elementAttribute = [
        "onclick",
        "manageSettingTabs('tab_analog_" + tabIndex + "')",
      ])
    );
    let aTabAnalog = createHTMLElement(
      (HTMLTag = "a"),
      (elementId = "analog" + (tabIndex + 1) + "a"),
      (elementClass = ""),
      (elementText = "Sensor " + tabIndex),
      (elementStyle = []),
      (elementAttribute = [])
    );
    tabAnalog.appendChild(aTabAnalog);
    tabsControlAnalog.appendChild(tabAnalog);

    let analogTab = createHTMLElement(
      (HTMLTag = "div"),
      (elementId = "tab_analog_" + tabIndex),
      (elementClass = "tabsdisplay"),
      (elementText = ""),
      (elementStyle = ["display", "block"])
    );

    // Divs

    let analogDiv = createHTMLElement(
      (HTMLTag = "div"),
      (elementId = ""),
      (elementClass = "pulsediv")
    );
    let sensorNameDiv = createHTMLElement(
      (HTMLTag = "div"),
      (elementId = "sensor_name"),
      (elementClass = "pulsediv")
    );
    let labelSensorName = createHTMLElement(
      (HTMLTag = "label"),
      (elementId = "analog" + tabIndex + "_label_name"),
      (elementClass = "tablabel"),
      (elementText = "Name")
    );
    let inputSensorName = createInput(
      (inputId = "analog_" + tabIndex + "_name"),
      (inputClass = "tab_pulse_input"),
      (inputValue = ""),
      (inputMaxLength = "25"),
      (inputType = "text"),
      (inputStyle = []),
      (inputAttribute = [])
    );
    sensorNameDiv.appendChild(labelSensorName);
    sensorNameDiv.appendChild(inputSensorName);
    analogDiv.appendChild(sensorNameDiv);
    analogTab.appendChild(analogDiv);

    let sensorDiv = createHTMLElement(
      (HTMLTag = "div"),
      (elementId = "sensor_type"),
      (elementClass = "pulsediv")
    );
    let sensorSelectType = createHTMLElement(
      (HTMLTag = "label"),
      (elementId = ""),
      (elementClass = "tablabel"),
      (elementText = "Sensor type")
    );
    sensorDiv.appendChild(sensorSelectType);
    let analogList = createHTMLElement(
      (HTMLTag = "select"),
      (elementId = "analog_list_" + tabIndex),
      (elementClass = ""),
      (elementText = ""),
      (elementStyle = ["opacity", 1, "width", "108px"]),
      (elementAttribute = [])
    );
    sensorDiv.appendChild(analogList);
    analogDiv.appendChild(sensorDiv);
    analogTab.appendChild(analogDiv);

    let sensorModelDiv = createHTMLElement(
      (HTMLTag = "div"),
      (elementId = "sensor_model"),
      (elementClass = "pulsediv")
    );
    let sensorModelSelect = createHTMLElement(
      (HTMLTag = "label"),
      (elementId = ""),
      (elementClass = "tablabel"),
      (elementText = "Sensor model")
    );
    let sensorModelList = createHTMLElement(
      (HTMLTag = "select"),
      (elementId = "sensor_model_" + tabIndex),
      (elementClass = ""),
      (elementText = ""),
      (elementStyle = ["opacity", 1, "width", "108px"]),
      (elementAttribute = [])
    );
    sensorModelDiv.appendChild(sensorModelSelect);
    sensorModelDiv.appendChild(sensorModelList);
    analogDiv.appendChild(sensorModelDiv);
    analogTab.appendChild(analogDiv);

    let analogDivMin = createHTMLElement(
      (HTMLTag = "div"),
      (elementId = ""),
      (elementClass = "pulsediv")
    );
    let labelMin = createHTMLElement(
      (HTMLTag = "label"),
      (elementId = "analog_min_label_" + tabIndex),
      (elementClass = "tablabel"),
      (elementText = "Min AI Value")
    );
    let inputMin = createInput(
      (inputId = "analog_min_" + tabIndex),
      (inputClass = "tab_pulse_input"),
      (inputValue = ""),
      (inputMaxLength = ""),
      (inputType = "number"),
      (inputStyle = []),
      (inputAttribute = [])
    );
    analogDivMin.appendChild(labelMin);
    analogDivMin.appendChild(inputMin);
    analogDiv.appendChild(analogDivMin);

    let analogDivMax = createHTMLElement(
      (HTMLTag = "div"),
      (elementId = ""),
      (elementClass = "pulsediv")
    );
    let labelMax = createHTMLElement(
      (HTMLTag = "label"),
      (elementId = "analog_max_label_" + tabIndex),
      (elementClass = "tablabel"),
      (elementText = "Max AI Value")
    );
    let inputMax = createInput(
      (inputId = "analog_max_" + tabIndex),
      (inputClass = "tab_pulse_input"),
      (inputValue = ""),
      (inputMaxLength = ""),
      (inputType = "number"),
      (inputStyle = []),
      (inputAttribute = [])
    );
    analogDivMax.appendChild(labelMax);
    analogDivMax.appendChild(inputMax);
    analogDiv.appendChild(analogDivMax);

    let analogDivConvert = createHTMLElement(
      (HTMLTag = "div"),
      (elementId = ""),
      (elementClass = "pulsediv")
    );
    let labelConvert = createHTMLElement(
      (HTMLTag = "label"),
      (elementId = "formula_analog_in_" + tabIndex + "_label"),
      (elementClass = "tablabel"),
      (elementText = "Conversion formula")
    );
    let inputConvert = createInput(
      (inputId = "formula_analog_in_" + tabIndex),
      (inputClass = "tab_pulse_input"),
      (inputValue = ""),
      (inputMaxLength = ""),
      (inputType = "text"),
      (inputStyle = []),
      (inputAttribute = ["placeholder", "value"])
    );

    analogDivConvert.appendChild(labelConvert);
    analogDivConvert.appendChild(inputConvert);
    analogDiv.appendChild(analogDivConvert);

    let analogDivSymbol = createHTMLElement(
      (HTMLTag = "div"),
      (elementId = ""),
      (elementClass = "pulsediv")
    );
    let labelSymbol = createHTMLElement(
      (HTMLTag = "label"),
      (elementId = "analog" + tabIndex + "_symbol_label"),
      (elementClass = "tablabel"),
      (elementText = "Symbol")
    );
    let inputSymbol = createInput(
      (inputId = "analog_" + tabIndex + "_symbol"),
      (inputClass = "tab_pulse_input"),
      (inputValue = ""),
      (inputMaxLength = "3"),
      (inputType = "text"),
      (inputStyle = []),
      (inputAttribute = ["placeholder", "Max. 3 characters"])
    );

    analogDivSymbol.appendChild(labelSymbol);
    analogDivSymbol.appendChild(inputSymbol);
    analogDiv.appendChild(analogDivSymbol);

    let applyButton = createHTMLElement(
      (HTMLTag = "div"),
      (elementId = ""),
      (elementClass = "rollover"),
      (elementText = ""),
      (elementStyle = ["float", "left"])
    );
    let aAnalog2Update = createHTMLElement(
      (HTMLTag = "a"),
      (elementId = "analog2_update"),
      (elementClass = ""),
      (elementText = "Save & Apply"),
      (elementStyle = []),
      (elementAttribute = [
        "onclick",
        "javascript: return setSettingsPopupValues();",
      ])
    );
    applyButton.appendChild(aAnalog2Update);
    analogTab.appendChild(applyButton);

    tabAnalogData.appendChild(analogTab);
  }
} //END createTabAnalog

function createTabWire() {
  const tabWireData = document.querySelector("#tab_wire_data");

  // Add the wire to the input select
  let wireDiv = createHTMLElement(
    (HTMLTag = "div"),
    (elementId = "wire_list"),
    (elementClass = "wireDiv")
  );

  // wireListDiv
  let wireListDiv = createHTMLElement(
    (HTMLTag = "div"),
    (elementId = "div_wire_list")
  );
  let wireSelectListLabel = createHTMLElement(
    (HTMLTag = "label"),
    (elementId = ""),
    (elementClass = "tablabel"),
    (elementText = "1 Wire Sensor")
  );

  let wireList = createHTMLElement(
    (HTMLTag = "select"),
    (elementId = "select_wire_sensor"),
    (elementClass = ""),
    (elementText = ""),
    (elementStyle = ["opacity", 1, "width", "160px"]),
    (elementAttribute = [])
  );

  wireListDiv.appendChild(wireSelectListLabel);
  wireListDiv.appendChild(wireList);
  wireDiv.appendChild(wireListDiv);
  //
  let wireSensor1;

  for (let key in _ALL_FIELDS_IOSTACK) {
    if (key.startsWith("ow_")) {
      let wireSensor = document.createElement("option");
      wireSensor.id = key;
      wireSensor.value = key;
      wireSensor.text = _ALL_FIELDS_IOSTACK[key];

      wireList.appendChild(wireSensor);
      //Create each sensor options
      let showDivWire = key === "ow_1_1_degc" ? "block" : "none";

      wireSensor1 = createHTMLElement(
        (HTMLTag = "div"),
        (elementId = `div_${key}`),
        (elementClass = ""),
        (elementText = ""),
        (elementStyle = ["display", showDivWire]),
        (elementAttribute = [])
      );

      // div for name
      let divWireName = createHTMLElement(
        (HTMLTag = "div"),
        (elementId = "div_name_" + key),
        (elementClass = "wireDiv"),
        (elementText = ""),
        (elementStyle = ""),
        (elementAttribute = [])
      );
      let wireName = createHTMLElement(
        (HTMLTag = "label"),
        (elementId = ""),
        (elementClass = "tablabel"),
        (elementText = "Name")
      );
      let inputWireName = createInput(
        (inputId = `name_${key}`),
        (inputClass = "tab_pulse_input"),
        (inputValue = ""),
        (inputMaxLength = ""),
        (inputType = "text"),
        (inputStyle = []),
        (inputAttribute = ["placeholder", _ALL_FIELDS_IOSTACK[key]])
      );
      divWireName.appendChild(wireName);
      divWireName.appendChild(inputWireName);
      wireSensor1.appendChild(divWireName);
      wireDiv.appendChild(wireSensor1);
      // End div for name

      // div for formula
      let divWireFormula = createHTMLElement(
        (HTMLTag = "div"),
        (elementId = "div_formula_" + key),
        (elementClass = "wireDiv"),
        (elementText = ""),
        (elementStyle = ""),
        (elementAttribute = [])
      );
      let wireFormula = createHTMLElement(
        (HTMLTag = "label"),
        (elementId = ""),
        (elementClass = "tablabel"),
        (elementText = "Conversion formula")
      );
      let isTemp = key.includes("degc") ? "value * 9 / 5 + 32" : "value";
      let inputWireFormula = createInput(
        (inputId = `formula_${key}`),
        (inputClass = "tab_pulse_input"),
        (inputValue = ""),
        (inputMaxLength = ""),
        (inputType = "text"),
        (inputStyle = []),
        (inputAttribute = ["placeholder", isTemp])
      );
      divWireFormula.appendChild(wireFormula);
      divWireFormula.appendChild(inputWireFormula);
      wireSensor1.appendChild(divWireFormula);
      // End div for formula

      // div for symbol
      let divWireSymbol = createHTMLElement(
        (HTMLTag = "div"),
        (elementId = "div_symbol_" + key),
        (elementClass = "pulsediv"),
        (elementText = ""),
        (elementStyle = ""),
        (elementAttribute = [])
      );
      let wireSymbolLabel = createHTMLElement(
        (HTMLTag = "label"),
        (elementId = ""),
        (elementClass = "tablabel"),
        (elementText = "Symbol")
      );
      let symbolChar = key.includes("degc")
        ? "E.g. °F - Max. 3 Chars"
        : "Max. 3 characters";
      let inputWireSymbol = createInput(
        (inputId = `symbol_${key}`),
        (inputClass = "tab_pulse_input"),
        (inputValue = ""),
        (inputMaxLength = "3"),
        (inputType = "text"),
        (inputStyle = []),
        (inputAttribute = ["placeholder", symbolChar])
      );
      divWireSymbol.appendChild(wireSymbolLabel);
      divWireSymbol.appendChild(inputWireSymbol);
      wireSensor1.appendChild(divWireSymbol);
      // End Symbol
    }
  }

  tabWireData.appendChild(wireDiv);

  let applyButton = createHTMLElement(
    (HTMLTag = "div"),
    (elementId = ""),
    (elementClass = "rollover"),
    (elementText = ""),
    (elementStyle = ["float", "left"])
  );
  let aWire2Update = createHTMLElement(
    (HTMLTag = "a"),
    (elementId = "wire2_update"),
    (elementClass = ""),
    (elementText = "Save & Apply"),
    (elementStyle = []),
    (elementAttribute = [
      "onclick",
      "javascript: return setSettingsPopupValues();",
    ])
  );
  applyButton.appendChild(aWire2Update);
  tabWireData.appendChild(applyButton);
} //END createTabWire

function createTabPulse() {
  const tabSize = 4;
  const tabPulseKwh = document.querySelector("#tab_pulse_kwh_data");
  const tabsControl = document.querySelector("#tabs_control");

  for (let x = 0; x < tabSize; x++) {
    tabIndex = x + 1;

    let tabPulse = createHTMLElement(
      (HTMLTag = "li"),
      (elementId = "li_tab_pulse_" + tabIndex),
      (elementClass = ""),
      (elementText = ""),
      (elementStyle = ["opacity", 1]),
      (elementAttribute = [
        "onclick",
        "manageSettingTabs('tab_pulse_" + tabIndex + "')",
      ])
    );
    let aTabPulse = createHTMLElement(
      (HTMLTag = "a"),
      (elementId = "pulse" + (tabIndex + 1) + "a"),
      (elementClass = ""),
      (elementText = "Pulse " + tabIndex),
      (elementStyle = []),
      (elementAttribute = [])
    );
    tabPulse.appendChild(aTabPulse);
    tabsControl.appendChild(tabPulse);

    let pulseTab = createHTMLElement(
      (HTMLTag = "div"),
      (elementId = "tab_pulse_" + tabIndex),
      (elementClass = "tabsdisplay"),
      (elementText = ""),
      (elementStyle = ["display", "none"])
    );

    // Div 1
    let pulseDiv1 = createHTMLElement(
      (HTMLTag = "div"),
      (elementId = ""),
      (elementClass = "pulsediv")
    );

    let labelName = createHTMLElement(
      (HTMLTag = "label"),
      (elementId = ""),
      (elementClass = "tablabel"),
      (elementText = "Name")
    );
    let inputName = createInput(
      (inputId = "pc_" + tabIndex + "_name"),
      (inputClass = "tab_pulse_input"),
      (inputValue = ""),
      (inputMaxLength = "25"),
      (inputType = "text"),
      (inputStyle = []),
      (inputAttribute = [])
    );

    pulseDiv1.appendChild(labelName);
    pulseDiv1.appendChild(inputName);
    pulseTab.appendChild(pulseDiv1);

    // Div 2
    let pulseDiv2 = createHTMLElement(
      (HTMLTag = "div"),
      (elementId = ""),
      (elementClass = "pulsediv")
    );
    let labelPulseValue = createHTMLElement(
      (HTMLTag = "label"),
      (elementId = ""),
      (elementClass = "tablabel"),
      (elementText = "Pulse Value")
    );
    let inputPc1 = createInput(
      (inputId = "pc_" + tabIndex + "_value"),
      (inputClass = "fixedwidth"),
      (inputValue = ""),
      (inputMaxLength = ""),
      (inputType = "number"),
      (inputStyle = ["opacity", 1, "width", "32px"]),
      (inputAttribute = ["onkeyup", "getConvertedValue(" + tabIndex + ")"])
    );
    let valueSelectPc1 = createHTMLElement(
      (HTMLTag = "select"),
      (elementId = "pc_" + tabIndex + "_value_select"),
      (elementClass = ""),
      (elementText = ""),
      (elementStyle = ["opacity", 1, "width", "108px"]),
      (elementAttribute = ["onchange", "getConvertedValue(" + tabIndex + ");"])
    );
    pulseDiv2.appendChild(labelPulseValue);
    pulseDiv2.appendChild(inputPc1);
    pulseDiv2.appendChild(valueSelectPc1);
    pulseTab.appendChild(pulseDiv2);
    // Div 3
    let pulseDiv3 = createHTMLElement(
      (HTMLTag = "div"),
      (elementId = ""),
      (elementClass = "pulsediv")
    );
    let labelCostLabel = createHTMLElement(
      (HTMLTag = "label"),
      (elementId = "pc" + tabIndex + "_cost_label"),
      (elementClass = "tablabel"),
      (elementText = "1 US Gallon = ")
    );
    let inputPc1Cost = createInput(
      (inputId = "pc" + tabIndex + "_cost"),
      (inputClass = "fixedwidth"),
      (inputValue = ""),
      (inputMaxLength = ""),
      (inputType = "number"),
      (inputStyle = ["opacity", 1, "width", "32px"]),
      (inputAttribute = ["onkeyup", "getConvertedValue(" + tabIndex + ")"])
    );
    let valueSelectPc1Currency = createHTMLElement(
      (HTMLTag = "select"),
      (elementId = "pc" + tabIndex + "_currency"),
      (elementClass = ""),
      (elementText = ""),
      (elementStyle = ["opacity", 1, "width", "108px"]),
      (elementAttribute = ["onchange", "getConvertedValue(" + tabIndex + ");"])
    );

    pulseDiv3.appendChild(labelCostLabel);
    pulseDiv3.appendChild(inputPc1Cost);
    pulseDiv3.appendChild(valueSelectPc1Currency);
    pulseTab.appendChild(pulseDiv3);

    // Inputs
    let inputPc1OptValue = createInput(
      (inputId = "pc_" + tabIndex + "_optvalue"),
      (inputClass = ""),
      (inputValue = "gl"),
      (inputMaxLength = ""),
      (inputType = "hidden")
    );
    let inputPc1OptText = createInput(
      (inputId = "pc_" + tabIndex + "_opttext"),
      (inputClass = ""),
      (inputValue = "US Gallon"),
      (inputMaxLength = ""),
      (inputType = "hidden")
    );

    pulseTab.appendChild(inputPc1OptValue);
    pulseTab.appendChild(inputPc1OptText);
    // Div4
    let pulseDiv4 = createHTMLElement(
      (HTMLTag = "div"),
      (elementId = ""),
      (elementClass = ""),
      (elementText = "Omnimeter v.4 Pulse Input:")
    );
    let divRCorners = createHTMLElement(
      (HTMLTag = "div"),
      (elementId = "rcorners"),
      (elementClass = "pulsediv")
    );
    let labelPulseInputRatio = createHTMLElement(
      (HTMLTag = "label"),
      (elementId = ""),
      (elementClass = "tablabel"),
      (elementText = "Pulse Input Ratio")
    );
    let inputPc1Ratio = createInput(
      (inputId = "pc_" + tabIndex + "_ratio"),
      (inputClass = "tabinput"),
      (inputValue = ""),
      (inputMaxLength = ""),
      (inputType = "text"),
      (inputStyle = []),
      (inputAttribute = ["readonly", "true"])
    );
    let divRCornersChild = createHTMLElement(
      (HTMLTag = "div"),
      (elementId = ""),
      (elementClass = ""),
      (elementText = " :1"),
      (elementStyle = ["marginTop", "7px", "float", "left"])
    );

    let centerPulseIncrement = createHTMLElement(
      (HTMLTag = "center"),
      (elementId = ""),
      (elementClass = "aligncenter"),
      (elementText = " Omnimeter Pulse Increment = ")
    );
    let bPulse1ValuesIs = createHTMLElement(
      (HTMLTag = "b"),
      (elementId = "pulse" + tabIndex + "valueis")
    );
    centerPulseIncrement.appendChild(bPulse1ValuesIs);

    let centerPulse1Cost = createHTMLElement(
      (HTMLTag = "center"),
      (elementId = ""),
      (elementClass = "aligncenter"),
      (elementText = " Cost = ")
    );
    let bPulse1Cost = createHTMLElement(
      (HTMLTag = "b"),
      (elementId = "pulse" + tabIndex + "Cost")
    );
    centerPulse1Cost.appendChild(bPulse1Cost);

    divRCorners.appendChild(labelPulseInputRatio);
    divRCorners.appendChild(inputPc1Ratio);
    divRCorners.appendChild(divRCornersChild);
    divRCorners.appendChild(document.createElement("br"));
    divRCorners.appendChild(centerPulseIncrement);
    divRCorners.appendChild(centerPulse1Cost);

    pulseDiv4.appendChild(divRCorners);
    pulseTab.appendChild(pulseDiv4);
    pulseTab.appendChild(document.createElement("br"));

    // Div5
    let pulseDiv5 = createHTMLElement(
      (HTMLTag = "div"),
      (elementId = ""),
      (elementClass = ""),
      (elementText = "Converted Value:"),
      (elementStyle = [])
    );
    let divRCorners2 = createHTMLElement(
      (HTMLTag = "div"),
      (elementId = "rcorners"),
      (elementClass = "pulsediv"),
      (elementText = ""),
      (elementStyle = [])
    );
    let labelPc1ConvertLabel = createHTMLElement(
      (HTMLTag = "label"),
      (elementId = "pc_" + tabIndex + "_convert_label"),
      (elementClass = "tab_pulse_label"),
      (elementText = "1 US Gallon = ")
    );
    let inputPc1ConvertedValue = createInput(
      (inputId = "pc_" + tabIndex + "_converted_value"),
      (inputClass = "tabinput fixedwidth"),
      (inputValue = ""),
      (inputMaxLength = ""),
      (inputType = "text"),
      (inputStyle = ["width", "66px"]),
      (inputAttribute = ["value", "", "readonly", "true"])
    );
    let selectPc1ConvertedSelect = createHTMLElement(
      (HTMLTag = "select"),
      (elementId = "pc_" + tabIndex + "_converted_select"),
      (elementClass = ""),
      (elementText = ""),
      (elementStyle = ["opacity", 1, "width", "108px"]),
      (elementAttribute = ["onchange", "getConvertedValue(" + tabIndex + ");"])
    );

    let centerPulseIncrement2 = createHTMLElement(
      (HTMLTag = "center"),
      (elementId = ""),
      (elementClass = "aligncenter"),
      (elementText = " Omnimeter Pulse Increment = ")
    );
    let bPulse1CalculateValuesIs = createHTMLElement(
      (HTMLTag = "b"),
      (elementId = "pulse" + tabIndex + "calculatevalueis")
    );
    centerPulseIncrement2.appendChild(bPulse1CalculateValuesIs);

    let centerPulseIncrement3 = createHTMLElement(
      (HTMLTag = "center"),
      (elementId = ""),
      (elementClass = "aligncenter"),
      (elementText = " Cost = ")
    );
    let bPulse1ConvertedCost = createHTMLElement(
      (HTMLTag = "b"),
      (elementId = "pulse" + tabIndex + "ConvertedCost")
    );
    centerPulseIncrement3.appendChild(bPulse1ConvertedCost);

    divRCorners2.appendChild(labelPc1ConvertLabel);
    divRCorners2.appendChild(inputPc1ConvertedValue);
    divRCorners2.appendChild(selectPc1ConvertedSelect);
    divRCorners2.appendChild(centerPulseIncrement2);
    divRCorners2.appendChild(centerPulseIncrement3);

    pulseDiv5.appendChild(divRCorners2);
    pulseTab.appendChild(pulseDiv5);

    // Apply button
    let pulseDiv6 = createHTMLElement(
      (HTMLTag = "div"),
      (elementId = ""),
      (elementClass = "rollover"),
      (elementText = ""),
      (elementStyle = ["float", "left"])
    );
    let aPulse2Update = createHTMLElement(
      (HTMLTag = "a"),
      (elementId = "pulse2_update"),
      (elementClass = ""),
      (elementText = "Save & Apply"),
      (elementStyle = []),
      (elementAttribute = [
        "onclick",
        "javascript: return setSettingsPopupValues();",
      ])
    );
    pulseDiv6.appendChild(aPulse2Update);
    pulseTab.appendChild(pulseDiv6);

    // Add all change to the tab
    tabPulseKwh.appendChild(pulseTab);
  }
}

// Creates dynamic values for iframe link in settings pop up.
function createurl() {
  iframeURL = window.location.href;
  var frmseturlvalue =
    '<iframe height="750px" width="100%" src="' + iframeURL + '"></iframe>';
  document.getElementById("frmseturl").innerHTML = frmseturlvalue;
}

function change_select() {
  let selectScale = document.getElementById("select_scale").value;
  if (selectScale == "realtime") {
    var select = $("#select_count");
    select
      .empty()
      .append(
        '<option value="25">25</option><option value="50">50</option><option value="75">75</option>'
      );
  } else if (selectScale == "15") {
    var select = $("#select_count");
    select
      .empty()
      .append(
        '<option value="48">12 hour</option><option value="96">1 day</option><option value="192">2 days</option>'
      );
  } else if (selectScale == "hr") {
    var select = $("#select_count");
    select
      .empty()
      .append(
        '<option value="48">2 days</option><option value="120">5 days</option><option value="168">1 week</option>'
      );
  } else if (selectScale == "dy") {
    var select = $("#select_count");
    select
      .empty()
      .append(
        '<option value="7">1 week</option><option value="14">2 week</option><option value="30">1 month</option>'
      );
  } else if (selectScale == "wk") {
    var select = $("#select_count");
    select
      .empty()
      .append(
        '<option value="13">3 month</option><option value="26">6 month</option><option value="52">1 year</option>'
      );
  } else if (selectScale == "mo") {
    var select = $("#select_count");
    select
      .empty()
      .append(
        '<option value="6">6 month</option><option value="12">1 year</option><option value="24">2 year</option>'
      );
  }
}

function change_graph() {
  var graph = document.getElementById("select_graph").value;
  var scaleis = document.getElementById("select_scale").value;
  if (graph == "1") {
    document.getElementById("select_count").style.display = "block";
    document.getElementById("reads_label").style.display = "block";
    document.getElementById("select_scale").style.display = "block";
    document.getElementById("scale_label").style.display = "block";

    $("#select_scale option[value=realtime]").remove();
    $("#select_scale").removeAttr("onchange", "change_select();");
    $("#select_scale").attr("onchange", "change_select();");
    if (scaleis == "realtime") {
      var select = $("#select_count");
      select
        .empty()
        .append(
          '<option value="25">25</option><option value="50">50</option><option value="75">75</option>'
        );
    } else if (scaleis == "15") {
      var select = $("#select_count");
      select
        .empty()
        .append(
          '<option value="48">12 hour</option><option value="96">1 day</option><option value="192">2 days</option>'
        );
    } else if (scaleis == "hr") {
      var select = $("#select_count");
      select
        .empty()
        .append(
          '<option value="48">2 days</option><option value="120">5 days</option><option value="168">1 week</option>'
        );
    } else if (scaleis == "dy") {
      var select = $("#select_count");
      select
        .empty()
        .append(
          '<option value="7">1 week</option><option value="14">2 week</option><option value="30">1 month</option>'
        );
    } else if (scaleis == "wk") {
      var select = $("#select_count");
      select
        .empty()
        .append(
          '<option value="13">3 month</option><option value="26">6 month</option><option value="52">1 year</option>'
        );
    } else if (scaleis == "mo") {
      var select = $("#select_count");
      select
        .empty()
        .append(
          '<option value="6">6 month</option><option value="12">1 year</option><option value="24">2 year</option>'
        );
    }
  } else {
    document.getElementById("select_count").style.display = "block";
    document.getElementById("reads_label").style.display = "block";
    document.getElementById("select_scale").style.display = "none";
    document.getElementById("scale_label").style.display = "none";
    $("#select_scale").append('<option value="realtime">Real Time</option>');
    $("#select_scale").removeAttr("onchange", "change_select();");
    //$("#select_scale").attr("onchange", "createurl()");
    var select = $("#select_count");
    select
      .empty()
      .append(
        '<option value="100">100</option><option value="500">500</option><option value="1000">1000</option>'
      );
  }
}

$.fn.digits = function () {
  return this.each(function () {
    $(this).text(
      $(this)
        .text()
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
    );
  });
};
/************************************************************************************************************************/

function change_tzone() {
  var countrycodeis = document.getElementById("country").value;
  var tzValues = _ALL_TIMEZONES[countrycodeis];
  var data =
    '<option value="" disabled selected>-- Select Timezone --</option>';
  for (var key in tzValues) {
    if (_timezone == key) {
      data +=
        '<option value="' + key + '" selected>' + tzValues[key] + "</option>";
    } else {
      data += '<option value="' + key + '">' + tzValues[key] + "</option>";
    }
  }
  var timezone_options = $("#tz");
  timezone_options.empty().append(data);
}

function copycontent(elementId) {
  $(elementId).focus();
  $(elementId).select();
  let textToCopy = $(elementId).val();

  if (navigator.clipboard) {
    navigator.clipboard.writeText(textToCopy);
  }
  shoInfowMessage("Text copied to clipboard!");
} // End copycontent()

async function reset_url() {
  var deviceType = defaultDeviceType;
  var deviceAddress = defaultDevice;
  var keyis = key_id;
  let baseURL = location.protocol + "//" + location.host + location.pathname;
  let resetURL =
    "deviceType=" +
    deviceType +
    "&deviceAddress=" +
    deviceAddress +
    "&key=" +
    keyis;
  let paramsToUpdate = resetURL;
  if (resetURL.length > 512) {
    resetURL = await compressData(resetURL);
    paramsToUpdate = `q=${resetURL}`;
  }

  resetURL = `${baseURL}?${paramsToUpdate}`;
  location.replace(resetURL);
}

function showError() {
  var imgis =
    '<img id="img_status" border="0" src="static/buttons/widget_fail_icon.svg" align="absmiddle" style="opacity: 0.6; cursor: pointer; padding-top: 0px; width:18px; padding-left: 3px;margin-top:5px;">';
  $("#status").html(imgis);
  //meter_key
  $("#update_meter").attr("src", "static/buttons/widget_fail_icon.svg");
  $("#update_meter").css("width", "18px");
  $("#update_meter").css("margin-top", "5px");

  var create =
    '<option read_rate_attr="0" version="null" value="null">Null</option>';

  $("#select_device").find("option").remove().end().append(create);

  $("#device_rate_value").text(0);
  $("#meter_version").text(null);
  show_error("Error! Bad push key.", 6500);
} //End showError()

function addDevices(devices, deviceSelected) {
  let create = "";
  var checkLastDevice = "";
  const selectDeviceTypeAD = document.querySelector("#select_device_type");

  if (devices.length > 0) {
    $("#status").empty();

    for (let i = 0; i < selectDeviceTypeAD.options.length; i++) {
      if (selectDeviceTypeAD.options[i].value === deviceSelected) {
        selectDeviceTypeAD.options[i].selected = true;
        break;
      }
    }

    if (deviceSelected == "meter") {
      if (_REQUEST.get("last_meter")) {
        checkLastDevice = _REQUEST.get("last_meter");
      } else {
        checkLastDevice = _REQUEST.get("deviceAddress");
      }

      for (var i = 0; i < devices.length; i++) {
        let deviceData = devices[i];
        let deviceName =
          deviceData.name !== "" && deviceData.name !== null
            ? `- ${deviceData.name}`
            : "";
        selectLastDevice =
          checkLastDevice == deviceData.address ? "selected" : "";
        create +=
          '<option read_rate_attr="' +
          deviceData.read_interval +
          ' "version="' +
          deviceData.version +
          '" value="' +
          deviceData.address +
          '" ' +
          selectLastDevice +
          ">" +
          deviceData.address +
          " (" +
          deviceData.version + ") " +
          deviceName +
          "</option>";
      }
    } else if (deviceSelected == "iostack") {
      if (_REQUEST.get("last_iostack")) {
        checkLastDevice = _REQUEST.get("last_iostack");
      } else {
        checkLastDevice = _REQUEST.get("deviceAddress");
      }

      for (var i = 0; i < devices.length; i++) {
        let deviceData = devices[i];
        let deviceName = deviceData.name !== "" ? `(${deviceData.name})` : "";
        selectLastDevice =
          checkLastDevice == deviceData.address ? "selected" : "";
        create +=
          '<option send_interval="' +
          deviceData.send_interval +
          ' "version="' +
          deviceData.version +
          '" value="' +
          deviceData.address +
          '" ' +
          selectLastDevice +
          ">" +
          deviceData.address +
          " " +
          deviceName +
          "</option>";
      }
    }

    $("#select_device").find("option").remove().end().append(create);
    const deviceRead = $("#select_device").val();
    updateRateValue(deviceRead, deviceSelected);
  } else {
    showError();
  }
} // End addDevices()

function getDevices(reset = "") {
  var urlKey = $("#key_value").val();
  let checkUrlKey = true;
  if (urlKey === undefined || urlKey == "") {
    show_error("Please insert your Key and select your device Address!", 5000);
    document.querySelector("#deviceAddressLabel").classList.add("bold-text");
    document.querySelector("#select_device").style.backgroundColor =
      "lightgreen";
    document.querySelector("#keyLabel").classList.add("bold-text");
    document.querySelector("#key_value").style.backgroundColor = "lightgreen";
    $("#settings").toggle();
    checkUrlKey = false;
  }
  const deviceAuthURL =
    baseURL + "account/api/account?key=" + urlKey + "&deep=1";

  if (checkUrlKey) {
    // For getDevices()
    $.ajax({
      url: deviceAuthURL,
      cache: true,
      async: false,
      error: function (jqXHR, status, err) {
        showError();
      },
      success: function (devicesArray) {
        var imgis =
          '<img id="status" border="0" src="static/buttons/widget_check_mark_icon.svg" align="absmiddle" \
            style="opacity: 0.6; cursor: pointer; padding-top: 0px; padding-left: 6px;">';
        $("#status").html(imgis);
        $("#update_meter").attr(
          "src",
          "static/buttons/widget_check_mark_icon.svg"
        );
        $("#update_meter").css("width", "auto");

        if (devicesArray["meters"] != undefined) {
          ALL_DEVICES["meter"] = devicesArray["meters"];
        } else {
          ALL_DEVICES["meter"] = [];
        }
        if (devicesArray["iostacks"] != undefined) {
          ALL_DEVICES["iostack"] = devicesArray["iostacks"];
        } else {
          ALL_DEVICES["iostack"] = [];
        }

        for (let device in ALL_DEVICES) {
          for (let i = 0; i < ALL_DEVICES[device].length; i++) {
            ALL_DEVICES[device][i]["address"] = parseInt(
              ALL_DEVICES[device][i]["address"]
            );
          }
        }

        if (
          ALL_DEVICES["iostack"].length == 0 &&
          ALL_DEVICES["meter"].length == 0
        ) {
          show_error(
            "Error! No devices found for this key. Please use another key with devices.",
            100000
          );
          document
            .querySelector("#deviceAddressLabel")
            .classList.add("bold-text");
          document.querySelector("#select_device").style.backgroundColor =
            "lightgreen";
          document.querySelector("#keyLabel").classList.add("bold-text");
          document.querySelector("#key_value").style.backgroundColor =
            "lightgreen";
          $("#settings").toggle();
        } else {
          if (ALL_DEVICES[deviceType].length == 0) {
            if (deviceType == "meter") {
              deviceType = "iostack";
            } else if (deviceType == "iostack") {
              deviceType = "meter";
            }
          }
        }

        selectDevicesType(deviceType);

        if (reset == true) {
          addDevices(devices["meter"]);
          defaultDevice = $("#select_device").find("option:first").val();
        }

        var selectDevice = document.querySelector("#select_device");

        for (var i = 0; i < selectDevice.options.length; i++) {
          if (selectDevice.options[i].value === defaultDevice) {
            selectDevice.options[i].selected = true;
            meter_version = selectDevice.options[i].getAttribute("version");
            break;
          }
        }
        // Check whether the call comes when the page is loaded or
        // from the settings when the key was updated.
        let checkKeyFromUrl = _REQUEST.get("key");
        let checkKeySettings = document.querySelector("#key_value").value;

        if (checkKeyFromUrl != checkKeySettings)
          defaultDevice = ALL_DEVICES[deviceType][0].address;

        updateRateValue(defaultDevice, deviceType);
        const meterVersionElement = document.querySelector("#meter_version");
        meterVersionElement.textContent = meter_version;
        manageKWhPulseTabs(deviceType);
        fillGraphFieldOptions();
        getpulseRatioByMeterID(defaultDevice, meter_version);
      },
    });
  }
} // End getDevices()

//Fill Pulse and Analog tab
function fillPulseTab(onLoad = false) {
  devicePrefix =
    document.querySelector("#select_device_type").value == "meter" ? "" : "io_";
  const kwhTotYName = document.querySelector("#kwh_tot_yname");
  kwhTotYName.value = _REQUEST.get("kwh_tot_yname");
  const kwhTotCost = document.querySelector("#kwh_tot_cost");
  kwhTotCost.value = _REQUEST.get("kwh_tot_cost");

  const kwhCurrency = document.querySelector("#kwh_currency");
  for (let key in _CURRENCY_OPTIONS) {
    let option = document.createElement("option");
    option.value = key;
    option.innerHTML =
      key + " " + getHexCode(_CURRENCY_OPTIONS[key]["hex-code"]);
    if (_REQUEST.get("kwh_currency") == key) {
      option.setAttribute("selected", "");
    }
    kwhCurrency.add(option);
  }

  // Engine > Tab 2: Pulse/kWh > Pulse 1
  for (let i = 1; i <= 4; i++) {
    const sensorType = document.querySelector("#analog_list_" + i);

    if (_REQUEST.has("analog_list_" + i))
      sensorType.value = _REQUEST.get("analog_list_" + i);
    else sensorType.value = _DEFAULT_VALUES.iostack.analog_list;

    const pcName = document.querySelector("#pc_" + i + "_name");
    pcName.value = _REQUEST.get(devicePrefix + "pc_" + i + "_name");

    const pcValue = document.querySelector("#pc_" + i + "_value");
    pcValue.value = _REQUEST.get(devicePrefix + "pc_" + i + "_value");

    const pcCost = document.querySelector("#pc" + i + "_cost");
    pcCost.value = _REQUEST.get(devicePrefix + "pc" + i + "_cost");

    if (
      deviceType == "iostack" ||
      document.querySelector("#select_device_type").value == "iostack"
    ) {
      document.querySelector("#pc_" + i + "_ratio").value = 1;
    }

    const pcValueSelect = document.querySelector("#pc_" + i + "_value_select");
    $("#pc_" + i + "_value_select")
      .find("option")
      .remove();
    for (let key in _OPTIONS_PULSE_VALUE) {
      let option = document.createElement("option");
      option.value = key;
      option.innerHTML = _OPTIONS_PULSE_VALUE[key];
      if (_REQUEST.get(devicePrefix + "pc_" + i + "_value_select") == key)
        option.setAttribute("selected", "");

      pcValueSelect.add(option);
    }

    const pcCurrency = document.querySelector("#pc" + i + "_currency");
    $("#pc" + i + "_currency")
      .find("option")
      .remove();
    for (let key in _CURRENCY_OPTIONS) {
      let option = document.createElement("option");
      option.value = key;
      option.innerHTML =
        key + " " + getHexCode(_CURRENCY_OPTIONS[key]["hex-code"]);
      if (_REQUEST.get(devicePrefix + "pc" + i + "_currency") == key)
        option.setAttribute("selected", "");

      pcCurrency.add(option);
    }

    const pcConvertedSelect = document.querySelector(
      "#pc_" + i + "_converted_select"
    );
    $("#pc_" + i + "_converted_select")
      .find("option")
      .remove();
    for (let key in _OPTIONS_PULSE_VALUE) {
      if (key != "kWh") {
        let option = document.createElement("option");
        option.value = key;
        option.innerHTML = _OPTIONS_PULSE_VALUE[key];
        if (_REQUEST.get(devicePrefix + "pc_" + i + "_converted_select") == key)
          option.setAttribute("selected", "");

        pcConvertedSelect.add(option);
      }
    }

    // Fill the pulse tab after all tabs have been created.
    fillConvertedValuePulseTab(i);

    let checkDevice =
      document.querySelector("#select_device_type").value == "meter" ? 3 : 4;
    for (let i = 1; i <= checkDevice; i++) {
      getConvertedValue(i);
    }
  }
}

function fillWireTab() {
  devicePrefix =
    document.querySelector("#select_device_type").value == "meter" ? "" : "io_";
  for (let key in _ALL_FIELDS_IOSTACK) {
    if (key.startsWith("ow_")) {
      if (_REQUEST.has(`name_${key}`))
        document.querySelector(`#name_${key}`).value = _REQUEST.get(
          `name_${key}`
        );
      if (_REQUEST.has(`formula_${key}`))
        document.querySelector(`#formula_${key}`).value = _REQUEST
          .get(`formula_${key}`)
          .toLowerCase();
      if (_REQUEST.has(`symbol_${key}`))
        document.querySelector(`#symbol_${key}`).value = _REQUEST
          .get(`symbol_${key}`)
          .toLowerCase();
    }
  }
}

function selectDevicesType(deviceSelected) {
  let devices = ALL_DEVICES[deviceSelected];

  fillDeviceTypes();
  addDevices(devices, deviceSelected);
  manageKWhPulseTabs();
  fillPulseTab(false);
  fillWireTab();
  checkEditedTabs();
  fillGraphFieldOptions();
  fillTotalField(deviceSelected);
  fillLineGraphFields();
  fillCompareData();
  let deviceInfo = document.querySelector("#select_device");
  deviceVersion = deviceInfo.selectedOptions[0].getAttribute("version");
  getpulseRatioByMeterID(deviceInfo.value, deviceVersion);
} // End selectDevicesType()

function updateRateValue(deviceRead, deviceSelected) {
  const val = $("#select_device option[value='" + deviceRead + "']").val();

  if (deviceSelected == "meter") {
    var device_rate_value =
      $("#select_device option[value='" + val + "']").attr("read_rate_attr") /
      1000;
  } else {
    var device_rate_value = $(
      "#select_device option[value='" + val + "']"
    ).attr("send_interval");
  }

  $("#device_rate_value").text(device_rate_value);
}

function manageKWhPulseTabs(deviceType = "") {
  if (deviceType == "") {
    deviceType = document.querySelector("#select_device_type").value;
    deviceType = deviceType.toLowerCase();
  }

  if (deviceType == "iostack") {
    document.querySelector("#li_tab_analog").style.visibility = "visible";
    document.querySelector("#li_tab_analog").style.display = "block";
    document.querySelector("#li_tab_wire").style.visibility = "visible";
    document.querySelector("#li_tab_wire").style.display = "block";
    document.querySelector("#li_tab_total_kwh").style.visibility = "hidden";
    document.querySelector("#tab_total_kwh").style.visibility = "hidden";
    document.querySelector("#li_tab_pulse_1").style.visibility = "visible";
    document.querySelector("#li_tab_pulse_2").style.visibility = "visible";
    document.querySelector("#li_tab_pulse_3").style.visibility = "visible";
    document.querySelector("#li_tab_pulse_4").style.visibility = "visible";
    $("#li_tab_pulse_kwh_text").text("Pulse");
    $("#tab_pulse_kwh").css("marginTop", "0px");
  } else if (meter_version == "v3") {
    // Hide pulse tabs for v3 meter.
    document.querySelector("#li_tab_total_kwh").style.visibility = "hidden";
    document.querySelector("#li_tab_pulse_1").style.visibility = "hidden";
    document.querySelector("#li_tab_pulse_2").style.visibility = "hidden";
    document.querySelector("#li_tab_pulse_3").style.visibility = "hidden";
    document.querySelector("#li_tab_pulse_4").style.visibility = "hidden";
    document.querySelector("#li_tab_analog").style.visibility = "hidden";
    document.querySelector("#li_tab_analog").style.display = "none";
    document.querySelector("#li_tab_wire").style.visibility = "hidden";
    document.querySelector("#li_tab_wire").style.display = "none";
    $("#li_tab_pulse_kwh_text").text("Total kWh");
    $("#tab_pulse_kwh").css("marginTop", "-30px");
  } else {
    document.querySelector("#li_tab_total_kwh").style.visibility = "visible";
    document.querySelector("#li_tab_pulse_1").style.visibility = "visible";
    document.querySelector("#li_tab_pulse_2").style.visibility = "visible";
    document.querySelector("#li_tab_pulse_3").style.visibility = "visible";
    document.querySelector("#li_tab_pulse_4").style.visibility = "hidden";
    document.querySelector("#li_tab_analog").style.visibility = "hidden";
    document.querySelector("#li_tab_analog").style.display = "none";
    document.querySelector("#li_tab_wire").style.visibility = "hidden";
    document.querySelector("#li_tab_wire").style.display = "none";
    $("#li_tab_pulse_kwh_text").text("Pulse/kWh");
    $("#tab_pulse_kwh").css("marginTop", "0px");
  }
}

function manageSettingTabs(tab) {
  var deviceType = document.querySelector("#select_device_type").value;
  deviceType = deviceType.toLowerCase();
  document.querySelector("#tab_general").style.display = "none";
  const sensor_type = document.querySelectorAll("#sensor_type");

  const tabChilds = [
    "li_tab_general",
    "li_tab_pulse_kwh",
    "li_tab_analog",
    "li_tab_wire",
    "li_tab_layout",
    "li_tab_total_kwh",
    "li_tab_pulse_1",
    "li_tab_pulse_2",
    "li_tab_pulse_3",
    "li_tab_pulse_4",
    "li_tab_analog_1",
    "li_tab_analog_2",
    "li_tab_analog_3",
    "li_tab_analog_4",
    "li_tab_params",
    "li_release_tab",
  ];
  for (let i = 0; i < tabChilds.length; i++) {
    document.querySelector("#" + tabChilds[i]).setAttribute("class", "");
  }
  document.querySelector("#li_" + tab).setAttribute("class", "active");

  const tabPulseChilds = [
    "tab_total_kwh",
    "tab_pulse_1",
    "tab_pulse_2",
    "tab_pulse_3",
    "tab_pulse_4",
  ];

  for (let i = 0; i < tabPulseChilds.length; i++) {
    if (tab == tabPulseChilds[i]) {
      document.querySelector("#" + tabPulseChilds[i]).style.display = "block";
    } else {
      document.querySelector("#" + tabPulseChilds[i]).style.display = "none";
    }
  }

  const tabSensorChilds = [
    "tab_analog_1",
    "tab_analog_2",
    "tab_analog_3",
    "tab_analog_4",
  ];
  for (let i = 0; i < tabSensorChilds.length; i++) {
    if (tab == tabSensorChilds[i]) {
      document.querySelector("#" + tabSensorChilds[i]).style.display = "block";
    } else {
      document.querySelector("#" + tabSensorChilds[i]).style.display = "none";
    }
  }
  if (deviceType == "meter") {
    document.querySelector("#tab_analog").style.display = "none";
  }

  const tabOptions = [
    "tab_general",
    "tab_pulse_kwh",
    "tab_analog",
    "tab_wire",
    "tab_layout",
    "tab_params",
    "release_tab",
  ];
  if (tabOptions.includes(tab)) {
    for (let i = 0; i < tabOptions.length; i++) {
      if (tabOptions[i] == tab) {
        document.querySelector("#" + tabOptions[i]).style.display = "block";
        document.querySelector("#" + tabOptions[i] + "_data").style.display =
          "block";

        if (tab == "tab_pulse_kwh") {
          if (deviceType == "iostack") {
            document.querySelector("#tab_total_kwh").style.display = "none";
            document.querySelector("#li_tab_total_kwh").style.display = "none";
            document.querySelector("#tab_pulse_1").style.display = "block";
            document
              .querySelector("#li_tab_pulse_1")
              .setAttribute("class", "active");
          } else if (deviceType == "meter") {
            document.querySelector("#tab_total_kwh").style.display = "block";
            document.querySelector("#tab_total_kwh").style.visibility =
              "visible";
            document.querySelector("#li_tab_total_kwh").style.display = "block";
            document
              .querySelector("#li_tab_total_kwh")
              .setAttribute("class", "active");
          }
          document.querySelector("#li_tab_pulse_1").style.display = "block";
          document.querySelector("#li_tab_pulse_2").style.display = "block";
          document.querySelector("#li_tab_pulse_3").style.display = "block";
          document.querySelector("#li_tab_pulse_4").style.display = "block";
        } else if (tab == "tab_analog") {
          for (let i = 0; i < sensor_type.length; i++) {
            sensor_type[i].style.display = "block";
          }
          document.querySelector("#tab_analog_1").style.display = "block";
          document
            .querySelector("#li_tab_analog_1")
            .setAttribute("class", "active");
        }
      } else {
        if (
          tab != "tab_total_kwh" &&
          tab != "tab_pulse_1" &&
          tab != "tab_pulse_2" &&
          tab != "tab_pulse_3" &&
          tab != "tab_pulse_4"
        ) {
          document.querySelector("#" + tabOptions[i]).style.display = "none";
          document.querySelector("#" + tabOptions[i] + "_data").style.display =
            "none";
        }
      }
    }
  }
}

function checkUrlParams() {
  //Check device type
  if (deviceType != "meter" && deviceType != "iostack") {
    show_error(
      "Error! The URL parameter deviceType=" +
      deviceType +
      " does not exist. deviceType has to be 'meter' or 'iostack'",
      100000
    );
    return false;
  }

  //Check device address
  let checkDeviceParam = false;

  for (let i = 0; i < ALL_DEVICES[deviceType].length; i++) {
    if (ALL_DEVICES[deviceType][i].address == deviceAddress) {
      checkDeviceParam = true;
    }
  }
  if (deviceAddress) if (!checkDeviceParam) validateURLInfo(deviceAddress);
}

function fillTotalField(deviceTypeParam = "") {
  if (deviceTypeParam == "") {
    deviceTypeParam = document.querySelector("#select_device_type").value;
  }

  $("#total_field").find("option").remove();
  const totalField = document.querySelector("#total_field");
  let selectTotalField =
    deviceType == "meter" ? "total_field" : "io_total_field";

  const total_field = _REQUEST.has(selectTotalField)
    ? _REQUEST.get(selectTotalField)
    : _DEFAULT_VALUES[deviceType][selectTotalField];

  var optionsTotal = "";
  var allFieldsDevice = "";

  if (deviceTypeParam == "meter") {
    optionsTotal = filterV3Meters();
    allFieldsDevice = _ALL_FIELDS;
  } else if (deviceTypeParam == "iostack") {
    optionsTotal = _SUPPORTED_DEFAULTED_TOT_FIELDS_IOSTACK;
    allFieldsDevice = _ALL_FIELDS_IOSTACK;
  }

  for (let key in optionsTotal) {
    let option = document.createElement("option");
    if (allFieldsDevice.hasOwnProperty(key)) {
      value = allFieldsDevice[key];
    } else {
      value = optionsTotal[key]["name"];
    }

    if (total_field == key) {
      option.value = key;
      option.text = value;
      option.setAttribute("selected", "");
    } else {
      option.value = key;
      option.text = value;
    }

    totalField.add(option);
  }
}

function updateSensorData(sensorIndex, selectedSensor, data) {
  const sensorTypeDropdown = document.querySelector(
    "#analog_list_" + sensorIndex
  );
  const selectedSensorType = sensorTypeDropdown.value;
  const selectedData = data.find(
    (item) =>
      item.sensor_type === selectedSensorType &&
      item.sensor_model === selectedSensor.value
  );

  if (selectedData) {
    document.querySelector("#analog_min_" + sensorIndex).value =
      selectedData.min_ai_value;
    document.querySelector("#analog_max_" + sensorIndex).value =
      selectedData.max_ai_value;
    document.querySelector("#formula_analog_in_" + sensorIndex).value =
      selectedData.adc;
    document.querySelector("#analog_" + sensorIndex + "_symbol").value =
      selectedData.symbol;
  }
}

// Load CSV
function loadAnalogDataCSV() {
  // Load the CSV file

  const host = location.host;
  let url = "";
  if (host.includes("widget.ekmmetering.com")) {
    url =
      location.protocol +
      "//" +
      location.host +
      location.pathname +
      "static/analog_conversions.csv";
  } else {
    url =
      location.protocol +
      "//" +
      location.host +
      "/widget-dev/static/analog_conversions.csv";
  }

  fetch(url)
    .then((response) => response.text())
    .then((csv) => {
      // Parse the CSV
      var _ANALOG_SENSOR_DATA = Papa.parse(csv, { header: true }).data;

      _ANALOG_SENSOR_DATA.sort((a, b) => {
        // Sort by sensor_type first
        if (a.sensor_type < b.sensor_type) return -1;
        if (a.sensor_type > b.sensor_type) return 1;
        // If sensor_type is the same, sort by sensor model
        if (a.sensor_model < b.sensor_model) return -1;
        if (a.sensor_model > b.sensor_model) return 1;
        return 0;
      });

      for (let j = 1; j <= 4; j++) {
        if (_REQUEST.has("analog_" + j + "_name")) {
          document.querySelector("#analog_" + j + "_name").value = _REQUEST.get(
            "analog_" + j + "_name"
          );
        }

        // Populate the server type dropdown
        const sensorTypeDropdown = document.querySelector("#analog_list_" + j);
        sensorTypeDropdown.innerHTML = '<option value="custom">Custom</option>';
        const sensorTypes = [
          ...new Set(_ANALOG_SENSOR_DATA.map((item) => item.sensor_type)),
        ];

        const analogMinValue = document.querySelector("#analog_min_" + j);
        const analogMaxValue = document.querySelector("#analog_max_" + j);
        const inputConversionFormula = document.querySelector(
          "#formula_analog_in_" + j
        );
        const inputSymbol = document.querySelector("#analog_" + j + "_symbol");

        for (let x = 0; x < sensorTypes.length; x++) {
          const option = document.createElement("option");
          option.value = sensorTypes[x];
          option.text = sensorTypes[x];
          if (_REQUEST.get("analog_list_" + j) == sensorTypes[x]) {
            option.setAttribute("selected", "");
          }
          sensorTypeDropdown.add(option);
        }

        if (
          sensorTypeDropdown.value == "custom" ||
          _REQUEST.get("analog_list_" + j) == "custom"
        ) {
          const selectElement = document.querySelector("#sensor_model_" + j);
          selectElement.disabled = true;
          analogMinValue.disabled = false;
          analogMaxValue.disabled = false;
          inputConversionFormula.disabled = false;
          inputSymbol.disabled = false;

          //Analog Min
          if (_REQUEST.has("analog_min_" + j)) {
            analogMinValue.value = decodeURIComponent(
              getQueryVariable("analog_min_" + j)
            );
          } else {
            analogMinValue.value = 0;
          }
          // Analog Max
          if (_REQUEST.has("analog_max_" + j)) {
            analogMaxValue.value = decodeURIComponent(
              getQueryVariable("analog_max_" + j)
            );
          } else {
            analogMaxValue.value = 4096;
          }

          // Formula
          if (_REQUEST.has("formula_analog_in_" + j)) {
            inputConversionFormula.value = decodeURIComponent(
              getQueryVariable("formula_analog_in_" + j)
            );
          } else {
            inputConversionFormula.value = "";
          }

          // Symbol
          if (_REQUEST.has("sensor_model_symbol_" + j)) {
            inputSymbol.value = getQueryVariable("sensor_model_symbol_" + j);
          } else {
            inputSymbol.value = "";
          }
          if (inputSymbol.value.length > 3) {
            show_error(
              "Symbol for Sensor " + j + " input is limited to 3 characters.",
              5000
            );
            inputSymbol.value = inputSymbol.value.substring(0, 3);
          }
        } else {
          analogMinValue.disabled = true;
          analogMinValue.value = _ANALOG_SENSOR_DATA[0].min_ai_value;
          analogMaxValue.disabled = true;
          analogMaxValue.value = _ANALOG_SENSOR_DATA[0].max_ai_value;
          inputConversionFormula.disabled = true;
          inputConversionFormula.value = _ANALOG_SENSOR_DATA[0].adc;
          inputSymbol.disabled = true;
          inputSymbol.value = _ANALOG_SENSOR_DATA[0].symbol;
        }

        // Populate the sensor dropdown when a category is selected
        const sensorTypeSelectedIndex = sensorTypeDropdown.selectedIndex;
        const sensorTypeSelectedHTML =
          sensorTypeDropdown.options[sensorTypeSelectedIndex];
        const sensorTypeSelected = sensorTypeSelectedHTML.value;

        // Create Sensor model list
        showSensorType(j, sensorTypeSelected, _ANALOG_SENSOR_DATA);

        // Update Sensor model list when the Sensor type was changed
        sensorTypeDropdown.addEventListener("change", () => {
          const selectSensorModel = document.querySelector(
            "#sensor_model_" + j
          );
          if (sensorTypeDropdown.value != "custom") {
            analogMinValue.disabled = true;
            analogMaxValue.disabled = true;
            inputConversionFormula.disabled = true;
            inputSymbol.disabled = true;
            selectSensorModel.disabled = false;
          } else if (sensorTypeDropdown.value == "custom") {
            selectSensorModel.disabled = true;
            analogMinValue.disabled = false;
            analogMaxValue.disabled = false;
            inputConversionFormula.disabled = false;
            inputSymbol.disabled = false;
          }

          showSensorType(j, sensorTypeDropdown.value, _ANALOG_SENSOR_DATA);
        });

        const selectedSensor = document.querySelector("#sensor_model_" + j);
        selectedSensor.addEventListener("change", () => {
          updateSensorData(j, selectedSensor, _ANALOG_SENSOR_DATA);
        });
      }
    });
}

function showSensorType(sensorIndex, sensorType = "", data = "") {
  const sensorDropdown = document.querySelector("#sensor_model_" + sensorIndex);
  let analogMinValue = document.querySelector("#analog_min_" + sensorIndex);
  let analogMaxValue = document.querySelector("#analog_max_" + sensorIndex);
  let inputConversionFormula = document.querySelector(
    "#formula_analog_in_" + sensorIndex
  );
  let inputSymbol = document.querySelector(
    "#analog_" + sensorIndex + "_symbol"
  );

  document.querySelector("#sensor_model_" + sensorIndex).innerHTML = "";
  let checkSelected = 0;

  if (!_REQUEST.has("sensor_model_" + sensorIndex)) {
    checkSelected = 1;
  }

  for (let i = 0; i < data.length; i++) {
    if (data[i].sensor_type == sensorType) {
      const option = document.createElement("option");
      option.value = data[i].sensor_model;
      option.text = data[i].sensor_model;
      option.setAttribute("data-symbol", data[i].symbol); //Sensor Symbol (v, °C, psi, etc)
      if (checkSelected == 1) {
        for (let i = 0; i < sensorDropdown.length; i++) {
          if (i == 0) sensorDropdown[i].setAttribute("selected", "");
        }
      } else if (
        _REQUEST.get("sensor_model_" + sensorIndex) == data[i].sensor_model
      ) {
        option.setAttribute("selected", "");
      }
      sensorDropdown.add(option);

      if (sensorDropdown.value == data[i].sensor_model) {
        analogMinValue.value = data[i].min_ai_value;
        analogMaxValue.value = data[i].max_ai_value;
        inputConversionFormula.value = data[i].adc;
        inputSymbol.value = data[i].symbol;
      }
    }
  }
}

function evaluateMathFormula(formula) {
  try {
    const result = katex.renderToString(formula, {
      throwOnError: false,
      errorColor: "#cc0000",
      displayMode: false,
    });

    // If the formula was successfully rendered, return the result
    return result;
  } catch (err) {
    // If there was an error, return null
    return null;
  }
}

function checkLineGraphFields() {
  selectLineGraphFields =
    deviceType == "meter" ? "line_graph_fields" : "io_line_graph_fields";

  if (_REQUEST.get(selectLineGraphFields)) {
    graphFieldsToCheck = [];
    uniqueFields = [];
    netFieldsArray = [
      "net_calc_watts_ln_1",
      "net_calc_watts_ln_2",
      "net_calc_watts_ln_3",
      "net_calc_watts_tot",
    ];
    count = 0;
    lineGraphFields = _REQUEST.get(selectLineGraphFields).toLowerCase();
    lineGraphFields = lineGraphFields.split("~");

    lineGraphFields.forEach(function (obj) {
      if (!uniqueFields.includes(obj)) uniqueFields.push(obj);
    });

    let allFields = "";

    if (deviceType == "meter") {
      allFields = _ALL_FIELDS;
    } else if (deviceType == "iostack") {
      allFields = _ALL_FIELDS_IOSTACK;
    }

    uniqueFields.forEach(function (obj) {
      if (
        typeof allFields[obj] !== undefined &&
        graphFieldsToCheck.length < 4
      ) {
        graphFieldsToCheck.push(obj);
      }
    });

    graphFieldsToCheck.forEach(function (obj) {
      if (netFieldsArray.includes(obj)) {
        count++;
      }
    });

    if (count == 4) {
      isEnableAreaGraph = 1;
    }
  }
}

function checkBarGraphFields() {
  selectBarGraphFields =
    deviceType == "meter" ? "bar_graph_field" : "io_bar_graph_field";
  let barGraphFields = _REQUEST.has(selectBarGraphFields)
    ? _REQUEST.get(selectBarGraphFields).toLowerCase()
    : _DEFAULT_VALUES[deviceType][selectBarGraphFields];

  if (barGraphFields) {
    count = 0;
    graphFieldsToCheck = [];
    netkWHFieldsArray = [
      "net_kwh_tot",
      "net_kwh_tariff_1",
      "net_kwh_tariff_2",
      "net_kwh_tariff_3",
      "net_kwh_tariff_4",
    ];

    let allFields = deviceType == "meter" ? _ALL_FIELDS : _ALL_FIELDS_IOSTACK;

    if (typeof allFields[barGraphFields] !== undefined)
      graphFieldsToCheck = barGraphFields;
    if (netkWHFieldsArray.includes(graphFieldsToCheck)) count++;
    if (count == 1) isNetKWHField = 1;
  }
}

function handleRelativeView(value = 0) {
  const compareSettings = document.querySelector("#compareSettings");
  compareSettings.style.display = value == 1 ? "block" : "none";
}

function fillLineGraphFields() {
  selectLineGraphFields =
    deviceType == "meter" ? "line_graph_fields" : "io_line_graph_fields";
  if (_REQUEST.get(selectLineGraphFields)) {
    urlLineGraphFields = _REQUEST.get(selectLineGraphFields);
    urlLineGraphFields = urlLineGraphFields.split("~");

    const htmlLineGraphFields = document.querySelector("#line_graph_fields");

    for (let i = 0; i < htmlLineGraphFields.options.length; i++) {
      for (let j = 0; j < urlLineGraphFields.length; j++) {
        if (htmlLineGraphFields.options[i].value == urlLineGraphFields[j]) {
          htmlLineGraphFields.options[i].selected = true;
        }
      }
    }
  }
}

function fillCompareData() {
  selectCompare = deviceType == "meter" ? "compare" : "io_compare";
  const htmlCompare = document.querySelector("#compare");
  let urlCompare = 0;

  if (_REQUEST.get(selectCompare)) {
    urlCompare = _REQUEST.get(selectCompare).toLowerCase();
    urlCompare = urlCompare.trim();
    if (urlCompare == 1 || urlCompare == 0) {
      handleRelativeView(urlCompare);
      htmlCompare.value = urlCompare == 1 ? 1 : 0;
      if (urlCompare == 1) {
        selectCompareMinY =
          deviceType == "meter" ? "compare_min_y" : "io_compare_min_y";
        selectCompareMaxY =
          deviceType == "meter" ? "compare_max_y" : "io_compare_max_y";
        if (_REQUEST.get(selectCompareMinY))
          document.querySelector("#compare_min").value =
            _REQUEST.get(selectCompareMinY);
        if (_REQUEST.get(selectCompareMaxY))
          document.querySelector("#compare_max").value =
            _REQUEST.get(selectCompareMaxY);
      }
    }
  } else {
    if (htmlCompare.value == 1) {
      htmlCompare.value = 0;
      document.querySelector("#compare_min").value = "";
      document.querySelector("#compare_max").value = "";
    }
    handleRelativeView();
  }
}

function fillTableFields() {
  let urlTableFields = "";
  selectTableFields =
    deviceType == "meter" ? "table_fields" : "io_table_fields";

  if (_REQUEST.get(selectTableFields) != "") {
    if (_REQUEST.get(selectTableFields)) {
      urlTableFields = _REQUEST.get(selectTableFields);
      urlTableFields = urlTableFields.split("~");
    }

    const htmlTableFields = document.querySelector("#tablefields");
    for (let i = 0; i < htmlTableFields.options.length; i++) {
      for (let j = 0; j < urlTableFields.length; j++) {
        if (htmlTableFields.options[i].value == urlTableFields[j]) {
          htmlTableFields.options[i].selected = true;
        }
      }
    }
  }
}

// Fill Layout/Fields Tab
function fillLayoutTab() {
  checkLineGraphFields();
  fillCompareData();
  checkBarGraphFields();
  //fillTableFields() It's called inside fillGraphFieldOptions()
}

function extractValuesforTotalField(arrayData, splitDefaultTotalField) {
  let diff = 0;
  let first = 0;
  let last = 0;
  let firstArray = [];
  let lastArray = [];

  if (isSummary == 1) {
    for (let i = 0; i < splitDefaultTotalField.length; i++) {
      if (!splitDefaultTotalField[i].includes("_Diff")) {
        if (splitDefaultTotalField[i].includes("_First")) {
          firstArray.push(splitDefaultTotalField[i]);
        } else if (splitDefaultTotalField[i].includes("_Last")) {
          lastArray.push(splitDefaultTotalField[i]);
        }
      }
    }

    for (let i = 0; i < lastArray.length; i++) {
      last += arrayData[0][lastArray[i]];
    }
    for (let i = 0; i < firstArray.length; i++) {
      first += arrayData[arrayData.length - 1][firstArray[i]];
    }

    last = Number.isNaN(last) ? 0 : last;
    first = Number.isNaN(first) ? 0 : first;

    diff = last - first;
  } else {
    diff = arrayData[0][splitDefaultTotalField];
  }

  return diff;
}

function updateBookmark() {
  let updatedURL = window.location.href;
  const goBtn = document.querySelector("#gobtn");
  goBtn.href = updatedURL;
  $("#copytext").text(updatedURL);
}

async function compressData(data) {
  try {
    const textEncoder = new TextEncoder();
    const compressedStream = new CompressionStream("gzip");
    const writer = compressedStream.writable.getWriter();

    writer.write(textEncoder.encode(data));
    writer.close();

    const chunks = [];
    const reader = compressedStream.readable.getReader();

    let result;

    while (!(result = await reader.read()).done) {
      chunks.push(result.value);
    }

    const compressed = new Uint8Array(
      chunks.reduce((acc, val) => acc.concat(Array.from(val)), [])
    );

    // Convert to Base64
    const base64String = btoa(String.fromCharCode(...compressed));
    let compressedString = base64String
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");

    return compressedString;
  } catch (error) {
    console.error("Trying remote compress... ");
    try {
      let remoteCompress = await getRemoteData(data, "compress");
      return remoteCompress;
    } catch (remoteError) {
      console.error("Remote compress error: ", remoteError);
    }
  }
}

async function decompressData(encodedData) {
  try {
    const base64String = encodedData.replace(/-/g, "+").replace(/_/g, "/");
    const binaryString = atob(base64String);
    const bytes = new Uint8Array(binaryString.length);

    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    const decompressedStream = new DecompressionStream("gzip");
    const writer = decompressedStream.writable.getWriter();

    writer.write(bytes);
    writer.close();

    const chunks = [];
    const reader = decompressedStream.readable.getReader();

    let result;

    while (!(result = await reader.read()).done) {
      chunks.push(result.value);
    }

    const decompressed = new Uint8Array(
      chunks.reduce((acc, val) => acc.concat(Array.from(val)), [])
    );
    const textDecoder = new TextDecoder();
    formatParamsInput(textDecoder.decode(decompressed));
    return textDecoder.decode(decompressed);
  } catch (error) {
    console.error("Trying remote decompress... ");
    try {
      let remoteDecompress = await getRemoteData(encodedData, "decompress");
      return remoteDecompress;
    } catch (remoteError) {
      console.error("Remote decompress error: ", remoteError);
    }
  }
}

// Next function is for support older browser that don't support Compression/DecompressionStream API
async function getRemoteData(data, action) {
  let phpFile = action === "compress" ? "compress.php" : "decompress.php";
  $("body").addClass("loading");
  return new Promise((resolve, reject) => {
    let remoteURL =
      location.protocol + "//" + location.host + location.pathname + phpFile;
    let xhr = new XMLHttpRequest();
    xhr.open("POST", remoteURL);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        $("body").removeClass("loading");
        resolve(xhr.responseText); // Resolve the Promise with the response text
      } else {
        $("body").removeClass("loading");
        reject(new Error("Request failed with status:", xhr.status)); // Reject the Promise with an error
      }
    };

    xhr.onerror = function () {
      console.error("Request errored");
      reject(new Error("Request errored")); // Reject the Promise with an error
    };

    xhr.send(JSON.stringify({ data: data }));
  });
}

async function checkInitialUrlParams() {
  const urlParams = new URLSearchParams(window.location.search);

  // Check if the url is raw or it is encode
  // For raw url
  if (
    urlParams.toString() === "" ||
    (urlParams.get("q") === null && urlParams.toString().length === 0) ||
    urlParams.get("q") === ""
  ) {
    _REQUEST = urlParams;
  } else if (urlParams.get("q") !== null) {
    let checkParams;
    let otherParams = false;
    let decompressedUrl = await decompressData(urlParams.get("q"));

    decompressedUrl = new URLSearchParams(decompressedUrl);

    if (urlParams.size > 1) {
      urlParams.forEach((value, key) => {
        checkParams = false;

        if (key !== "q") {
          decompressedUrl.forEach((dValue, dKey) => {
            if (key === dKey) {
              decompressedUrl.set(key, urlParams.get(dKey));
              otherParams = true;
              checkParams = true;
            }
          });

          if (!checkParams) {
            decompressedUrl.append(key, value);
            otherParams = true;
          }
        }
      });
    }

    if (otherParams) {
      let compressParams = await compressData(decompressedUrl.toString());
      updateURL = `?q=${compressParams}`;
      history.pushState(null, null, updateURL);
    }

    _REQUEST = new URLSearchParams(decompressedUrl);
  } else {
    _REQUEST = urlParams;
    if (_REQUEST.has('meter')) {
      _REQUEST.set('deviceAddress', _REQUEST.get('meter'));
    }
    if (urlParams.toString() !== "") {
      if (urlParams.toString().length > 512) {
        let compressedUrl = await compressData(urlParams.toString());
        // Create a new URL with the updated parameters
        let newUrl = window.location.pathname + "?q=" + compressedUrl;
        history.pushState(null, null, newUrl);
      }
    }
  }
  return _REQUEST;
}

function updateLayoutOptions() {
  fillTotalField();
  fillLineGraphFields();
  fillGraphFieldOptions();
  fillTableFields();
}

function filterV3Meters() {
  let meterFields = {};
  let meterSelected = document.querySelector("#select_device option:checked");
  if (meterSelected !== null) {
    meterSelected = document.querySelector(
      "#select_device option:checked"
    ).value;
  }
  let meterVersion = "";

  ALL_DEVICES.meter.forEach(function (device) {
    if (device.address == meterSelected) {
      meterVersion = device.version;
    }
  });

  if (meterVersion === "v3") {
    _V3_METER_DEFAULT_FIELDS.forEach(function (key) {
      if (_SUPPORTED_DEFAULTED_TOT_FIELDS[key]) {
        meterFields[key] = _SUPPORTED_DEFAULTED_TOT_FIELDS[key];
      }
    });
  } else {
    meterFields = _SUPPORTED_DEFAULTED_TOT_FIELDS;
  }
  meter_version = meterVersion;

  return meterFields;
}

function cleanEditedTabs() {
  for (let i = 1; i <= 5; i++) {
    let tabToCheck = document.querySelector(`#pulse${i}a`);

    if (tabToCheck.textContent.includes("*")) {
      tabToCheck.textContent = tabToCheck.textContent.replace("*", "");
    }
  }
}

function checkEditedTabs() {
  cleanEditedTabs();
  let deviceTypeSelected = document.querySelector(
    "#select_device_type option:checked"
  ).value;
  let paramsToCheck = _REQUEST.toString().split("&");
  let tabsChecked = [];
  paramsToCheck.forEach(function (param) {
    if (deviceTypeSelected === "meter") {
      //Tab: Pulse/kWh
      if (param.startsWith("kwh_") || param.startsWith(`pc`)) {
        for (let i = 1; i <= 4; i++) {
          if (param.startsWith("kwh_") && !tabsChecked.includes("kwh_")) {
            let pulseTab = document.querySelector(`#pulse${i}a`);
            pulseTab.textContent = `*${pulseTab.textContent}`;
            tabsChecked.push("kwh_");
          } else if (
            (param.startsWith(`pc${i}_`) || param.startsWith(`pc_${i}`)) &&
            (!tabsChecked.includes(`pc${i}_`) ||
              !tabsChecked.includes(`pc_${i}`))
          ) {
            let pulseTab = document.querySelector(`#pulse${i + 1}a`);
            pulseTab.textContent = `*${pulseTab.textContent}`;
            tabsChecked.push(`pc_${i}`);
            tabsChecked.push(`pc${i}_`);
          }
        }
      }
    } else if (deviceTypeSelected === "iostack") {
      if (param.startsWith("io_pc")) {
        //Subtab: Pulse *
        for (let i = 1; i <= 4; i++) {
          if (
            (param.startsWith(`io_pc${i}_`) ||
              param.startsWith(`io_pc_${i}`)) &&
            (!tabsChecked.includes(`io_pc${i}_`) ||
              !tabsChecked.includes(`io_pc_${i}`))
          ) {
            let pulseTab = document.querySelector(`#pulse${i + 1}a`);
            pulseTab.textContent = `*${pulseTab.textContent}`;
            tabsChecked.push(`io_pc_${i}`);
            tabsChecked.push(`io_pc${i}_`);
          }
        }
      } else if (
        param.startsWith("analog_") ||
        param.startsWith("sensor_model_symbol_") ||
        param.startsWith("formula_analog_in_") ||
        param.startsWith("analog_list_") ||
        param.startsWith("sensor_model_")
      ) {
        for (let i = 1; i <= 4; i++) {
          if (
            (param.startsWith(`analog_${i}`) ||
              param.startsWith(`analog_min_${i}`) ||
              param.startsWith(`analog_max_${i}`) ||
              param.startsWith(`sensor_model_symbol_${i}`) ||
              param.startsWith(`formula_analog_in_${i}`) ||
              param.startsWith(`analog_list_${i}`) ||
              param.startsWith(`sensor_model_${i}`)) &&
            !tabsChecked.includes(`analog_${i}`)
          ) {
            let analogTab = document.querySelector(`#analog${i + 1}a`);
            analogTab.textContent = `*${analogTab.textContent}`;
            tabsChecked.push(`analog_${i}`);
          }
        }
      } else if (
        param.startsWith("name_ow_") ||
        param.startsWith("formula_ow_") ||
        param.startsWith("symbol_ow_")
      ) {
        for (let key in _ALL_FIELDS_IOSTACK) {
          if (
            (param.startsWith(`name_${key}`) ||
              param.startsWith(`formula_${key}`) ||
              param.startsWith(`symbol_${key}`)) &&
            !tabsChecked.includes(`${key}`)
          ) {
            let wireTab = document.querySelector(`#${key}`);
            wireTab.textContent = `*${wireTab.textContent}`;
            tabsChecked.push(`${key}`);
          }
        }
      }
    }
  });
}

function formatParamsInput(paramsToFormat) {
  paramsToFormat = paramsToFormat.replace(/&/g, "\n");
  document.querySelector("#params_text").textContent = paramsToFormat;
}

function setInitialParams() {
  if (_REQUEST.has("key")) {
    key_id = _REQUEST.get("key");
    $("#key_value").val(key_id);
    $("#key_value_reserve").val(key_id);
  } else {
    key_id = $("#key_value").val();
  }



  if (_REQUEST.has("deviceType")) {
    let tmpDeviceType = _REQUEST.get("deviceType")
    if (tmpDeviceType === "meter" || tmpDeviceType === "iostack") {
      deviceType = tmpDeviceType.toLowerCase();
    } else {
      deviceType = "meter"
      _REQUEST.set("deviceType", deviceType);
    }
  } else {
    deviceType = "meter";
    _REQUEST.set("deviceType", deviceType);
  }

  devicePrefix = deviceType == "meter" ? "" : "io_";

  if (_REQUEST.has("deviceAddress")) {
    deviceAddress = _REQUEST.get("deviceAddress");
  } else if (_REQUEST.has("key") && _REQUEST.get("key") !== "") {
    show_error(
      `Attention! No EKM Omnimeter or ioStack serial number has been included in your EKM Widget URL. In this case we will choose a default serial number. If you would like to change the serial number, go to the Settings under the gear icon ⚙️ on the right and choose the serial number you prefer.`,
      20000
    );
  }

  if (_REQUEST.get(devicePrefix + "compare"))
    compare = _REQUEST.get(devicePrefix + "compare");

  if (_REQUEST.has("scale")) {
    let tmpScale = _REQUEST.get("scale").trim()
    if (_SUPPORTED_SCALES.hasOwnProperty(tmpScale)) {
      scale = vScale = tmpScale;
      isSummary = 1;
    }
  }

  // For Realtime supported values are
  // meter: kWh_Tot,Pulse_Cnt_1,Pulse_Cnt_2,Pulse_Cnt_3
  // iostack: Analog_In_1, Analog_In_2, Analog_In_3, Analog_In_4
  if (deviceType == "meter") {
    defaultTotalField = "kwh_tot";
  } else if (deviceType == "iostack") {
    defaultTotalField = "pulse_cnt_1";
  }

  if (deviceType == "meter") {
    supportedDefaultedTotFields = _SUPPORTED_DEFAULTED_TOT_FIELDS;
  } else if (deviceType == "iostack") {
    supportedDefaultedTotFields = _SUPPORTED_DEFAULTED_TOT_FIELDS_IOSTACK;
  }

  selectTotalField = deviceType == "meter" ? "total_field" : "io_total_field";
  if (
    _REQUEST.get(selectTotalField) &&
    supportedDefaultedTotFields.hasOwnProperty(_REQUEST.get(selectTotalField))
  ) {
    defaultTotalField = _REQUEST.get(selectTotalField);
  }

  // Time Zone
  if (_REQUEST.has("timezone")) {
    getCountryByTZ(_REQUEST.get("timezone"));
  }
}

function readStateInOut(responseJSON) {
  let dataArray = responseJSON

  if (isSummary === 0) {
    if (deviceType == "iostack") {
      dataArray = responseJSON["readiostack"]["ReadSet"][0]["ReadData"];
    } else if (deviceType == "meter") {
      dataArray = responseJSON["readMeter"]["ReadSet"][0]["ReadData"];
    }

    if (dataArray[i]?.hasOwnProperty("State_Inputs") || dataArray[i]?.hasOwnProperty("State_Out")) {

      for (let i = 0; i < dataArray.length; i++) {

        if (dataArray[i].hasOwnProperty("State_Inputs")) {
          dataArray[i]["State_Inputs"] = decTo4BitBinary(
            dataArray[i]["State_Inputs"],
            "State_Inputs"
          );
        }
        if (dataArray[i].hasOwnProperty("State_Out")) {
          dataArray[i]["State_Out"] = decTo4BitBinary(
            dataArray[i]["State_Out"],
            "State_Out"
          );
        }
      }
    }

  }

  return dataArray

}
